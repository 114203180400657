export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  HTML: { input: any; output: any; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Markdown: { input: any; output: any; }
  /** Timezone formatted per the IANA Time Zone database (https://en.wikipedia.org/wiki/List_of_tz_database_time_zones) */
  TZInfo: { input: any; output: any; }
};

/** Autogenerated input type of AcquireDocumentLock */
export type AcquireDocumentLockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of AcquireDocumentLock. */
export type AcquireDocumentLockPayload = {
  __typename?: 'AcquireDocumentLockPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Document locked */
  document?: Maybe<Document>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Active pharmacy coverage details for a patient */
export type ActivePharmacyCoverageDetails = {
  __typename?: 'ActivePharmacyCoverageDetails';
  alternativesId?: Maybe<Scalars['String']['output']>;
  copayNumber?: Maybe<Scalars['String']['output']>;
  coverageId?: Maybe<Scalars['String']['output']>;
  coverageStatus?: Maybe<Scalars['String']['output']>;
  coverageType: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  demographicDifferences?: Maybe<DemographicDifferencesResult>;
  entityIdentifierCode?: Maybe<Scalars['String']['output']>;
  formularyNumber: Scalars['String']['output'];
  groupName?: Maybe<Scalars['String']['output']>;
  groupNumber: Scalars['String']['output'];
  healthPlanName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  iin: Scalars['String']['output'];
  insuranceTypeCode?: Maybe<Scalars['String']['output']>;
  interchangeControlNumber?: Maybe<Scalars['String']['output']>;
  monetaryAmount?: Maybe<Scalars['String']['output']>;
  payerName: Scalars['String']['output'];
  pcn: Scalars['String']['output'];
  planNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AddDocumentSummaryFeedbackInput = {
  displayedSummaryDataFields: Array<DocumentSummaryDataFieldName>;
  documentSummaryId: Scalars['ID']['input'];
  feedbackFields: Array<DocumentSummaryFeedbackFieldInput>;
  metadata: DocumentSummaryActorMetadataInput;
};

/** Autogenerated input type of AddDocumentSummaryFeedbackMutation */
export type AddDocumentSummaryFeedbackMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedback: AddDocumentSummaryFeedbackInput;
};

/** Autogenerated return type of AddDocumentSummaryFeedbackMutation. */
export type AddDocumentSummaryFeedbackMutationPayload = {
  __typename?: 'AddDocumentSummaryFeedbackMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<DocumentSummaryFeedback>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AddExternalDocumentToChart */
export type AddExternalDocumentToChartInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

/** Autogenerated return type of AddExternalDocumentToChart. */
export type AddExternalDocumentToChartPayload = {
  __typename?: 'AddExternalDocumentToChartPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  note?: Maybe<Note>;
  success: Scalars['Boolean']['output'];
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  crossStreet?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  cross_street?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPreferred: Scalars['Boolean']['output'];
  kind: AddressKind;
  latitude?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AddressStatus>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  crossStreet?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  kind?: InputMaybe<AddressKind>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressKind {
  Billing = 'billing',
  Home = 'home',
  Other = 'other',
  Work = 'work'
}

export enum AddressStatus {
  Current = 'CURRENT',
  Previous = 'PREVIOUS'
}

export type Admission = {
  __typename?: 'Admission';
  admitReason: Scalars['String']['output'];
  admittedAt?: Maybe<Scalars['Date']['output']>;
  attachments: AdmissionAttachmentConnection;
  author?: Maybe<Scalars['String']['output']>;
  dischargeType?: Maybe<AdmissionDischargeType>;
  dischargedAt?: Maybe<Scalars['Date']['output']>;
  facilityLocation: Scalars['String']['output'];
  facilityType: AdmissionFacilityType;
  hasDischargeSummary: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  monitoring: AdmissionMonitoring;
  nextCheckInDate: Scalars['Date']['output'];
  nextSteps?: Maybe<Scalars['String']['output']>;
  patient: Patient;
  readmissionRisk?: Maybe<ReadmissionRisk>;
  status: AdmissionStatus;
  story?: Maybe<Scalars['HTML']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  type: AdmissionType;
};


export type AdmissionAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AdmissionAttachableInterface = {
  id: Scalars['ID']['output'];
};

/** The connection type for AdmissionAttachableInterface. */
export type AdmissionAttachmentConnection = {
  __typename?: 'AdmissionAttachmentConnection';
  /** A list of edges. */
  edges: Array<AdmissionAttachmentEdge>;
  /** A list of nodes. */
  nodes: Array<AdmissionAttachableInterface>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Describes the relationship between admissions and attachments */
export type AdmissionAttachmentEdge = {
  __typename?: 'AdmissionAttachmentEdge';
  createdAt: Scalars['DateTime']['output'];
  createdBy: InternalUser;
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AdmissionAttachableInterface>;
};

export enum AdmissionDischargeType {
  AssistedLivingFacility = 'ASSISTED_LIVING_FACILITY',
  Deceased = 'DECEASED',
  Home = 'HOME',
  InpatientHospitalization = 'INPATIENT_HOSPITALIZATION',
  Other = 'OTHER',
  SkilledNursingFacility = 'SKILLED_NURSING_FACILITY'
}

export enum AdmissionFacilityType {
  EmergencyRoom = 'EMERGENCY_ROOM',
  InpatientHospitalization = 'INPATIENT_HOSPITALIZATION',
  Observation = 'OBSERVATION',
  Other = 'OTHER',
  SkilledNursingFacility = 'SKILLED_NURSING_FACILITY'
}

export enum AdmissionMonitoring {
  Active = 'ACTIVE',
  Passive = 'PASSIVE'
}

export type AdmissionSearchEdge = {
  __typename?: 'AdmissionSearchEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Admission>;
};

export type AdmissionSearchPage = {
  __typename?: 'AdmissionSearchPage';
  edges: Array<AdmissionSearchEdge>;
  nodes: Array<Admission>;
  pageInfo: PageInfo;
};

export type AdmissionSearchQuery = {
  facilityType?: InputMaybe<Array<AdmissionFacilityType>>;
  monitoring?: InputMaybe<AdmissionMonitoring>;
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  readmissionRisk?: InputMaybe<Array<ReadmissionRisk>>;
  serviceAreaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  sortBy?: InputMaybe<AdmissionsSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  status?: InputMaybe<AdmissionStatus>;
};

export enum AdmissionStatus {
  Cancelled = 'CANCELLED',
  Current = 'CURRENT',
  Discharged = 'DISCHARGED',
  Pending = 'PENDING'
}

export enum AdmissionType {
  Planned = 'PLANNED',
  Unplanned = 'UNPLANNED'
}

export enum AdmissionsSortBy {
  AdmittedAt = 'ADMITTED_AT',
  DischargedAt = 'DISCHARGED_AT',
  NextCheckInDate = 'NEXT_CHECK_IN_DATE',
  Patient = 'PATIENT'
}

/** Age Limits for a coverage */
export type AgeLimit = {
  __typename?: 'AgeLimit';
  maximumAge?: Maybe<Scalars['Int']['output']>;
  /** Days, Months, or Years */
  maximumAgeQualifier?: Maybe<Scalars['String']['output']>;
  minimumAge?: Maybe<Scalars['Int']['output']>;
  /** Days, Months, or Years */
  minimumAgeQualifier?: Maybe<Scalars['String']['output']>;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  /** Buckets for each range */
  buckets: Array<RangeBucket>;
};

export enum AmazonClinicEndReason {
  PatientNoShow = 'patient_no_show',
  PatientTechFail = 'patient_tech_fail',
  ProviderTechFail = 'provider_tech_fail',
  UnsupportedLicensingBody = 'unsupported_licensing_body',
  VisitComplete = 'visit_complete'
}

export type AmazonPlan = Plan & {
  __typename?: 'AmazonPlan';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of ApplyTemplateAppointmentInventory */
export type ApplyTemplateAppointmentInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['DateTime']['input'];
  templateVariantId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of ApplyTemplateAppointmentInventory. */
export type ApplyTemplateAppointmentInventoryPayload = {
  __typename?: 'ApplyTemplateAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  inventories?: Maybe<Array<AppointmentInventory>>;
  success: Scalars['Boolean']['output'];
};

export type Appointment = AppointmentCommon & Remindable & {
  __typename?: 'Appointment';
  appointmentInventory?: Maybe<AppointmentInventory>;
  /** The kind of appointment */
  appointmentType: AppointmentType;
  /** The user who approved the appointment */
  approvedBy?: Maybe<InternalUser>;
  /** The time at which the patient arrived for the appointment */
  arrivedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user who booked the appointment */
  bookedBy?: Maybe<AppointmentActor>;
  /** If the appointment can be cancelled */
  cancellable: Scalars['Boolean']['output'];
  /** The reason an apppointment was cancelled */
  cancellationReason?: Maybe<AppointmentCancellationReason>;
  /** The time at which the appointment was cancelled */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The zero charge details associated with this appointment */
  chargeExemptionDetails?: Maybe<ChargeExemptionDetails>;
  /** The user who checked in the patient for the appointment */
  checkinBy?: Maybe<InternalUser>;
  /** The user who checked out the patient for the appointment */
  checkoutBy?: Maybe<InternalUser>;
  /** Comments about the appointment */
  comments?: Maybe<Scalars['String']['output']>;
  /** The date time when the patient confirmed the appointment on their mobile phone */
  confirmedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The time at which the appointment record was created */
  createdAt: Scalars['DateTime']['output'];
  /** The time at which the patient departed the office */
  departedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The duration of the appointment in minutes */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The time at which the appointment is scheduled to conclude */
  endAt: Scalars['DateTime']['output'];
  /** id of appointment object */
  id: Scalars['ID']['output'];
  /** If the visit charges have been reviewed */
  isBillingReviewed: Scalars['Boolean']['output'];
  /** If the appointment timezone does not match the patient timezone */
  isDifferentOnsiteTimezone: Scalars['Boolean']['output'];
  /** Whether or not the appointment type is a remote kind */
  isRemote: Scalars['Boolean']['output'];
  /** The user who last updated the appointment */
  lastUpdatedBy?: Maybe<InternalUser>;
  /**
   * name for Remindable interface
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String']['output'];
  /** start of appointment time */
  notifiableStatus?: Maybe<Scalars['String']['output']>;
  /**
   * start of appointment time
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']['output']>;
  /** title for Remindable object */
  notificationSubtitle?: Maybe<Scalars['String']['output']>;
  /** title for Remindable object */
  notificationTitle: Scalars['String']['output'];
  /**
   * title for Remindable object
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * title for Remindable object
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String']['output'];
  office?: Maybe<Office>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  patient?: Maybe<Patient>;
  /** The date time when the patient notified us they would be late for their appointment */
  patientRunningLateAt?: Maybe<Scalars['DateTime']['output']>;
  /** A program enrollment associated with this appointment */
  programEnrollment?: Maybe<ProgramEnrollment>;
  provider?: Maybe<Provider>;
  /** The patient-reported reason for the appointment */
  reason: Scalars['String']['output'];
  recording?: Maybe<AppointmentRecording>;
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']['output']>;
  /** The id for the remote video visit */
  remoteMeetingId?: Maybe<Scalars['ID']['output']>;
  /** The URL for the remote video visit. Intended for internal users only. For patients, see join_link. */
  remoteMeetingLink?: Maybe<Scalars['String']['output']>;
  /** If the appointment was rescheduled */
  rescheduled: Scalars['Boolean']['output'];
  /** The room selection appointed in the appointment drawer for the patient */
  room?: Maybe<Scalars['String']['output']>;
  /** The time at which the provider saw the patient */
  seenAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether or not we should send SMS appointment reminders */
  sendSms?: Maybe<Scalars['Boolean']['output']>;
  /** The service ticket associated with this appointment */
  serviceTicket?: Maybe<ServiceTicket>;
  /** The ID of the Service Ticket for a given appointment. */
  serviceTicketId?: Maybe<Scalars['Int']['output']>;
  /** The number to which we will send SMS appointment reminders */
  smsNumber?: Maybe<Scalars['String']['output']>;
  /** weight for appointment notifications */
  sortWeight: Scalars['Int']['output'];
  /**
   * weight for appointment notifications
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int']['output'];
  /** The time at which the appointment is scheduled to start */
  startAt: Scalars['DateTime']['output'];
  /** The state of the appointment (created, booked, confirmed, arrived, started, cancelled, seen, or no_show) */
  state?: Maybe<Scalars['String']['output']>;
  /** The in-person day-of tracking status of the appointment */
  status?: Maybe<Scalars['String']['output']>;
  /** Last updated datetime of status */
  statusLastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The local timezone in which the appointment will take place */
  timezone?: Maybe<Scalars['String']['output']>;
  /** The local IANA-formatted timezone in which the appointment will take place */
  timezoneTzinfo?: Maybe<Scalars['TZInfo']['output']>;
  /** Google Maps link with directions to the office. Null if the visit is remote */
  travelDirectionsLink?: Maybe<Scalars['String']['output']>;
  /** description for Remindable interface */
  universalLink: Scalars['String']['output'];
  /**
   * description for Remindable interface
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String']['output'];
  /** Unregistered patient associated with the appointment, if any */
  unregisteredPatient?: Maybe<UnregisteredPatient>;
  /** The time at which the appointment record was last updated */
  updatedAt: Scalars['DateTime']['output'];
  /** The visit summary for the appointment */
  visitSummary?: Maybe<Summary>;
  /** The visit summary for the appointment */
  visitSummaryId?: Maybe<Scalars['ID']['output']>;
};

/** The user who acted on and changed an attribute of a given appointment */
export type AppointmentActor = {
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AppointmentCancellationReason = {
  __typename?: 'AppointmentCancellationReason';
  displayReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type AppointmentCommon = {
  /** id of appointment object */
  id: Scalars['ID']['output'];
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Appointment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int']['output'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

export type AppointmentInventory = {
  __typename?: 'AppointmentInventory';
  appointmentInventoryBookableLocations: Array<AppointmentInventoryBookableLocation>;
  /** Whether this is a blocked appointment slot */
  blocked: Scalars['Boolean']['output'];
  bookedAppointments?: Maybe<AppointmentConnection>;
  cancelledAppointments?: Maybe<AppointmentConnection>;
  /** How many people can book this inventory */
  capacity: Scalars['Int']['output'];
  /** A comments about why the appointment slot is blocked */
  comments: Scalars['String']['output'];
  date: Scalars['String']['output'];
  /** How long the appointment will last in seconds */
  duration: Scalars['Int']['output'];
  /** The time the appointment will end */
  endAt: Scalars['DateTime']['output'];
  examRoomId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** A description of the appointment slot */
  label?: Maybe<Scalars['String']['output']>;
  /** Licensing bodies where the inventory is available (e.g. for cross-region booking) */
  licensingBodies: Array<LicensingBody>;
  numBookedAppointments: Scalars['Int']['output'];
  office: AppointmentInventorySearchResultsOffice;
  officeId: Scalars['Int']['output'];
  /** Whether this is an appointment slot reserved for patients in the provider's panel */
  preferredBooking: Scalars['Boolean']['output'];
  provider: AppointmentInventoryProviderSearchResultsProvider;
  providerId: Scalars['Int']['output'];
  /** The URL for the remote video visit */
  remoteMeetingLink?: Maybe<Scalars['String']['output']>;
  /** Whether this is a reserved appointment slot */
  reserved: Scalars['Boolean']['output'];
  /** Whether this is a restricted appointment slot */
  restricted: Scalars['Boolean']['output'];
  restrictedAppointmentTypes?: Maybe<Array<Scalars['String']['output']>>;
  /** The time the appointment will start */
  startAt: Scalars['DateTime']['output'];
  /** @deprecated Use startAt instead */
  startTime: Scalars['String']['output'];
  timezoneName: Scalars['String']['output'];
  timezoneTzinfo: Scalars['String']['output'];
  utcStartDatetime: Scalars['String']['output'];
};


export type AppointmentInventoryBookedAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AppointmentInventoryCancelledAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AppointmentInventoryBlock = {
  __typename?: 'AppointmentInventoryBlock';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AppointmentInventoryBookableLocation = {
  __typename?: 'AppointmentInventoryBookableLocation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AppointmentInventoryBookableLocationInput = {
  officeId?: InputMaybe<Scalars['Int']['input']>;
  serviceAreaId?: InputMaybe<Scalars['Int']['input']>;
  stateId?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for AppointmentInventory. */
export type AppointmentInventoryConnection = {
  __typename?: 'AppointmentInventoryConnection';
  /** A list of edges. */
  edges: Array<AppointmentInventoryEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentInventory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentInventoryEdge = {
  __typename?: 'AppointmentInventoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentInventory>;
};

export type AppointmentInventoryOfficeSearchResults = {
  __typename?: 'AppointmentInventoryOfficeSearchResults';
  office: Office;
  providers: Array<AppointmentInventoryProviderSearchResults>;
};

export type AppointmentInventoryProviderSearchResults = {
  __typename?: 'AppointmentInventoryProviderSearchResults';
  appointmentInventories: Array<AppointmentInventorySearchResultsDay>;
  provider: AppointmentInventoryProviderSearchResultsProvider;
};

export type AppointmentInventoryProviderSearchResultsProvider = {
  __typename?: 'AppointmentInventoryProviderSearchResultsProvider';
  displayName: Scalars['String']['output'];
  excludeClosedPanels: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isPatientBookable: Scalars['Boolean']['output'];
  preferredRole?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
};

export type AppointmentInventorySearchResults = {
  __typename?: 'AppointmentInventorySearchResults';
  count: Scalars['Int']['output'];
  inventories?: Maybe<Array<AppointmentInventory>>;
  offices?: Maybe<Array<AppointmentInventoryOfficeSearchResults>>;
  pcpAvailability: PcpAvailability;
  serviceArea: ServiceArea;
};

export type AppointmentInventorySearchResultsDay = {
  __typename?: 'AppointmentInventorySearchResultsDay';
  appointmentInventories: Array<AppointmentInventory>;
  date: Scalars['Date']['output'];
};

export type AppointmentInventorySearchResultsOffice = {
  __typename?: 'AppointmentInventorySearchResultsOffice';
  address?: Maybe<Address>;
  b2bCompanyId?: Maybe<Scalars['ID']['output']>;
  b2bCompanyIds?: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  mapLink?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export enum AppointmentInventoryView {
  /** Results in chronological order */
  Day = 'DAY',
  /** Results aggregated by office and provider */
  Week = 'WEEK'
}

export type AppointmentLength = {
  __typename?: 'AppointmentLength';
  id: Scalars['ID']['output'];
  length: Scalars['Int']['output'];
};

/** various queryable relationships between patient and appointment */
export enum AppointmentQueryStrategy {
  ConnectedDevice = 'CONNECTED_DEVICE',
  /**
   * For an appointment to be current, it must not have the state `no_show` or
   * `cancelled`. The `end_at` must be in the future. It must _either_ have the
   * `start_at` in the past _or_ be in the state `arrived` or `started`.
   */
  Current = 'CURRENT',
  /** Only includes appointments with state 'no show' or 'cancelled' */
  NotUtilized = 'NOT_UTILIZED',
  Past = 'PAST',
  /** appointments with a state of seen */
  Seen = 'SEEN',
  Upcoming = 'UPCOMING'
}

export type AppointmentRecording = {
  __typename?: 'AppointmentRecording';
  appointment?: Maybe<Appointment>;
  appointmentRecordingFeedback: Array<AppointmentRecordingFeedback>;
  createdAt: Scalars['DateTime']['output'];
  defaultFields?: Maybe<Scalars['JSON']['output']>;
  detailedLaunchFeedback?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  internalUser?: Maybe<InternalUser>;
  isStreaming?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  recordingDuration?: Maybe<Scalars['String']['output']>;
  recordingEndedAt?: Maybe<Scalars['DateTime']['output']>;
  recordingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  /** presigned url location for scribe summary after a completed stream */
  scribeSummaryUrl?: Maybe<Scalars['String']['output']>;
  /** presigned url location for scribe transcript after a completed stream */
  scribeTranscriptUrl?: Maybe<Scalars['String']['output']>;
  state: AppointmentRecordingState;
  streamEndsAt?: Maybe<Scalars['DateTime']['output']>;
  /** status of healthscribe streaming job */
  streamStatus?: Maybe<AppointmentRecordingStreamStatus>;
  summaryForPlanSection?: Maybe<Array<AppointmentRecordingSummary>>;
  summaryForSection?: Maybe<AppointmentRecordingSummary>;
  transcript: Array<TranscriptSegment>;
  transcriptIsTooOld?: Maybe<Scalars['Boolean']['output']>;
  transcriptionEndedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type AppointmentRecordingSummaryForPlanSectionArgs = {
  name: SummaryPlanSectionEnum;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  split?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AppointmentRecordingSummaryForSectionArgs = {
  name: CombinedSummarySection;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for AppointmentRecording. */
export type AppointmentRecordingConnection = {
  __typename?: 'AppointmentRecordingConnection';
  /** A list of edges. */
  edges: Array<AppointmentRecordingEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentRecording>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentRecordingEdge = {
  __typename?: 'AppointmentRecordingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentRecording>;
};

export type AppointmentRecordingFeedback = {
  __typename?: 'AppointmentRecordingFeedback';
  rating: Scalars['Int']['output'];
  summarySection: CombinedSummarySection;
};

export type AppointmentRecordingFeedbackInput = {
  lineId?: InputMaybe<Scalars['ID']['input']>;
  rating: Scalars['Int']['input'];
  summarySection: CombinedSummarySection;
};

export enum AppointmentRecordingState {
  Archived = 'archived',
  ReadyToRecord = 'ready_to_record',
  Recording = 'recording',
  Transcribed = 'transcribed',
  Transcribing = 'transcribing',
  TranscribingFailed = 'transcribing_failed'
}

export enum AppointmentRecordingStreamStatus {
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** CREATED */
  Created = 'CREATED',
  /** ERROR */
  Error = 'ERROR',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** PENDING_ASSET_GENERATION */
  PendingAssetGeneration = 'PENDING_ASSET_GENERATION'
}

export type AppointmentRecordingSummary = {
  __typename?: 'AppointmentRecordingSummary';
  lines: Array<SummaryLine>;
  name: Scalars['String']['output'];
  rating?: Maybe<Scalars['Int']['output']>;
  suggestedProblem?: Maybe<SuggestedProblem>;
};

export type AppointmentRoom = {
  __typename?: 'AppointmentRoom';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** How appointments in a series relate to each other */
export type AppointmentSeries = {
  __typename?: 'AppointmentSeries';
  /** the status of the series */
  active: Scalars['Boolean']['output'];
  /** the type and order of appointments in series */
  appointmentTypes?: Maybe<AppointmentTypeConnection>;
  /** the id of the series */
  id: Scalars['ID']['output'];
  /** the default number of weeks between appointments in series */
  interval: Scalars['Int']['output'];
  /** the number of appointments in the series */
  length: Scalars['Int']['output'];
  /** the name of the series */
  name: Scalars['String']['output'];
};


/** How appointments in a series relate to each other */
export type AppointmentSeriesAppointmentTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Enum for appointment series interval selection */
export enum AppointmentSeriesInterval {
  /** Repeats every two weeks */
  BiWeekly = 'BI_WEEKLY',
  /** Repeats every month */
  Monthly = 'MONTHLY',
  /** One-time appointment */
  Single = 'SINGLE',
  /** Repeats every week */
  Weekly = 'WEEKLY'
}

export type AppointmentStatus = {
  __typename?: 'AppointmentStatus';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type AppointmentType = AppointmentTypeCommon & {
  __typename?: 'AppointmentType';
  appointmentSeries?: Maybe<AppointmentSeries>;
  /** Used to display insurance capture during the appointment booking mobile flow */
  billable: Scalars['Boolean']['output'];
  /** The color associated with this appointment type */
  color: Scalars['String']['output'];
  /** A description of this appointment type */
  description?: Maybe<Scalars['String']['output']>;
  /** The name of the appointment type */
  displayName?: Maybe<Scalars['String']['output']>;
  /**
   * Whether this appointment type should display provider bios
   * @deprecated This field should be removed in the future as it is no longer used
   */
  displayProviderBios: Scalars['Boolean']['output'];
  /** Length of appointment */
  displayedDuration?: Maybe<Scalars['Int']['output']>;
  firstVisitAppointmentType?: Maybe<AppointmentType>;
  /** id of the AppointmentType object */
  id: Scalars['ID']['output'];
  /** Whether this is a billable appointment */
  isBillable: Scalars['Boolean']['output'];
  /** Whether this is a brief appointment */
  isBrief: Scalars['Boolean']['output'];
  isEmailed: Scalars['Boolean']['output'];
  /** Whether this appointment is for new patients */
  isNewPatient?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this is a primary care appointment */
  isPrimary: Scalars['Boolean']['output'];
  /** Whether this is a specialty care appointment */
  isSpecialty: Scalars['Boolean']['output'];
  /** Whether this is an urgent care appointment */
  isUrgent: Scalars['Boolean']['output'];
  /** Whether this is a walkin appointment */
  isWalkin: Scalars['Boolean']['output'];
  /** Whether the appointment type is a lab service */
  labService?: Maybe<Scalars['Boolean']['output']>;
  /** A longer description of this appointment type */
  longDescription?: Maybe<Scalars['String']['output']>;
  /** The internal name of this appointment type */
  name: Scalars['String']['output'];
  /** Whether patients are able to book the appointment themselves */
  onlineBookable: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  /** Whether the appointment type requires a provider */
  providerlessSearch?: Maybe<Scalars['Boolean']['output']>;
  /** Whether this is a remote appointment */
  remote?: Maybe<Scalars['Boolean']['output']>;
  remoteAppointmentType?: Maybe<AppointmentType>;
  /**
   * The corresponding remote appointment type id if it exists
   * @deprecated Use remoteAppointmentType instead
   */
  remoteAppointmentTypeId?: Maybe<Scalars['ID']['output']>;
  /** Whether this appointment type requires a HIR authorization from the current member */
  requiresHirAuthorization: Scalars['Boolean']['output'];
  uuid: Scalars['ID']['output'];
  /** Whether this appointment type has the waitlist feature enabled (in the given service area, if provided) */
  waitlistEnabled: Scalars['Boolean']['output'];
};


export type AppointmentTypeWaitlistEnabledArgs = {
  serviceAreaId?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for AppointmentType. */
export type AppointmentTypeAlternative = {
  __typename?: 'AppointmentTypeAlternative';
  /** A list of edges. */
  edges: Array<AppointmentTypeAlternativeEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Extra metadata about an appointment type */
export type AppointmentTypeAlternativeEdge = {
  __typename?: 'AppointmentTypeAlternativeEdge';
  /** Labels for 'try searching for...' recommendations */
  alternativeLabel: Label;
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentType>;
};

export type AppointmentTypeCommon = {
  /** id of the AppointmentType object */
  id: Scalars['ID']['output'];
  /** uuid of the AppointmentType object */
  uuid: Scalars['ID']['output'];
};

/** The connection type for AppointmentType. */
export type AppointmentTypeConnection = {
  __typename?: 'AppointmentTypeConnection';
  /** A list of edges. */
  edges: Array<AppointmentTypeEdge>;
  /** A list of nodes. */
  nodes: Array<AppointmentType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AppointmentTypeEdge = {
  __typename?: 'AppointmentTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AppointmentType>;
};

export type AppointmentTypeGroup = {
  __typename?: 'AppointmentTypeGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AppointmentsSearchResults = {
  __typename?: 'AppointmentsSearchResults';
  nodes: Array<AppointmentsSearchResultsAppointment>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type AppointmentsSearchResultsAppointment = {
  __typename?: 'AppointmentsSearchResultsAppointment';
  /** The kind of appointment */
  appointmentType: AppointmentsSearchResultsAppointmentType;
  /** That state of approval for the appointment */
  approved: Scalars['Boolean']['output'];
  /** The internal user that approved the appointment */
  approvedBy?: Maybe<AppointmentsSearchResultsInternalUser>;
  /** Comments about the appointment */
  comments?: Maybe<Scalars['String']['output']>;
  /** The time at which the appointment is scheduled to conclude */
  endAt: Scalars['DateTime']['output'];
  /** The ID of the appointment */
  id: Scalars['ID']['output'];
  /** The office of the appointment */
  office: AppointmentsSearchResultsOffice;
  /** The patient of the appointment */
  patient: AppointmentsSearchResultsPatient;
  /** The provider of the appointment */
  provider: AppointmentsSearchResultsInternalUser;
  /** The patient-reported reason for the appointment */
  reason: Scalars['String']['output'];
  /** The room selection appointed in the appointment drawer for the patient */
  room?: Maybe<Scalars['String']['output']>;
  /** The time at which the appointment is scheduled to start */
  startAt: Scalars['DateTime']['output'];
  /** The state of the appointment (created, booked, confirmed, arrived, started, cancelled, seen, or no_show) */
  state?: Maybe<Scalars['String']['output']>;
  /**
   * The in-person tracking status of the appointment. Used for communicating
   * details about a current appointment amongst the office staff
   */
  status?: Maybe<Scalars['String']['output']>;
  /** The time the in-person tracking status was last updated */
  statusLastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AppointmentsSearchResultsAppointmentType = {
  __typename?: 'AppointmentsSearchResultsAppointmentType';
  color: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isWalkin: Scalars['Boolean']['output'];
  uuid: Scalars['ID']['output'];
};

export type AppointmentsSearchResultsInternalUser = {
  __typename?: 'AppointmentsSearchResultsInternalUser';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preferredRole: AppointmentsSearchResultsInternalUserPreferredRole;
};

export type AppointmentsSearchResultsInternalUserPreferredRole = {
  __typename?: 'AppointmentsSearchResultsInternalUserPreferredRole';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AppointmentsSearchResultsOffice = {
  __typename?: 'AppointmentsSearchResultsOffice';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AppointmentsSearchResultsPatient = {
  __typename?: 'AppointmentsSearchResultsPatient';
  dob?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insuranceNetworkStatus?: Maybe<Scalars['String']['output']>;
  insurances: Array<AppointmentsSearchResultsPatientInsurance>;
  lastName: Scalars['String']['output'];
  membership?: Maybe<AppointmentsSearchResultsPatientMembership>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredName: Scalars['String']['output'];
  unregistered: Scalars['Boolean']['output'];
  waystarEligibiltyUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  waystarEligilityStatus?: Maybe<Scalars['String']['output']>;
};

export type AppointmentsSearchResultsPatientInsurance = {
  __typename?: 'AppointmentsSearchResultsPatientInsurance';
  effectiveAt?: Maybe<Scalars['DateTime']['output']>;
  eligiblityStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insuranceCarrier?: Maybe<Scalars['String']['output']>;
  insuranceNetworkStatus?: Maybe<Scalars['String']['output']>;
  insuranceType?: Maybe<Scalars['String']['output']>;
  subscriberNumber?: Maybe<Scalars['String']['output']>;
};

export type AppointmentsSearchResultsPatientMembership = {
  __typename?: 'AppointmentsSearchResultsPatientMembership';
  isB2b: Scalars['Boolean']['output'];
  isDpc: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ArchiveInsurance */
export type ArchiveInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of ArchiveInsurance. */
export type ArchiveInsurancePayload = {
  __typename?: 'ArchiveInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean']['output'];
};

/** Interface for different assignees, typically a role or internal user */
export type Assignee = {
  displayName: Scalars['String']['output'];
  normalizedId: Scalars['ID']['output'];
};

/** Autogenerated input type of AttachDocumentToProcedure */
export type AttachDocumentToProcedureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  procedureOriginId: Scalars['ID']['input'];
  procedureOriginType: ProcedureOriginType;
};

/** Autogenerated return type of AttachDocumentToProcedure. */
export type AttachDocumentToProcedurePayload = {
  __typename?: 'AttachDocumentToProcedurePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AttachServiceTicketImproperlyClosedOrder */
export type AttachServiceTicketImproperlyClosedOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of AttachServiceTicketImproperlyClosedOrder. */
export type AttachServiceTicketImproperlyClosedOrderPayload = {
  __typename?: 'AttachServiceTicketImproperlyClosedOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicketImproperlyClosedOrder?: Maybe<ServiceTicketImproperlyClosedOrder>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AuthorizeConnectedDevices */
export type AuthorizeConnectedDevicesInput = {
  authorized?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of AuthorizeConnectedDevices. */
export type AuthorizeConnectedDevicesPayload = {
  __typename?: 'AuthorizeConnectedDevicesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of AutoVerifyInsuranceOcrResult */
export type AutoVerifyInsuranceOcrResultInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  uuid: Scalars['ID']['input'];
};

/** Autogenerated return type of AutoVerifyInsuranceOcrResult. */
export type AutoVerifyInsuranceOcrResultPayload = {
  __typename?: 'AutoVerifyInsuranceOcrResultPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Insurance plan object */
  insurancePlan?: Maybe<InsurancePlan>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** Subscriber number */
  subscriberNumber?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  /** Waystar status object */
  waystarEligibilityStatus?: Maybe<WaystarEligibilityStatus>;
  /** Waystar error - waystar text response */
  waystarError?: Maybe<Scalars['String']['output']>;
};

export type B2bCompany = {
  __typename?: 'B2bCompany';
  activationCodeRetrievableViaEmail: Scalars['Boolean']['output'];
  b2bEmailDomains?: Maybe<Array<Scalars['String']['output']>>;
  customElistError?: Maybe<Scalars['String']['output']>;
  customNetworks?: Maybe<Array<CustomNetwork>>;
  /** Finds a B2B discount type by its code */
  discountType?: Maybe<DiscountType>;
  displayElistError: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  enterprisePedsRegistrationEnabled: Scalars['Boolean']['output'];
  /** @deprecated Use verifyDependentInfo instead to determine if dependents should be verified against the e-list */
  hasEligibleDependents: Scalars['Boolean']['output'];
  /** @deprecated Never used */
  hasEligibleEmployees: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includesDependent: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  verifyDependentInfo: Scalars['Boolean']['output'];
};


export type B2bCompanyDiscountTypeArgs = {
  code: Scalars['String']['input'];
};

export type B2bPlan = Plan & {
  __typename?: 'B2bPlan';
  company?: Maybe<B2bCompany>;
  hasActiveDiscountTypes: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isCore: Scalars['Boolean']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Formulary benefits message */
export type BenefitsMessage = {
  __typename?: 'BenefitsMessage';
  longMessage?: Maybe<Scalars['String']['output']>;
  shortMessage?: Maybe<Scalars['String']['output']>;
  type: BenefitsMessageTypeEnum;
};

export enum BenefitsMessageTypeEnum {
  AgeLimits = 'AGE_LIMITS',
  Copay = 'COPAY',
  Formulary = 'FORMULARY',
  GenderLimits = 'GENDER_LIMITS',
  GeneralInformation = 'GENERAL_INFORMATION',
  PriorAuthorization = 'PRIOR_AUTHORIZATION',
  ProductCoverageExclusion = 'PRODUCT_COVERAGE_EXCLUSION',
  QuantityLimits = 'QUANTITY_LIMITS',
  StepTherapy = 'STEP_THERAPY'
}

/** Formulary benefits response for a patient */
export type BenefitsResponse = {
  __typename?: 'BenefitsResponse';
  alternatives?: Maybe<FormularyAlternatives>;
  errors?: Maybe<Array<Error>>;
  /** Formulary information for the request medication */
  formulary?: Maybe<MedicationFormulary>;
  id: Scalars['ID']['output'];
  pbm?: Maybe<ActivePharmacyCoverageDetails>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum BenefitsTierEnum {
  /** Non-Reimbursable are Prescription drugs that the patient’s health plan will not pay for. */
  NotCovered = 'NOT_COVERED',
  /** On Formulary/Preferred (Higher) */
  Tier_1 = 'TIER_1',
  /** Non-preferred drugs are often covered by the health plan, but at a higher patient copay. */
  Tier_2 = 'TIER_2',
  /** Prescription drugs that are not on a plan’s formulary. */
  Tier_3 = 'TIER_3',
  /** Unknown tier */
  Unknown = 'UNKNOWN'
}

export type BillingDistrict = {
  __typename?: 'BillingDistrict';
  id: Scalars['ID']['output'];
  insuranceNetworks?: Maybe<Array<InsuranceNetwork>>;
  insuranceTaxId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  serviceArea?: Maybe<ServiceArea>;
};

export type BillingDistrictSearch = {
  __typename?: 'BillingDistrictSearch';
  results: Array<BillingDistrict>;
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type BookAppointmentAttributes = {
  appointmentTypeId: Scalars['ID']['input'];
  /** ID of the appointment inventory that will be booked as a part of rescheduling. */
  inventoryId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  visitReasonCategories?: InputMaybe<Array<VisitReasonAttributes>>;
};

/** Autogenerated input type of BookAppointment */
export type BookAppointmentInput = {
  appointmentAttributes: BookAppointmentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  internalBookingAttributes?: InputMaybe<InternalBookingAttributes>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of BookAppointment. */
export type BookAppointmentPayload = {
  __typename?: 'BookAppointmentPayload';
  /** The new appointment that was booked. */
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient: Patient;
  success: Scalars['Boolean']['output'];
};

export type BookableProviderSearchResult = SearchResult & {
  __typename?: 'BookableProviderSearchResult';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  node: Provider;
};

/** Autogenerated input type of BulkUpdateInsurancePlan */
export type BulkUpdateInsurancePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insurancePlanIds: Array<Scalars['ID']['input']>;
  insuranceTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of BulkUpdateInsurancePlan. */
export type BulkUpdateInsurancePlanPayload = {
  __typename?: 'BulkUpdateInsurancePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  insurancePlans?: Maybe<Array<InsurancePlan>>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CancelAppointment */
export type CancelAppointmentInput = {
  cancellationReasonId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  sendNotice: Scalars['Boolean']['input'];
};

/** Autogenerated return type of CancelAppointment. */
export type CancelAppointmentPayload = {
  __typename?: 'CancelAppointmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CancelServiceTicketTransmission */
export type CancelServiceTicketTransmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelServiceTicketTransmission. */
export type CancelServiceTicketTransmissionPayload = {
  __typename?: 'CancelServiceTicketTransmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  success: Scalars['Boolean']['output'];
};

/** The connection type for CareTeamMember. */
export type CareTeam = {
  __typename?: 'CareTeam';
  /** A list of edges. */
  edges: Array<CareTeamEdge>;
  /** A list of nodes. */
  nodes: Array<CareTeamMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  pcp?: Maybe<Provider>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type CareTeamEdge = {
  __typename?: 'CareTeamEdge';
  /** @deprecated this resource does not yet use cursor based pagination but hopefully will soon */
  cursor?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  /** The item at the end of the edge. */
  node?: Maybe<CareTeamMember>;
  reasons?: Maybe<Array<CareTeamReason>>;
};

/**
 * The members of a patient's team. Currently only internal users, but we have had
 * conversations about including specalists and medical powers of attorney, so this
 * API allows for that
 */
export type CareTeamMember = InternalUser;

/** why is this individual on this patient's team */
export type CareTeamReason = Appointment | Panel | ProgramEnrollment | ProgramEnrollmentCareTeam;

export enum CareTeamType {
  /**
   * PCP + mindset providers with domain specific labels (e.g. Therapist, Care
   * Manager, Unknown) instead of default program enrollment label
   */
  BehavioralHealth = 'BEHAVIORAL_HEALTH',
  /** Everything in LONGITUDINAL plus appointments */
  Comprehensive = 'COMPREHENSIVE',
  /** PCP, then any program-based care */
  Longitudinal = 'LONGITUDINAL'
}

export type CareTeamsCareGiver = {
  __typename?: 'CareTeamsCareGiver';
  careTeamId: Scalars['ID']['output'];
  provider: Provider;
  role: Scalars['String']['output'];
};

export type CashPrice = {
  __typename?: 'CashPrice';
  price?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CazRequest = {
  __typename?: 'CazRequest';
  createdAt: Scalars['Float']['output'];
  patientId: Scalars['String']['output'];
  practitioner: Practitioner;
  reason: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  reviewerId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  ttl: Scalars['Float']['output'];
  updatedAt: Scalars['Float']['output'];
};

export type CervicalCancerReportContent = {
  __typename?: 'CervicalCancerReportContent';
  /** Result report and any additional detail */
  description: Scalars['HTML']['output'];
};

export type CervicalCancerReportContents = {
  __typename?: 'CervicalCancerReportContents';
  /** Cytology result report */
  cytologyResult?: Maybe<CervicalCancerReportContent>;
  ending: Scalars['String']['output'];
  /** HPV result report */
  hpvResult?: Maybe<CervicalCancerReportContent>;
  intro: Scalars['String']['output'];
  /** List of other infections to report */
  otherInfections?: Maybe<CervicalCancerReportSection>;
  /** Indicates whether patient needs to provide previous records */
  previousRecordsNeeded?: Maybe<CervicalCancerReportContent>;
  /** Recommendation for next steps, based on results */
  recommendation?: Maybe<CervicalCancerReportContent>;
  title: Scalars['String']['output'];
};

export enum CervicalCancerReportCytologyResult {
  Agc = 'AGC',
  AgcAndOver = 'AGC_AND_OVER',
  Ascus = 'ASCUS',
  AscH = 'ASC_H',
  Hsil = 'HSIL',
  Inflammation = 'INFLAMMATION',
  Lsil = 'LSIL',
  Nilm = 'NILM',
  Unsatisfactory = 'UNSATISFACTORY',
  UnsatisfactorySecond = 'UNSATISFACTORY_SECOND'
}

export enum CervicalCancerReportHpvResult {
  Negative_16Hpv = 'NEGATIVE_16_HPV',
  NegativeHpv = 'NEGATIVE_HPV',
  Positive_16Hpv = 'POSITIVE_16_HPV',
  PositiveHpvUntyped = 'POSITIVE_HPV_UNTYPED'
}

export enum CervicalCancerReportOtherInfection {
  Cocco = 'COCCO',
  NegativeGcct = 'NEGATIVE_GCCT',
  PositiveActinomyces = 'POSITIVE_ACTINOMYCES',
  PositiveHsvAsymp = 'POSITIVE_HSV_ASYMP',
  PositiveHsvHistory = 'POSITIVE_HSV_HISTORY',
  PositiveTrichomonas = 'POSITIVE_TRICHOMONAS',
  PositiveYeast = 'POSITIVE_YEAST',
  PositiveYeastAndCocco = 'POSITIVE_YEAST_AND_COCCO'
}

export enum CervicalCancerReportRecommendation {
  Colposcopy = 'COLPOSCOPY',
  ColpoEmb = 'COLPO_EMB',
  FiveYears = 'FIVE_YEARS',
  OneYear = 'ONE_YEAR',
  RepeatCcs = 'REPEAT_CCS',
  ThreeYears = 'THREE_YEARS'
}

export type CervicalCancerReportSection = {
  __typename?: 'CervicalCancerReportSection';
  /** List of results reports and additional details */
  descriptions: Array<Scalars['HTML']['output']>;
  /** Section header */
  header: Scalars['String']['output'];
};

export type CervicalCancerResultOption = {
  __typename?: 'CervicalCancerResultOption';
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CervicalCancerResultOptions = {
  __typename?: 'CervicalCancerResultOptions';
  /** Options according to different cytology results */
  cytologyResults: Array<CervicalCancerResultOption>;
  /** Options according to different hpv results */
  hpvResults: Array<CervicalCancerResultOption>;
  /** Options for infections beyond pap and hpv tests */
  otherInfections: Array<CervicalCancerResultOption>;
  /** Option for action required */
  previousRecordsNeeded: CervicalCancerResultOption;
  /** Options for follow-up recommendations */
  recommendations: Array<CervicalCancerResultOption>;
};

export type CervicalCancerScreeningReportInput = {
  cytologyResult?: InputMaybe<CervicalCancerReportCytologyResult>;
  hpvResult?: InputMaybe<CervicalCancerReportHpvResult>;
  otherInfections?: InputMaybe<Array<CervicalCancerReportOtherInfection>>;
  previousRecordsNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  recommendation?: InputMaybe<CervicalCancerReportRecommendation>;
};

export type ChargeExemptionDetails = {
  __typename?: 'ChargeExemptionDetails';
  chargeExempt: Scalars['Boolean']['output'];
  factors?: Maybe<Array<Scalars['String']['output']>>;
};

/** Autogenerated input type of ChargePatientForCopay */
export type ChargePatientForCopayInput = {
  amountCents: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  serviceTicketId: Scalars['ID']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of ChargePatientForCopay. */
export type ChargePatientForCopayPayload = {
  __typename?: 'ChargePatientForCopayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ChargePatientForServiceTicket */
export type ChargePatientForServiceTicketInput = {
  amountCents: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  serviceTicketId: Scalars['ID']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of ChargePatientForServiceTicket. */
export type ChargePatientForServiceTicketPayload = {
  __typename?: 'ChargePatientForServiceTicketPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean']['output'];
};

export type ChartClaimingPatientAttributes = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  legalFirstName?: InputMaybe<Scalars['String']['input']>;
  legalLastName?: InputMaybe<Scalars['String']['input']>;
};

export type ChartingTemplate = {
  __typename?: 'ChartingTemplate';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  format: ChartingTemplateFormat;
  id: Scalars['ID']['output'];
  internalUser?: Maybe<InternalUser>;
  name: Scalars['String']['output'];
  purpose: ChartingTemplatePurpose;
  tags: Array<Scalars['String']['output']>;
  type: ChartingTemplateType;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<Scalars['String']['output']>;
};

/** The connection type for ChartingTemplate. */
export type ChartingTemplateConnection = {
  __typename?: 'ChartingTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChartingTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChartingTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ChartingTemplateEdge = {
  __typename?: 'ChartingTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ChartingTemplate>;
};

export enum ChartingTemplateFormat {
  Html = 'HTML',
  Plaintext = 'PLAINTEXT'
}

export enum ChartingTemplatePurpose {
  AutoLabs = 'AUTO_LABS',
  Personal = 'PERSONAL',
  Public = 'PUBLIC'
}

/** Search parameters for retrieving charting templates */
export type ChartingTemplateSearchParams = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  purposes?: InputMaybe<Array<ChartingTemplatePurpose>>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  terms?: InputMaybe<Scalars['String']['input']>;
  type: ChartingTemplateType;
};

export enum ChartingTemplateType {
  Message = 'MESSAGE',
  Text = 'TEXT'
}

/** Autogenerated input type of CheckInsuranceEligibility */
export type CheckInsuranceEligibilityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CheckInsuranceEligibility. */
export type CheckInsuranceEligibilityPayload = {
  __typename?: 'CheckInsuranceEligibilityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  insurances?: Maybe<Array<Insurance>>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type CheckVersion = {
  __typename?: 'CheckVersion';
  message?: Maybe<Scalars['String']['output']>;
  minimumSupportedVersion?: Maybe<Scalars['String']['output']>;
  updateRequired: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ClaimChart */
export type ClaimChartInput = {
  accessKey: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  patientAttributes: ChartClaimingPatientAttributes;
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ClaimChart. */
export type ClaimChartPayload = {
  __typename?: 'ClaimChartPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type ClaimCodeValidation = {
  __typename?: 'ClaimCodeValidation';
  billingCycle?: Maybe<Scalars['String']['output']>;
  error?: Maybe<ClaimCodeValidationError>;
  isMembershipManager?: Maybe<Scalars['Boolean']['output']>;
  isValidClaimCode: Scalars['Boolean']['output'];
  seatsAvailable?: Maybe<Scalars['Int']['output']>;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
  trial?: Maybe<Scalars['Boolean']['output']>;
};

export type ClaimCodeValidationError = {
  __typename?: 'ClaimCodeValidationError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

/** Autogenerated input type of ClaimVisit */
export type ClaimVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  visitId: Scalars['ID']['input'];
};

/** Autogenerated return type of ClaimVisit. */
export type ClaimVisitPayload = {
  __typename?: 'ClaimVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CloneConsultOrder */
export type CloneConsultOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CloneConsultOrder. */
export type CloneConsultOrderPayload = {
  __typename?: 'CloneConsultOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  consultOrder?: Maybe<ConsultOrder>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type CollectionInstruction = {
  __typename?: 'CollectionInstruction';
  imageUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  sampleDescription?: Maybe<Scalars['String']['output']>;
  sampleType?: Maybe<Scalars['String']['output']>;
  sampleTypeDescription?: Maybe<Scalars['String']['output']>;
  shipContainers?: Maybe<Array<ShipContainer>>;
  sizeDescription?: Maybe<Scalars['String']['output']>;
  specialInstructions?: Maybe<Array<SpecialInstruction>>;
  yieldVolume: Scalars['Float']['output'];
  yieldVolumeUnits?: Maybe<Scalars['String']['output']>;
};

export enum CombinedSummarySection {
  AfterVisitSummary = 'AFTER_VISIT_SUMMARY',
  Assessment = 'ASSESSMENT',
  AssessmentAndPlan = 'ASSESSMENT_AND_PLAN',
  ChiefComplaint = 'CHIEF_COMPLAINT',
  HistoryOfPresentIllness = 'HISTORY_OF_PRESENT_ILLNESS',
  PastFamilyHistory = 'PAST_FAMILY_HISTORY',
  PastMedicalHistory = 'PAST_MEDICAL_HISTORY',
  PastSocialHistory = 'PAST_SOCIAL_HISTORY',
  PhysicalExamination = 'PHYSICAL_EXAMINATION',
  Plan = 'PLAN',
  ReviewOfSystems = 'REVIEW_OF_SYSTEMS',
  Subjective = 'SUBJECTIVE',
  Vitals = 'VITALS'
}

/** Autogenerated input type of CompleteProcedureInteraction */
export type CompleteProcedureInteractionInput = {
  allowClosedTicket?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CompleteProcedureInteraction. */
export type CompleteProcedureInteractionPayload = {
  __typename?: 'CompleteProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  detailedErrors?: Maybe<Scalars['JSON']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean']['output'];
};

export type ConsultOrder = {
  __typename?: 'ConsultOrder';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  state?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ConsultantAutocomplete = {
  __typename?: 'ConsultantAutocomplete';
  label: Scalars['String']['output'];
};

export type Contact = {
  __typename?: 'Contact';
  acceptingNewPatients: Scalars['Boolean']['output'];
  acceptsInsurance: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  availability: Rating;
  companyName?: Maybe<Scalars['String']['output']>;
  contactInsurances: Array<ContactInsurance>;
  createdAt: Scalars['DateTime']['output'];
  customNetworks: Array<CustomNetwork>;
  customWarning?: Maybe<Scalars['String']['output']>;
  degrees?: Maybe<Scalars['String']['output']>;
  descendants: Array<Contact>;
  diagnosticGroups: Array<DiagnosticGroup>;
  displayName?: Maybe<Scalars['String']['output']>;
  emails: Array<Email>;
  expertise: Rating;
  firstName?: Maybe<Scalars['String']['output']>;
  formerName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  hasPreferredAccess: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  incompleteReason?: Maybe<Scalars['String']['output']>;
  institutions: Array<Institution>;
  insuranceNetworks: Array<InsuranceNetwork>;
  insuranceNotes?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isComplete: Scalars['Boolean']['output'];
  isCustom: Scalars['Boolean']['output'];
  isEconsult: Scalars['Boolean']['output'];
  isEditable: Scalars['Boolean']['output'];
  isElectronicallyIntegrated: Scalars['Boolean']['output'];
  isIntegratedPartner: Scalars['Boolean']['output'];
  isInternal: Scalars['Boolean']['output'];
  isPartner: Scalars['Boolean']['output'];
  isQuality: Scalars['Boolean']['output'];
  kind: ContactKind;
  languagesSpoken?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  noPhysicalLocation: Scalars['Boolean']['output'];
  nonAcceptedContactInsurances: Array<ContactInsurance>;
  npi?: Maybe<Scalars['String']['output']>;
  officeHours?: Maybe<Scalars['String']['output']>;
  otherNotes?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Contact>;
  personality: Rating;
  phoneNumbers: Array<PhoneNumber>;
  specialties: Array<Specialty>;
  /** @deprecated Please use `Contact.systemStatus`, an enum that encodes the same information */
  status?: Maybe<ContactsStatus>;
  suffix?: Maybe<Scalars['String']['output']>;
  systemStatus?: Maybe<ContactStatus>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<InternalUser>;
  value: Rating;
  warnings: Array<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};


export type ContactAddressArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactContactInsurancesArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactCustomNetworksArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactEmailsArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactHasPreferredAccessArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactInsuranceNetworksArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactInsuranceNotesArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactIsElectronicallyIntegratedArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactIsPartnerArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactIsQualityArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactNoPhysicalLocationArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactNonAcceptedContactInsurancesArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContactPhoneNumbersArgs = {
  withInheritedData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactInsurance = {
  __typename?: 'ContactInsurance';
  id: Scalars['ID']['output'];
  insuranceCarrier: InsuranceCarrier;
  insuranceTypes: Array<InsuranceType>;
};

export type ContactInsuranceAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  insuranceCarrierId?: InputMaybe<Scalars['ID']['input']>;
  insuranceTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum ContactKind {
  Consultant = 'consultant',
  FromEmail = 'from_email',
  Generic = 'generic',
  Group = 'group',
  LabProvider = 'lab_provider',
  Practice = 'practice',
  Specialist = 'specialist'
}

export type ContactPageInfo = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type ContactSearchEdge = {
  __typename?: 'ContactSearchEdge';
  cursor: Scalars['String']['output'];
  /** Distance in miles between the searched location and the contact's address. */
  distance?: Maybe<Scalars['Float']['output']>;
  node: Contact;
};

export type ContactSearchPage = {
  __typename?: 'ContactSearchPage';
  edges: Array<ContactSearchEdge>;
  /** @deprecated Use relay based `pageInfo` instead */
  hasNextPage: Scalars['Boolean']['output'];
  /** @deprecated Use relay based `pageInfo` instead */
  nextCursor?: Maybe<Scalars['String']['output']>;
  nodes: Array<Contact>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type ContactSearchQuery = {
  /** Only used when sortBy is RECOMMENDED */
  diagnosticGroupId?: InputMaybe<Scalars['ID']['input']>;
  /** Only used when sortBy is RECOMMENDED */
  filterInNetworkOnly?: InputMaybe<Scalars['Boolean']['input']>;
  insuranceCarrierIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  insuranceTypeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  location?: InputMaybe<Geolocation>;
  /** Only used when sortBy is RECOMMENDED */
  patientId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ContactSearchSortBy>;
};

export type ContactSearchResult = SearchResult & {
  __typename?: 'ContactSearchResult';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export enum ContactSearchSortBy {
  /** Search with match fields and location, without filters */
  Distance = 'DISTANCE',
  /** Search with match fields and location, with filters */
  Recommended = 'RECOMMENDED',
  /** Recommended search, but sorting by contact value ratings first */
  Value = 'VALUE'
}

export enum ContactStatus {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deactivated = 'DEACTIVATED',
  Deceased = 'DECEASED',
  Duplicate = 'DUPLICATE',
  Moved = 'MOVED',
  OutOfBusiness = 'OUT_OF_BUSINESS',
  Retired = 'RETIRED'
}

export type ContactsStatus = {
  __typename?: 'ContactsStatus';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Formulary pharmacy copay */
export type CopayFactors = {
  __typename?: 'CopayFactors';
  copayTier?: Maybe<Scalars['Int']['output']>;
  daysSupplyPerCopay?: Maybe<Scalars['Int']['output']>;
  firstCopayTerm?: Maybe<Scalars['String']['output']>;
  flatCopayAmount?: Maybe<Scalars['Float']['output']>;
  maximumCopay?: Maybe<Scalars['Float']['output']>;
  maximumCopayTier?: Maybe<Scalars['Int']['output']>;
  minimumCopay?: Maybe<Scalars['Float']['output']>;
  outOfPocketRangeEnd?: Maybe<Scalars['Float']['output']>;
  outOfPocketRangeStart?: Maybe<Scalars['Float']['output']>;
  percentCopayRate?: Maybe<Scalars['Float']['output']>;
  pharmacyType?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<CopayProductTypeEnum>;
};

export enum CopayProductTypeEnum {
  Any = 'ANY',
  BrandedGeneric = 'BRANDED_GENERIC',
  Compound = 'COMPOUND',
  Generic = 'GENERIC',
  NotSpecified = 'NOT_SPECIFIED',
  Otc = 'OTC',
  SingleSourceBrand = 'SINGLE_SOURCE_BRAND',
  Supply = 'SUPPLY'
}

/** Autogenerated input type of CreateAdmissionAttachment */
export type CreateAdmissionAttachmentInput = {
  admissionId: Scalars['ID']['input'];
  attachableId: Scalars['ID']['input'];
  attachableType: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAdmissionAttachment. */
export type CreateAdmissionAttachmentPayload = {
  __typename?: 'CreateAdmissionAttachmentPayload';
  admission?: Maybe<Admission>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateAdmission */
export type CreateAdmissionInput = {
  admitReason: Scalars['String']['input'];
  admittedAt?: InputMaybe<Scalars['Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dischargeType?: InputMaybe<AdmissionDischargeType>;
  dischargedAt?: InputMaybe<Scalars['Date']['input']>;
  facilityLocation: Scalars['String']['input'];
  facilityType: AdmissionFacilityType;
  hasDischargeSummary?: InputMaybe<Scalars['Boolean']['input']>;
  hrcmEnrollment?: InputMaybe<HrcmEnrollment>;
  monitoring: AdmissionMonitoring;
  nextCheckInDate?: InputMaybe<Scalars['Date']['input']>;
  nextSteps?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  readmissionRisk?: InputMaybe<ReadmissionRisk>;
  story?: InputMaybe<Scalars['HTML']['input']>;
  type: AdmissionType;
};

/** Autogenerated return type of CreateAdmission. */
export type CreateAdmissionPayload = {
  __typename?: 'CreateAdmissionPayload';
  admission?: Maybe<Admission>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateAppointmentInventory */
export type CreateAppointmentInventoryInput = {
  appointmentInventoryBookableLocations?: InputMaybe<Array<AppointmentInventoryBookableLocationInput>>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  startTime: Scalars['DateTime']['input'];
};

/** Autogenerated return type of CreateAppointmentInventory. */
export type CreateAppointmentInventoryPayload = {
  __typename?: 'CreateAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  inventory?: Maybe<AppointmentInventory>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateAppointmentRecording */
export type CreateAppointmentRecordingInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateAppointmentRecording. */
export type CreateAppointmentRecordingPayload = {
  __typename?: 'CreateAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateBillingDistrictInsuranceNetwork */
export type CreateBillingDistrictInsuranceNetworkInput = {
  billingDistrictId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceNetworkId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateBillingDistrictInsuranceNetwork. */
export type CreateBillingDistrictInsuranceNetworkPayload = {
  __typename?: 'CreateBillingDistrictInsuranceNetworkPayload';
  billingDistrict?: Maybe<BillingDistrict>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateCazRequest */
export type CreateCazRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  reason: Scalars['String']['input'];
};

/** Autogenerated return type of CreateCazRequest. */
export type CreateCazRequestPayload = {
  __typename?: 'CreateCazRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  request?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  acceptingNewPatients?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  addressAttributes?: InputMaybe<AddressAttributes>;
  availability?: InputMaybe<Rating>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  customNetworkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  customWarning?: InputMaybe<Scalars['String']['input']>;
  degrees?: InputMaybe<Scalars['String']['input']>;
  diagnosticGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  emailsAttributes?: InputMaybe<Array<InputMaybe<EmailAttributes>>>;
  expertise?: InputMaybe<Rating>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formerName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  hasPreferredAccess?: InputMaybe<Scalars['Boolean']['input']>;
  incompleteReason?: InputMaybe<Scalars['String']['input']>;
  institutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNetworkIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNotes?: InputMaybe<Scalars['String']['input']>;
  isCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  isEconsult?: InputMaybe<Scalars['Boolean']['input']>;
  isElectronicallyIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegratedPartner?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isPartner?: InputMaybe<Scalars['Boolean']['input']>;
  isQuality?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ContactKind>;
  languagesSpoken?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  noPhysicalLocation?: InputMaybe<Scalars['Boolean']['input']>;
  nonAcceptedContactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  npi?: InputMaybe<Scalars['String']['input']>;
  officeHours?: InputMaybe<Scalars['String']['input']>;
  otherNotes?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  personality?: InputMaybe<Rating>;
  phoneNumbersAttributes?: InputMaybe<Array<InputMaybe<PhoneNumberAttributes>>>;
  specialtyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  status?: InputMaybe<ContactStatus>;
  /** @deprecated use `systemStatus` enum instead */
  statusId?: InputMaybe<Scalars['ID']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Rating>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateContact. */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateHelpRequest */
export type CreateHelpRequestInput = {
  attributes?: InputMaybe<HelpRequestAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  helpflowType: Scalars['String']['input'];
};

/** Autogenerated return type of CreateHelpRequest. */
export type CreateHelpRequestPayload = {
  __typename?: 'CreateHelpRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  helpRequest?: Maybe<HelpRequest>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateInsurance */
export type CreateInsuranceInput = {
  cardImageS3KeyBack?: InputMaybe<Scalars['String']['input']>;
  cardImageS3KeyFront?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coinsurance?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  copay?: InputMaybe<Scalars['Int']['input']>;
  effectiveAt?: InputMaybe<Scalars['String']['input']>;
  groupno: Scalars['String']['input'];
  holderAddress1?: InputMaybe<Scalars['String']['input']>;
  holderAddress2?: InputMaybe<Scalars['String']['input']>;
  holderCity?: InputMaybe<Scalars['String']['input']>;
  holderDob?: InputMaybe<Scalars['String']['input']>;
  holderFirstName?: InputMaybe<Scalars['String']['input']>;
  holderLastName?: InputMaybe<Scalars['String']['input']>;
  holderPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  holderState?: InputMaybe<Scalars['String']['input']>;
  holderZipCode?: InputMaybe<Scalars['String']['input']>;
  insuranceCarrierId?: InputMaybe<Scalars['ID']['input']>;
  insuranceImage?: InputMaybe<S3Attributes>;
  insuranceNetworkId?: InputMaybe<Scalars['ID']['input']>;
  insurancePlanId?: InputMaybe<Scalars['ID']['input']>;
  insuranceRelationshipTypeId?: InputMaybe<Scalars['ID']['input']>;
  insuranceTypeId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  patientId?: InputMaybe<Scalars['ID']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  subscriberNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateInsuranceNetworksProviders */
export type CreateInsuranceNetworksProvidersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceNetworkId: Scalars['ID']['input'];
  providerId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateInsuranceNetworksProviders. */
export type CreateInsuranceNetworksProvidersPayload = {
  __typename?: 'CreateInsuranceNetworksProvidersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insuranceNetworksProviders?: Maybe<InsuranceNetworksProviders>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of CreateInsurance. */
export type CreateInsurancePayload = {
  __typename?: 'CreateInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateInsurancePlan */
export type CreateInsurancePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceCarrierId: Scalars['ID']['input'];
  insuranceTypeId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateInsurancePlan. */
export type CreateInsurancePlanPayload = {
  __typename?: 'CreateInsurancePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insurancePlan?: Maybe<InsurancePlan>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateLabOrderOutreach */
export type CreateLabOrderOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** Boolean indicating whether the lab order outreach should create a patient task. */
  createPatientTask?: InputMaybe<Scalars['Boolean']['input']>;
  /** An internal note describing why this outreach is being run. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Boolean determining whether a lab should be taken fasted or not. */
  fasting?: InputMaybe<Scalars['Boolean']['input']>;
  /** Lab order type IDs to be associated with a lab order. */
  labOrderTypeIds: Array<Scalars['ID']['input']>;
  /** The ID of the Measurement Order Type Source for the lab order. */
  measurementOrderTypeSourceId: Scalars['ID']['input'];
  /** Boolean indicating whether the lab order's review task should be auto finished if results are normal. */
  normalResultAutoFinishesTask: Scalars['Boolean']['input'];
  /** A note describing the actual instructions for a lab order action item. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** An internal note to the phlebotomist collecting the lab. */
  noteToPhleb?: InputMaybe<Scalars['String']['input']>;
  /** Pairs of Patient and Signer IDs. */
  patientAndProviderIds: Array<PatientProviderInput>;
  /** Problem type IDs to be associated with a lab order. */
  problemTypeId: Scalars['ID']['input'];
  /** The date for the lab order to be scheduled. */
  scheduledDate: Scalars['DateTime']['input'];
  /** Boolean determining whether a lab should be collected stat or not. */
  stat?: InputMaybe<Scalars['Boolean']['input']>;
  /** The title of the Lab Order outreach */
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateLabOrderOutreach. */
export type CreateLabOrderOutreachPayload = {
  __typename?: 'CreateLabOrderOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** The successfully created outreach manager batch. */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMassAppointmentCancellationRequest */
export type CreateMassAppointmentCancellationRequestInput = {
  appointmentCancellationReasonId: Scalars['ID']['input'];
  appointmentIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  secureMessageBody?: InputMaybe<Scalars['String']['input']>;
  secureMessageTitle?: InputMaybe<Scalars['String']['input']>;
  sendEmailNotification?: InputMaybe<Scalars['Boolean']['input']>;
  sendSecureMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of CreateMassAppointmentCancellationRequest. */
export type CreateMassAppointmentCancellationRequestPayload = {
  __typename?: 'CreateMassAppointmentCancellationRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  massAppointmentChangeRequest?: Maybe<MassAppointmentChangeRequest>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMiscClericalNote */
export type CreateMiscClericalNoteInput = {
  appointmentId?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  noteBodyText?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['Int']['input'];
  processingInstructions?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateMiscClericalNote. */
export type CreateMiscClericalNotePayload = {
  __typename?: 'CreateMiscClericalNotePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  note?: Maybe<Note>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMlFeedbackProcedureSuggestion */
export type CreateMlFeedbackProcedureSuggestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackCategory: Scalars['String']['input'];
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  mlBillingProcedureSuggestionId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMlFeedbackProcedureSuggestion. */
export type CreateMlFeedbackProcedureSuggestionPayload = {
  __typename?: 'CreateMlFeedbackProcedureSuggestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureSuggestionFeedback?: Maybe<MlFeedbackProcedureSuggestion>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateMroWorkflow */
export type CreateMroWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  mroWorkflowTemplateId: Scalars['Int']['input'];
  patientId: Scalars['ID']['input'];
  state: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

/** Autogenerated return type of CreateMroWorkflow. */
export type CreateMroWorkflowPayload = {
  __typename?: 'CreateMroWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mroWorkflow?: Maybe<MroWorkflow>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateOrUpdateInsurancePlanInNetworkStatus */
export type CreateOrUpdateInsurancePlanInNetworkStatusInput = {
  billingDistrictId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insurancePlanIds: Array<Scalars['ID']['input']>;
  status: Scalars['String']['input'];
};

/** Autogenerated return type of CreateOrUpdateInsurancePlanInNetworkStatus. */
export type CreateOrUpdateInsurancePlanInNetworkStatusPayload = {
  __typename?: 'CreateOrUpdateInsurancePlanInNetworkStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insurancePlanIds?: Maybe<Array<Scalars['ID']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateOrUpdateMeasurementForProcedure */
export type CreateOrUpdateMeasurementForProcedureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  measurements: Array<ProcedureMeasurementInputObject>;
  procedureOriginId: Scalars['ID']['input'];
  procedureOriginType: ProcedureOriginType;
};

/** Autogenerated return type of CreateOrUpdateMeasurementForProcedure. */
export type CreateOrUpdateMeasurementForProcedurePayload = {
  __typename?: 'CreateOrUpdateMeasurementForProcedurePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean']['output'];
};

export type CreatePatientAddressAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  kind: AddressKind;
  state: Scalars['String']['input'];
  status: AddressStatus;
  zipCode: Scalars['String']['input'];
};

/** Autogenerated input type of CreatePatientAddress */
export type CreatePatientAddressInput = {
  address: CreatePatientAddressAddressInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePatientAddress. */
export type CreatePatientAddressPayload = {
  __typename?: 'CreatePatientAddressPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePatientEmail */
export type CreatePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: PatientEmailInput;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePatientEmail. */
export type CreatePatientEmailPayload = {
  __typename?: 'CreatePatientEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Email>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePatientEmergencyContact */
export type CreatePatientEmergencyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emergencyContact: EmergencyContactInputObject;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePatientEmergencyContact. */
export type CreatePatientEmergencyContactPayload = {
  __typename?: 'CreatePatientEmergencyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emergencyContact?: Maybe<EmergencyContact>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreatePatientPhoneNumber */
export type CreatePatientPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  phoneNumber: PatientPhoneNumberInput;
};

/** Autogenerated return type of CreatePatientPhoneNumber. */
export type CreatePatientPhoneNumberPayload = {
  __typename?: 'CreatePatientPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  phoneNumber?: Maybe<PhoneNumber>;
  success: Scalars['Boolean']['output'];
};

export type CreatePrescriptionChangeRequestAttributes = {
  medicationOptions?: InputMaybe<Array<Scalars['JSON']['input']>>;
  messageRequestCode: Scalars['String']['input'];
  messageRequestSubcodes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Autogenerated input type of CreatePrescriptionChangeRequests */
export type CreatePrescriptionChangeRequestsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createPrescriptionChangeRequestAttributes: CreatePrescriptionChangeRequestAttributes;
  patientMedicationId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePrescriptionChangeRequests. */
export type CreatePrescriptionChangeRequestsPayload = {
  __typename?: 'CreatePrescriptionChangeRequestsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreatePrescriptionRenewalRequestAttributes = {
  comment?: InputMaybe<Scalars['String']['input']>;
  patientMedicationId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreatePrescriptionRenewalRequests */
export type CreatePrescriptionRenewalRequestsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createPrescriptionRenewalRequestAttributes: Array<CreatePrescriptionRenewalRequestAttributes>;
  patientId: Scalars['ID']['input'];
  patientPharmacyId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreatePrescriptionRenewalRequests. */
export type CreatePrescriptionRenewalRequestsPayload = {
  __typename?: 'CreatePrescriptionRenewalRequestsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** List of patient medications for which the renewal request was created. */
  createdRenewalsForPatientMedications?: Maybe<Array<PatientMedication>>;
  errors?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  /** Full list of a patient's medications. */
  patient_medications?: Maybe<Array<PatientMedication>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateProblemAttachment */
export type CreateProblemAttachmentInput = {
  attachableId: Scalars['ID']['input'];
  attachableType: ProblemAttachmentAttachmentEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  problemId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateProblemAttachment. */
export type CreateProblemAttachmentPayload = {
  __typename?: 'CreateProblemAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateProcedureInteractionIndication */
export type CreateProcedureInteractionIndicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  problemCodeExtensionId?: InputMaybe<Scalars['ID']['input']>;
  problemCodeLocationId?: InputMaybe<Scalars['ID']['input']>;
  problemTypeId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateProcedureInteractionIndication. */
export type CreateProcedureInteractionIndicationPayload = {
  __typename?: 'CreateProcedureInteractionIndicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  indication?: Maybe<Indication>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateProcedureInteraction */
export type CreateProcedureInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  problemId?: InputMaybe<Scalars['ID']['input']>;
  procedureSuggestionId?: InputMaybe<Scalars['ID']['input']>;
  procedureTypeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of CreateProcedureInteraction. */
export type CreateProcedureInteractionPayload = {
  __typename?: 'CreateProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateProgramEnrollmentCareGiver */
export type CreateProgramEnrollmentCareGiverInput = {
  careGiverIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateProgramEnrollmentCareGiver. */
export type CreateProgramEnrollmentCareGiverPayload = {
  __typename?: 'CreateProgramEnrollmentCareGiverPayload';
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateScheduleTemplate */
export type CreateScheduleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateScheduleTemplate. */
export type CreateScheduleTemplatePayload = {
  __typename?: 'CreateScheduleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  scheduleTemplate?: Maybe<ScheduleTemplate>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateScheduleTemplateVariant */
export type CreateScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  variant: CreateVariantInput;
};

/** Autogenerated return type of CreateScheduleTemplateVariant. */
export type CreateScheduleTemplateVariantPayload = {
  __typename?: 'CreateScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
  variant: ScheduleTemplateVariant;
};

/** Autogenerated input type of CreateSmartDraft */
export type CreateSmartDraftInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  replyInstructions: Scalars['String']['input'];
  threadId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSmartDraft. */
export type CreateSmartDraftPayload = {
  __typename?: 'CreateSmartDraftPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  smartDraft?: Maybe<SmartDraft>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId: Scalars['String']['input'];
};

/** Autogenerated return type of CreateStripeSetupIntent. */
export type CreateStripeSetupIntentPayload = {
  __typename?: 'CreateStripeSetupIntentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  setupIntentId?: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateSummary */
export type CreateSummaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  noteTypeId?: InputMaybe<Scalars['ID']['input']>;
  noteTypeSystemName?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateSummary. */
export type CreateSummaryPayload = {
  __typename?: 'CreateSummaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
  summary?: Maybe<Summary>;
};

/** Autogenerated input type of CreateSurveyOrderOutreach */
export type CreateSurveyOrderOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  patientIds: Array<Scalars['ID']['input']>;
  surveyId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateSurveyOrderOutreach. */
export type CreateSurveyOrderOutreachPayload = {
  __typename?: 'CreateSurveyOrderOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** return object */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  taskTemplateId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateTask. */
export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of CreateToken */
export type CreateTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  grantType?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Autogenerated return type of CreateToken. */
export type CreateTokenPayload = {
  __typename?: 'CreateTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateUserEngagementMessageOutreach */
export type CreateUserEngagementMessageOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** An internal note describing why this outreach is being run. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The Patients to deliver the message to. */
  patientIds: Array<Scalars['ID']['input']>;
  /** The User Engagement Message ID that should be used. */
  userEngagementMessageId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateUserEngagementMessageOutreach. */
export type CreateUserEngagementMessageOutreachPayload = {
  __typename?: 'CreateUserEngagementMessageOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** The successfully created outreach manager batch. */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean']['output'];
};

export type CreateVariantInput = {
  name: Scalars['String']['input'];
  scheduleTemplateId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateVideoCall */
export type CreateVideoCallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateVideoCall. */
export type CreateVideoCallPayload = {
  __typename?: 'CreateVideoCallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  meetingId?: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateVisitFollowUpOrderOutreach */
export type CreateVisitFollowUpOrderOutreachInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['DateTime']['input'];
  note: Scalars['String']['input'];
  outreachManagerAppointmentTypeGroupId: Scalars['ID']['input'];
  patientIds: Array<Scalars['ID']['input']>;
  problemTypeId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateVisitFollowUpOrderOutreach. */
export type CreateVisitFollowUpOrderOutreachPayload = {
  __typename?: 'CreateVisitFollowUpOrderOutreachPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** When successful, this is the Outreach Manager Batch. */
  outreachManagerBatch?: Maybe<OutreachManagerBatch>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of CreateVoluntaryAlignmentOffer */
export type CreateVoluntaryAlignmentOfferInput = {
  alignmentDocument?: InputMaybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  followUpAt?: InputMaybe<Scalars['DateTime']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offerMethod?: InputMaybe<Scalars['String']['input']>;
  offeredAt?: InputMaybe<Scalars['DateTime']['input']>;
  offeredById?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  signedAt?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateVoluntaryAlignmentOffer. */
export type CreateVoluntaryAlignmentOfferPayload = {
  __typename?: 'CreateVoluntaryAlignmentOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

export type CustomNetwork = {
  __typename?: 'CustomNetwork';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isRestricted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
};

/** Autogenerated input type of DebugServiceTicketTransmission */
export type DebugServiceTicketTransmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DebugServiceTicketTransmission. */
export type DebugServiceTicketTransmissionPayload = {
  __typename?: 'DebugServiceTicketTransmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteAdmissionAttachment */
export type DeleteAdmissionAttachmentInput = {
  admissionAttachmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteAdmissionAttachment. */
export type DeleteAdmissionAttachmentPayload = {
  __typename?: 'DeleteAdmissionAttachmentPayload';
  admission?: Maybe<Admission>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteAdmission */
export type DeleteAdmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteAdmission. */
export type DeleteAdmissionPayload = {
  __typename?: 'DeleteAdmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteBillingDistrictInsuranceNetwork */
export type DeleteBillingDistrictInsuranceNetworkInput = {
  billingDistrictId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceNetworkId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteBillingDistrictInsuranceNetwork. */
export type DeleteBillingDistrictInsuranceNetworkPayload = {
  __typename?: 'DeleteBillingDistrictInsuranceNetworkPayload';
  billingDistrict?: Maybe<BillingDistrict>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteDocumentFromProcedure */
export type DeleteDocumentFromProcedureInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  procedureOriginId: Scalars['ID']['input'];
  procedureOriginType: ProcedureOriginType;
};

/** Autogenerated return type of DeleteDocumentFromProcedure. */
export type DeleteDocumentFromProcedurePayload = {
  __typename?: 'DeleteDocumentFromProcedurePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureOrigin?: Maybe<ProcedureOrigin>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteInsuranceCard */
export type DeleteInsuranceCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInsuranceCard. */
export type DeleteInsuranceCardPayload = {
  __typename?: 'DeleteInsuranceCardPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insurance?: Maybe<Insurance>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteInsuranceNetworksProviders */
export type DeleteInsuranceNetworksProvidersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceNetworkId: Scalars['ID']['input'];
  providerId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteInsuranceNetworksProviders. */
export type DeleteInsuranceNetworksProvidersPayload = {
  __typename?: 'DeleteInsuranceNetworksProvidersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insuranceNetworksProviders?: Maybe<InsuranceNetworksProviders>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePatientAddress */
export type DeletePatientAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** id of address to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePatientAddress. */
export type DeletePatientAddressPayload = {
  __typename?: 'DeletePatientAddressPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePatientEmail */
export type DeletePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** id of email to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePatientEmail. */
export type DeletePatientEmailPayload = {
  __typename?: 'DeletePatientEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePatientEmergencyContact */
export type DeletePatientEmergencyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** id of emergency contact to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePatientEmergencyContact. */
export type DeletePatientEmergencyContactPayload = {
  __typename?: 'DeletePatientEmergencyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeletePatientPhoneNumber */
export type DeletePatientPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** id of phone number to delete */
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePatientPhoneNumber. */
export type DeletePatientPhoneNumberPayload = {
  __typename?: 'DeletePatientPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteProblemAttachment */
export type DeleteProblemAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  problemAttachmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProblemAttachment. */
export type DeleteProblemAttachmentPayload = {
  __typename?: 'DeleteProblemAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteProcedureInteractionIndication */
export type DeleteProcedureInteractionIndicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  procedureInteractionId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProcedureInteractionIndication. */
export type DeleteProcedureInteractionIndicationPayload = {
  __typename?: 'DeleteProcedureInteractionIndicationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteProcedureInteraction */
export type DeleteProcedureInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProcedureInteraction. */
export type DeleteProcedureInteractionPayload = {
  __typename?: 'DeleteProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteProgramEnrollmentCareGiver */
export type DeleteProgramEnrollmentCareGiverInput = {
  careGiverId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteProgramEnrollmentCareGiver. */
export type DeleteProgramEnrollmentCareGiverPayload = {
  __typename?: 'DeleteProgramEnrollmentCareGiverPayload';
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteScheduleTemplate */
export type DeleteScheduleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteScheduleTemplate. */
export type DeleteScheduleTemplatePayload = {
  __typename?: 'DeleteScheduleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteScheduleTemplateVariant */
export type DeleteScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteScheduleTemplateVariant. */
export type DeleteScheduleTemplateVariantPayload = {
  __typename?: 'DeleteScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteServiceTicketItem */
export type DeleteServiceTicketItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteServiceTicketItem. */
export type DeleteServiceTicketItemPayload = {
  __typename?: 'DeleteServiceTicketItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DeleteVoluntaryAlignmentOffer */
export type DeleteVoluntaryAlignmentOfferInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteVoluntaryAlignmentOffer. */
export type DeleteVoluntaryAlignmentOfferPayload = {
  __typename?: 'DeleteVoluntaryAlignmentOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Result Set for the differences between 1life's patient demographics and the external demographics */
export type DemographicDifferencesResult = {
  __typename?: 'DemographicDifferencesResult';
  differences: Array<Scalars['String']['output']>;
  externalDemographics: EligibilityDemographics;
  onelifeDemographics: EligibilityDemographics;
};

/** Autogenerated input type of DetachStripePaymentMethod */
export type DetachStripePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of DetachStripePaymentMethod. */
export type DetachStripePaymentMethodPayload = {
  __typename?: 'DetachStripePaymentMethodPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DiagnosticGroup = {
  __typename?: 'DiagnosticGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DirectSignupError = {
  __typename?: 'DirectSignupError';
  directSignupToken?: Maybe<DirectSignupToken>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DirectSignupToken = {
  __typename?: 'DirectSignupToken';
  email?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  signupComplete: Scalars['Boolean']['output'];
};

export type DiscountType = {
  __typename?: 'DiscountType';
  /** A fixed discount amount in dollars */
  amount?: Maybe<Scalars['Float']['output']>;
  /** The ID of a B2bPlan this discount code applies to */
  b2bPlanId?: Maybe<Scalars['ID']['output']>;
  /** The discount code description */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The type of discount */
  kind?: Maybe<Scalars['String']['output']>;
  /** The discount code */
  name: Scalars['String']['output'];
  /** A percentage-based discount amount */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** The referral code associated with this discount type, if it is a referral */
  referralCode?: Maybe<Referral>;
  /** Whether this discount code can be used for new signups */
  validForNew: Scalars['Boolean']['output'];
  /** Whether this discount code can be used for renewals */
  validForRenewal: Scalars['Boolean']['output'];
  /** Whether this discount code can be stacked with other codes */
  validWithOtherDiscounts: Scalars['Boolean']['output'];
};

/** Autogenerated input type of DismissMlBillingProcedureSuggestion */
export type DismissMlBillingProcedureSuggestionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DismissMlBillingProcedureSuggestion. */
export type DismissMlBillingProcedureSuggestionPayload = {
  __typename?: 'DismissMlBillingProcedureSuggestionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureSuggestion?: Maybe<MlBillingProcedureSuggestion>;
  success: Scalars['Boolean']['output'];
};

export type Document = AdmissionAttachableInterface & ProblemAttachableInterface & {
  __typename?: 'Document';
  availableStatusEvents: Array<DocumentStatusEvent>;
  awsBucket: Scalars['String']['output'];
  awsKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  ext: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  lockedAt?: Maybe<Scalars['DateTime']['output']>;
  lockedBy?: Maybe<InternalUser>;
  mimeType: Scalars['String']['output'];
  note?: Maybe<Note>;
  originalFilename: Scalars['String']['output'];
  parentType?: Maybe<Scalars['String']['output']>;
  /** If the document has been filed to a patient */
  patient?: Maybe<Patient>;
  pdfBucket?: Maybe<Scalars['String']['output']>;
  pdfKey?: Maybe<Scalars['String']['output']>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  serviceArea?: Maybe<ServiceArea>;
  source?: Maybe<Scalars['String']['output']>;
  status: DocumentStatus;
  summary?: Maybe<DocumentSummary>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Document>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int']['output'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

export enum DocumentSort {
  /** Sorts documents by created_at date in ascending order. */
  CreatedAtAsc = 'CreatedAtAsc',
  /** Sorts documents by created_at date in descending order. */
  CreatedAtDesc = 'CreatedAtDesc'
}

export type DocumentSource = {
  __typename?: 'DocumentSource';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum DocumentStatus {
  Deleted = 'deleted',
  Filed = 'filed',
  Flagged = 'flagged',
  Unfiled = 'unfiled'
}

export enum DocumentStatusEvent {
  Delete = 'delete',
  File = 'file',
  Flag = 'flag',
  MarkSent = 'mark_sent',
  Undelete = 'undelete',
  Unfile = 'unfile'
}

export type DocumentSummary = {
  __typename?: 'DocumentSummary';
  content: DocumentSummaryData;
  createdAt: Scalars['DateTime']['output'];
  feedbacks?: Maybe<Array<DocumentSummaryFeedback>>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DocumentSummaryActor = {
  __typename?: 'DocumentSummaryActor';
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type: DocumentSummaryActorType;
};

export type DocumentSummaryActorMetadata = {
  __typename?: 'DocumentSummaryActorMetadata';
  clientAppName: DocumentSummaryClientAppName;
  url?: Maybe<Scalars['String']['output']>;
  workflow?: Maybe<Scalars['String']['output']>;
};

export type DocumentSummaryActorMetadataInput = {
  clientAppName: DocumentSummaryClientAppName;
  url?: InputMaybe<Scalars['String']['input']>;
  workflow?: InputMaybe<Scalars['String']['input']>;
};

export enum DocumentSummaryActorType {
  System = 'system',
  User = 'user'
}

export enum DocumentSummaryClientAppName {
  PatientChart = 'patientChart',
  ThirdPartyDocumentBrowser = 'thirdPartyDocumentBrowser'
}

export type DocumentSummaryData = {
  __typename?: 'DocumentSummaryData';
  detailedSummary?: Maybe<Scalars['String']['output']>;
};

export enum DocumentSummaryDataFieldName {
  DetailedSummary = 'detailedSummary'
}

export type DocumentSummaryFeedback = {
  __typename?: 'DocumentSummaryFeedback';
  actor: DocumentSummaryActor;
  createdAt: Scalars['DateTime']['output'];
  displayedSummaryDataFields: Array<DocumentSummaryDataFieldName>;
  documentSummaryId: Scalars['ID']['output'];
  feedbackFields?: Maybe<Array<DocumentSummaryFeedbackField>>;
  id: Scalars['ID']['output'];
  metadata: DocumentSummaryActorMetadata;
  updatedAt: Scalars['DateTime']['output'];
};

export type DocumentSummaryFeedbackField = {
  __typename?: 'DocumentSummaryFeedbackField';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  options?: Maybe<Array<DocumentSummaryFeedbackOption>>;
  submitted?: Maybe<Array<DocumentSummaryFeedbackOption>>;
};

export type DocumentSummaryFeedbackFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  options: Array<DocumentSummaryFeedbackOptionInput>;
  submitted: Array<DocumentSummaryFeedbackOptionInput>;
};

export type DocumentSummaryFeedbackOption = {
  __typename?: 'DocumentSummaryFeedbackOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DocumentSummaryFeedbackOptionInput = {
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type DocusignEnvelope = {
  __typename?: 'DocusignEnvelope';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<InternalUser>;
  docusignEnvelopeId?: Maybe<Scalars['ID']['output']>;
  docusignTemplateId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  patient: Patient;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** Contains the elements/parameters for the form and metric dose threshold values. */
export type DoseThresholdRanges = {
  __typename?: 'DoseThresholdRanges';
  /** Text description of the low, high, and maximum daily dose values in metric units of measure. */
  metric?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for the MetricHighValue element/parameter. */
  metricHighUnit?: Maybe<Scalars['String']['output']>;
  /** Numeric value for the high daily dose threshold in metric units of measure */
  metricHighValue?: Maybe<Scalars['Float']['output']>;
  /** Low daily dose threshold value in metric units of measure. */
  metricLowUnit?: Maybe<Scalars['String']['output']>;
  /** Low daily dose threshold value in metric units of measure. */
  metricLowValue?: Maybe<Scalars['Float']['output']>;
  /** Unit of measure for the MetricMaxValue element/parameter. */
  metricMaxUnit?: Maybe<Scalars['String']['output']>;
  /** Maximum daily dose threshold value in metric units of measure. */
  metricMaxValue?: Maybe<Scalars['Float']['output']>;
  /** Unit of measure for the MetricNTEValue element/parameter. */
  metricNteUnit?: Maybe<Scalars['String']['output']>;
  /** Contains the not to exceed single dose threshold value in metric units of measure. */
  metricNteValue?: Maybe<Scalars['Float']['output']>;
  /** Text description of any required patient parameters for dosing threshold */
  patientParameters?: Maybe<Scalars['String']['output']>;
};

/**
 * Contains the screening results and message for dosing screening against the low
 * daily dose, high daily dose, and maximum daily dose values.
 */
export type DoseThresholdResult = {
  __typename?: 'DoseThresholdResult';
  /** Indicates if the screening result has a message.. */
  hasMessage: Scalars['Boolean']['output'];
  /**
   * Contains a numeric value for the variation between the calculated daily dose
   * amount and the low, high, and maximum daily dose values if the calculated
   * daily dose amount does not fall within the dose range.
   */
  percentOutOfBounds?: Maybe<Scalars['String']['output']>;
  /** Contains the alert screening message for display to the end user. */
  screenMessage?: Maybe<Scalars['String']['output']>;
  /** Contains the status. */
  status: DoseThresholdResultStatusEnum;
};

export enum DoseThresholdResultStatusEnum {
  BelowLow = 'BELOW_LOW',
  ExceedsDailyNte = 'EXCEEDS_DAILY_NTE',
  ExceedsHigh = 'EXCEEDS_HIGH',
  ExceedsMax = 'EXCEEDS_MAX',
  NotApplicable = 'NOT_APPLICABLE',
  UnableToCheckClinicianReviewRequired = 'UNABLE_TO_CHECK_CLINICIAN_REVIEW_REQUIRED',
  UnableToCheckExcluded = 'UNABLE_TO_CHECK_EXCLUDED',
  UnableToCheckInvalidOrder = 'UNABLE_TO_CHECK_INVALID_ORDER',
  UnableToCheckNonComparableDataTypes = 'UNABLE_TO_CHECK_NON_COMPARABLE_DATA_TYPES',
  UnableToCheckNoDataAvailable = 'UNABLE_TO_CHECK_NO_DATA_AVAILABLE',
  UnableToCheckNoDataProvided = 'UNABLE_TO_CHECK_NO_DATA_PROVIDED',
  WithinClinical = 'WITHIN_CLINICAL',
  WithinClinical_24HourAdministration = 'WITHIN_CLINICAL_24_HOUR_ADMINISTRATION',
  WithinClinicalIndividualDose = 'WITHIN_CLINICAL_INDIVIDUAL_DOSE'
}

/**
 * Contains specific notifications, warnings, or exclusion information about the
 * dosing protocol with regard to CreatinineClearance,
 * CreatinineClearanceLevelUnits, and HepaticLevel elements/parameters.
 */
export type DosingMessage = {
  __typename?: 'DosingMessage';
  /** Notification, warning, or exclusion message. */
  message?: Maybe<Scalars['String']['output']>;
  /** Description of the dosing message. */
  type?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DuplicateScheduleTemplateVariant */
export type DuplicateScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DuplicateScheduleTemplateVariant. */
export type DuplicateScheduleTemplateVariantPayload = {
  __typename?: 'DuplicateScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
  variant?: Maybe<ScheduleTemplateVariant>;
};

export type Education = {
  __typename?: 'Education';
  text?: Maybe<Scalars['String']['output']>;
};

export type ElectronicHealthInformationExport = {
  __typename?: 'ElectronicHealthInformationExport';
  createdAt: Scalars['DateTime']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  /** UUID */
  id: Scalars['ID']['output'];
  patient: Patient;
  recordType: ElectronicHealthInformationExportFormat;
  requestedAt: Scalars['DateTime']['output'];
  state: ElectronicHealthInformationExportState;
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for ElectronicHealthInformationExport. */
export type ElectronicHealthInformationExportConnection = {
  __typename?: 'ElectronicHealthInformationExportConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ElectronicHealthInformationExportEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ElectronicHealthInformationExport>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int']['output'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ElectronicHealthInformationExportEdge = {
  __typename?: 'ElectronicHealthInformationExportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ElectronicHealthInformationExport>;
};

export enum ElectronicHealthInformationExportFormat {
  Pdf = 'PDF',
  Zip = 'ZIP'
}

export enum ElectronicHealthInformationExportState {
  Available = 'available',
  Failed = 'failed',
  InProgress = 'in_progress',
  Requested = 'requested',
  Retrying = 'retrying'
}

export type EligibilityDemographics = {
  __typename?: 'EligibilityDemographics';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type EligibilityRule = {
  __typename?: 'EligibilityRule';
  displayString?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type Email = {
  __typename?: 'Email';
  displayEmail: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPreferred: Scalars['Boolean']['output'];
  kind: EmailKind;
  updatedAt: Scalars['DateTime']['output'];
};

export type EmailAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  kind?: InputMaybe<EmailKind>;
};

export enum EmailKind {
  B2bEmployeeWork = 'b2b_employee_work',
  DirectMessage = 'direct_message',
  Other = 'other',
  Personal = 'personal',
  Shared = 'shared',
  Work = 'work'
}

export enum EmailVerificationStatus {
  LockedOut = 'locked_out',
  Unverified = 'unverified',
  Verified = 'verified'
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  address?: Maybe<Address>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPreferred: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<PatientPhoneNumber>;
  relationship: Scalars['String']['output'];
};

export type EmergencyContactInputObject = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  addressAttributes?: InputMaybe<PatientAddressInputObject>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumberAttributes?: InputMaybe<PatientPhoneNumberInputObject>;
  relationship?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of EndAmazonClinicVisit */
export type EndAmazonClinicVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason: AmazonClinicEndReason;
  visitId: Scalars['ID']['input'];
};

/** Autogenerated return type of EndAmazonClinicVisit. */
export type EndAmazonClinicVisitPayload = {
  __typename?: 'EndAmazonClinicVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EndVideoCall */
export type EndVideoCallInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  meetingId: Scalars['ID']['input'];
};

/** Autogenerated return type of EndVideoCall. */
export type EndVideoCallPayload = {
  __typename?: 'EndVideoCallPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** A generic error object */
export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type ErrorResponse = {
  __typename?: 'ErrorResponse';
  error: Scalars['String']['output'];
};

export enum ErrorTypes {
  NonRecoverable = 'NON_RECOVERABLE',
  RetryableImmediate = 'RETRYABLE_IMMEDIATE'
}

export type Ethnicity = {
  __typename?: 'Ethnicity';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type Expertise = {
  __typename?: 'Expertise';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of ExportOrphanedOrders */
export type ExportOrphanedOrdersInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dosFilter?: InputMaybe<Scalars['String']['input']>;
  marketFilter?: InputMaybe<Scalars['String']['input']>;
  officeFilter?: InputMaybe<Scalars['String']['input']>;
  patientIdFilter?: InputMaybe<Scalars['String']['input']>;
  procedureTypeFilter?: InputMaybe<Scalars['String']['input']>;
  providerIdFilter?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ExportOrphanedOrders. */
export type ExportOrphanedOrdersPayload = {
  __typename?: 'ExportOrphanedOrdersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ExternalDocument = {
  __typename?: 'ExternalDocument';
  dateOfServiceEnd?: Maybe<Scalars['DateTime']['output']>;
  dateOfServiceStart?: Maybe<Scalars['DateTime']['output']>;
  directMessageId?: Maybe<Scalars['ID']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  documentSource?: Maybe<DocumentSource>;
  fromAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  institution?: Maybe<Institution>;
  noteId?: Maybe<Scalars['ID']['output']>;
  patientId: Scalars['ID']['output'];
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  type: ExternalDocumentTypeEnum;
};

export enum ExternalDocumentTypeEnum {
  InboundDirectMessageItem = 'inbound_direct_message_item',
  InboundExternalDocument = 'inbound_external_document'
}

export type FileDocumentInput = {
  assigneeIdent: Scalars['ID']['input'];
  filingTemplateId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  priority?: InputMaybe<Priority>;
};

export type FilingTemplate = {
  __typename?: 'FilingTemplate';
  defaultAssigneeIdent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  taskTemplate?: Maybe<TaskTemplate>;
};

export type Firebase = {
  __typename?: 'Firebase';
  namespace: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type FloatMeasurementValidationRule = MeasurementValidationRule & {
  __typename?: 'FloatMeasurementValidationRule';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  valueType: Scalars['String']['output'];
};

/** Autogenerated input type of ForceSignSummary */
export type ForceSignSummaryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  summaryId: Scalars['ID']['input'];
};

/** Autogenerated return type of ForceSignSummary. */
export type ForceSignSummaryPayload = {
  __typename?: 'ForceSignSummaryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** A connection object for the set of formulay alternatives */
export type FormularyAlternatives = {
  __typename?: 'FormularyAlternatives';
  /** Pagination token. Will be present if there are more results */
  pageCursor?: Maybe<Scalars['String']['output']>;
  results: Array<MedicationFormulary>;
  /** The total amount of alternatives */
  totalResults: Scalars['Int']['output'];
};

export enum FreeTextQuestionId {
  UserFreetextFeedback = 'USER_FREETEXT_FEEDBACK'
}

export type FreetextResponseInput = {
  /** Must be "USER_FREETEXT_FEEDBACK" */
  questionId: FreeTextQuestionId;
  /** The text of the question */
  questionText: Scalars['String']['input'];
  /** Typed response from the user for this question */
  response: Scalars['String']['input'];
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  NonBinary = 'non_binary',
  Unknown = 'unknown'
}

export type GenderIdentity = {
  __typename?: 'GenderIdentity';
  description?: Maybe<Scalars['String']['output']>;
  snomedCode?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenerateAccessKey */
export type GenerateAccessKeyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of GenerateAccessKey. */
export type GenerateAccessKeyPayload = {
  __typename?: 'GenerateAccessKeyPayload';
  claimUrl?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type GeneratedResponsePayload = {
  __typename?: 'GeneratedResponsePayload';
  /** Payload text content; will be null if the content has not yet been generated. */
  content?: Maybe<Scalars['String']['output']>;
  /** If true, this dialogTurn will need to be provided to the toucanGenerate mutation to populate the content field. */
  requiresGeneratedInput: Scalars['Boolean']['output'];
  /** must be "generatedResponse". */
  type: Scalars['String']['output'];
};

export type GeneratedResponsePayloadInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  requiresGeneratedInput: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
};

/** Strongly-typed lat/long input. */
export type Geolocation = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type GuestPlan = Plan & {
  __typename?: 'GuestPlan';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Hearabout = {
  __typename?: 'Hearabout';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** The connection type for Measurement. */
export type HeartRateMeasurementConnection = {
  __typename?: 'HeartRateMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  validationRules?: Maybe<IntegerMeasurementValidationRule>;
};

/** The connection type for Measurement. */
export type HeightMeasurementConnection = {
  __typename?: 'HeightMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HelpFlow = {
  __typename?: 'HelpFlow';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type HelpRequest = {
  __typename?: 'HelpRequest';
  createdAt: Scalars['DateTime']['output'];
  createdBy: InternalUser;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  linkedNoteId: Scalars['Int']['output'];
  patientId: Scalars['ID']['output'];
  slackChannel: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type HelpRequestAttributes = {
  actionUrl?: InputMaybe<Scalars['String']['input']>;
  callbackName?: InputMaybe<Scalars['String']['input']>;
  callbackNumber?: InputMaybe<Scalars['String']['input']>;
  callerOnTheLine?: InputMaybe<Scalars['Boolean']['input']>;
  currentUrl?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  licensingBodyId?: InputMaybe<Scalars['ID']['input']>;
  linkedNoteId?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  triageNoteId?: InputMaybe<Scalars['Int']['input']>;
  urgency?: InputMaybe<Scalars['String']['input']>;
};

export type HistoryVaccine = {
  __typename?: 'HistoryVaccine';
  administeredBy?: Maybe<InternalUser>;
  givenAt?: Maybe<Scalars['DateTime']['output']>;
  givenOn?: Maybe<Scalars['DateTime']['output']>;
  givenOnDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  vaccine?: Maybe<Vaccine>;
  vaccineOrder?: Maybe<VaccineOrder>;
};

export type HrcmEnrollment = {
  /** ID for internal user RN who will be assigned to the care team of the HRCM program */
  assignedRn: Scalars['ID']['input'];
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InboundFhirItem = {
  __typename?: 'InboundFhirItem';
  cdpIdentifier?: Maybe<Scalars['ID']['output']>;
  documentSource?: Maybe<Scalars['String']['output']>;
  fhirBundle?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  sourceType?: Maybe<Scalars['String']['output']>;
};

export type Indication = {
  __typename?: 'Indication';
  clinicalDescription?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  problemCode?: Maybe<ProblemCode>;
  problemCodeExtension?: Maybe<ProblemCodeExtension>;
  problemCodeLocation?: Maybe<ProblemCodeLocation>;
  problemType?: Maybe<ProblemType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Insurance = {
  __typename?: 'Insurance';
  /** back card image */
  cardImageS3KeyBack?: Maybe<Scalars['String']['output']>;
  /** front card image */
  cardImageS3KeyFront?: Maybe<Scalars['String']['output']>;
  cardUrlBack?: Maybe<Scalars['String']['output']>;
  cardUrlFront?: Maybe<Scalars['String']['output']>;
  /** coinsurance */
  coinsurance?: Maybe<Scalars['Int']['output']>;
  /** extra comments on this insurance */
  comment?: Maybe<Scalars['String']['output']>;
  /** copay */
  copay?: Maybe<Scalars['Int']['output']>;
  /** if the insurance was created by ml ocr */
  createdByMlOcr: Scalars['Boolean']['output'];
  editedBy?: Maybe<InternalUser>;
  /** effective at date */
  effectiveAt?: Maybe<Scalars['DateTime']['output']>;
  eligibilityRules?: Maybe<Array<EligibilityRule>>;
  /** The insurance coverage data in FHIR */
  fhir?: Maybe<Scalars['JSON']['output']>;
  /** group number */
  groupno?: Maybe<Scalars['String']['output']>;
  /** holder's address line 1 */
  holderAddress1?: Maybe<Scalars['String']['output']>;
  /** holder's address line 2 */
  holderAddress2?: Maybe<Scalars['String']['output']>;
  /** holder's city */
  holderCity?: Maybe<Scalars['String']['output']>;
  /** holder's date of birth */
  holderDob?: Maybe<Scalars['String']['output']>;
  /** holder's first name */
  holderFirstName?: Maybe<Scalars['String']['output']>;
  /** holder's last name */
  holderLastName?: Maybe<Scalars['String']['output']>;
  /** holder's phone number */
  holderPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** holder's state */
  holderState?: Maybe<Scalars['String']['output']>;
  /** holder's zip code */
  holderZipCode?: Maybe<Scalars['String']['output']>;
  homeOfficeNetworkStatus?: Maybe<Scalars['String']['output']>;
  /** insurance id */
  id: Scalars['ID']['output'];
  inNetworkStatus?: Maybe<Scalars['String']['output']>;
  insuranceCarrier?: Maybe<InsuranceCarrier>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  /** insurance plan */
  insurancePlan?: Maybe<InsurancePlan>;
  insuranceRelationshipType: InsuranceRelationshipType;
  insuranceType?: Maybe<InsuranceType>;
  /** if the insurance is archived */
  isArchived: Scalars['Boolean']['output'];
  /** if insurance populated by waystar */
  isAutopopulatedByWaystar: Scalars['Boolean']['output'];
  isPendingReview: Scalars['Boolean']['output'];
  /** if the ml ocr needs human review */
  mlOcrNeedsHumanReview: Scalars['Boolean']['output'];
  /** insurance name */
  name?: Maybe<Scalars['String']['output']>;
  opmEligibilityStatus?: Maybe<OpmEligibilityStatus>;
  opmEligibilityUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  patient: Patient;
  planType?: Maybe<Scalars['String']['output']>;
  /** insurance position */
  position?: Maybe<Scalars['Int']['output']>;
  /** if the insurance is primary */
  primary: Scalars['Boolean']['output'];
  relatedPerson?: Maybe<RelatedPerson>;
  /** insurance subscriber */
  subscriberNumber?: Maybe<Scalars['String']['output']>;
  /** updated at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** insurance verification status */
  verificationStatus: Scalars['String']['output'];
  waystarEligibility?: Maybe<WaystarEligibilityStatus>;
};


export type InsuranceInNetworkStatusArgs = {
  officeId: Scalars['ID']['input'];
};

export type InsuranceCarrier = {
  __typename?: 'InsuranceCarrier';
  /** The insurance carrier data in FHIR */
  fhir: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  serviceAreas?: Maybe<Array<ServiceArea>>;
  uuid: Scalars['ID']['output'];
};

export type InsuranceNetwork = {
  __typename?: 'InsuranceNetwork';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InsuranceNetworksProviders = {
  __typename?: 'InsuranceNetworksProviders';
  id: Scalars['ID']['output'];
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  provider?: Maybe<Provider>;
};

export type InsurancePlan = {
  __typename?: 'InsurancePlan';
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inNetworkStatus?: Maybe<InsurancePlanInNetworkStatus>;
  insuranceCarrier?: Maybe<InsuranceCarrier>;
  insuranceType?: Maybe<InsuranceType>;
  name?: Maybe<Scalars['String']['output']>;
  ppoName?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};


export type InsurancePlanInNetworkStatusArgs = {
  billingDistrictId: Scalars['ID']['input'];
};

export type InsurancePlanInNetworkStatus = {
  __typename?: 'InsurancePlanInNetworkStatus';
  billingDistrict?: Maybe<BillingDistrict>;
  id: Scalars['ID']['output'];
  insurancePlan?: Maybe<InsurancePlan>;
  status?: Maybe<Scalars['String']['output']>;
};

/** The connection type for InsurancePlan. */
export type InsurancePlansConnection = {
  __typename?: 'InsurancePlansConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InsurancePlansEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<InsurancePlan>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** represents an edge in the connection for InsurancePlanSearch, a node is a singular order */
export type InsurancePlansEdge = {
  __typename?: 'InsurancePlansEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InsurancePlan>;
};

export type InsuranceRelationshipType = {
  __typename?: 'InsuranceRelationshipType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type InsuranceType = {
  __typename?: 'InsuranceType';
  id: Scalars['ID']['output'];
  isMedicare: Scalars['Boolean']['output'];
  isSelfPay: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  networkRequired: Scalars['Boolean']['output'];
};

export type IntegerMeasurementValidationRule = MeasurementValidationRule & {
  __typename?: 'IntegerMeasurementValidationRule';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  valueType: Scalars['String']['output'];
};

export type InternalBookingAttributes = {
  addToProviderPanel?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  seriesInterval?: InputMaybe<AppointmentSeriesInterval>;
  unregisteredPatientAttributes?: InputMaybe<UnregisteredPatientAttributes>;
};

export type InternalUser = AppointmentActor & Assignee & MessageRecipient & MessageSender & {
  __typename?: 'InternalUser';
  additionalServiceAreas?: Maybe<Array<ServiceArea>>;
  appointmentRecordings?: Maybe<AppointmentRecordingConnection>;
  canFilterTasksByLocation: Scalars['Boolean']['output'];
  canPaginateAndFilterTasks: Scalars['Boolean']['output'];
  dayTaskCount: Scalars['Int']['output'];
  defaultOffice?: Maybe<Office>;
  displayName: Scalars['String']['output'];
  /** @deprecated Please use the camelCase version. */
  display_name: Scalars['String']['output'];
  doNotShowSmartDraftLimitationsAgain: Scalars['Boolean']['output'];
  draftMessages: OriginatingObjectConnection;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  globalId: Scalars['String']['output'];
  homeOffice?: Maybe<Office>;
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  /** All of the user's roles that are not the primary roles (admin, provider, phleb, or clubhouse). */
  nonPreferredRoles: Array<RoleType>;
  normalizedId: Scalars['ID']['output'];
  panels: Array<Panel>;
  preferences: InternalUserPreferences;
  /** Can only be admin, provider, phleb, or clubhouse. Defaults to admin. */
  preferredRole: RoleType;
  prescribingCredentials?: Maybe<Array<Maybe<PrescribingCredential>>>;
  /**
   * Default locations are different among providers and Ops roles. Because of
   * licensing, we return LicensingBodyType for providers.
   *  We return ServiceAreaType for non-provider roles. Within Ops, mostly front
   * desk admins support specific service areas. So default locations for Ops
   * depends on whether user cares about specific geography and if front desk
   * admin. An empty list indicates user supports all locations (aka markets).
   */
  prioritizedTasksDefaultLocations: Array<PrioritizedTaskLocation>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  providerPronoun?: Maybe<ProviderPronoun>;
  roles: Array<RoleType>;
  serviceArea?: Maybe<ServiceArea>;
  settings?: Maybe<InternalUserSettings>;
  suffix?: Maybe<Scalars['String']['output']>;
  supportedAgeRange?: Maybe<SupportedAgeRange>;
  supportedB2bCompanies?: Maybe<Array<B2bCompany>>;
  synchronousTaskCounts: InternalUserSyncTaskCounts;
  weekTaskCount: Scalars['Int']['output'];
  workSchedule?: Maybe<WorkSchedule>;
};


export type InternalUserAppointmentRecordingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InternalUserDisplayNameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type InternalUserDisplay_NameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type InternalUserDraftMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for InternalUser. */
export type InternalUserConnection = {
  __typename?: 'InternalUserConnection';
  /** A list of edges. */
  edges: Array<InternalUserEdge>;
  /** A list of nodes. */
  nodes: Array<InternalUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InternalUserDisplayNameOptions = {
  lastInitial?: InputMaybe<Scalars['Boolean']['input']>;
  showSuffix?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An edge in a connection. */
export type InternalUserEdge = {
  __typename?: 'InternalUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InternalUser>;
};

export type InternalUserPreferences = {
  __typename?: 'InternalUserPreferences';
  id: Scalars['ID']['output'];
  licensingBodies?: Maybe<Array<LicensingBody>>;
  videoNotificationEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type InternalUserSettings = {
  __typename?: 'InternalUserSettings';
  combinedSubjectiveSection: Scalars['Boolean']['output'];
  defaultFields?: Maybe<Scalars['JSON']['output']>;
  displayAsNarrative: Scalars['Boolean']['output'];
};

export type InternalUserSettingsInput = {
  combinedSubjectiveSection?: InputMaybe<Scalars['Boolean']['input']>;
  defaultFields?: InputMaybe<Scalars['JSON']['input']>;
  displayAsNarrative?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Synchronous task counts to help VMTs reach performance goals. */
export type InternalUserSyncTaskCounts = {
  __typename?: 'InternalUserSyncTaskCounts';
  dayMiscClinicalsCount: Scalars['Int']['output'];
  dayTriageNotesCount: Scalars['Int']['output'];
  dayVideoEncountersCount: Scalars['Int']['output'];
  syncDayTaskCount: Scalars['Int']['output'];
  syncWeekTaskCount: Scalars['Int']['output'];
  weekMiscClinicalsCount: Scalars['Int']['output'];
  weekTriageNotesCount: Scalars['Int']['output'];
  weekVideoEncountersCount: Scalars['Int']['output'];
};

export type LabOrder = Order & {
  __typename?: 'LabOrder';
  accudrawResponse?: Maybe<TemplatedResponse>;
  /** whether or not the patient needs to book an appointment for this lab */
  appointmentNeeded: Scalars['Boolean']['output'];
  /** The date the lab was collected. */
  collectionDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * The date the lab was collected.
   * @deprecated Please use the camelCase version.
   */
  collection_date?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<Provider>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dueOn: Scalars['DateTime']['output'];
  fasting: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  measurementOrderTypeSourceId?: Maybe<Scalars['ID']['output']>;
  /** The provider who ordered the labs. */
  orderingProvider: Provider;
  /**
   * The provider who ordered the labs.
   * @deprecated Please use the camelCase version.
   */
  ordering_provider: Provider;
  patient: Patient;
  procedureTypes?: Maybe<Array<ProcedureType>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  /** names of all tests ordered */
  testsOrdered: Array<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Lab Order Outreach Type */
export type LabOrderOutreach = OutreachManagerBatch & {
  __typename?: 'LabOrderOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** Boolean indicating whether the lab order outreach should create a patient task */
  createPatientTask?: Maybe<Scalars['Boolean']['output']>;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']['output']>;
  /** Boolean indicating whether lab order should be taken fasting or not */
  fasting?: Maybe<Scalars['Boolean']['output']>;
  /** A unique identifier for this record. */
  id: Scalars['ID']['output'];
  /** Lab order types associated with a lab order outreach */
  labOrderTypes: Array<LabOrderType>;
  /** Measurement order type source for a lab order */
  measurementOrderTypeSource?: Maybe<MeasurementOrderTypeSource>;
  /** Boolean indicating whether the lab order's review task should be auto finished if results are normal */
  normalResultAutoFinishesTask: Scalars['Boolean']['output'];
  /** Note on a lab order outreach task */
  note?: Maybe<Scalars['String']['output']>;
  /** Include a note to phlebotomist on the lab order */
  noteToPhleb?: Maybe<Scalars['String']['output']>;
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  /** Problem type association with a lab order outreach */
  problemType?: Maybe<ProblemType>;
  s3Pointers: Array<S3Pointers>;
  /** Date of lab order outreach to be scheduled */
  scheduledDate: Scalars['DateTime']['output'];
  /** Boolean indicating whether the lab order should be stat or not */
  stat?: Maybe<Scalars['Boolean']['output']>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** Title of a lab order outreach */
  title?: Maybe<Scalars['String']['output']>;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime']['output'];
};


/** Lab Order Outreach Type */
export type LabOrderOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

export type LabOrderType = {
  __typename?: 'LabOrderType';
  id: Scalars['ID']['output'];
  layDescription: Scalars['String']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  shortName: Scalars['String']['output'];
};

/** The connection type for LabOrderType. */
export type LabOrderTypeConnection = {
  __typename?: 'LabOrderTypeConnection';
  /** A list of edges. */
  edges: Array<LabOrderTypeEdge>;
  /** A list of nodes. */
  nodes: Array<LabOrderType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LabOrderTypeEdge = {
  __typename?: 'LabOrderTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LabOrderType>;
};

export type LabOrderTypeResult = SearchResult & {
  __typename?: 'LabOrderTypeResult';
  clinicalAbbreviation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  layDescription: Scalars['String']['output'];
  node: LabOrderType;
};

export type Label = {
  __typename?: 'Label';
  displayName: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
};

/**
 * Language preferred by a patient, and also used to return a list of languages (AR model: Language).
 * Note that languages spoken by internal users is represented by Types::UserLanguageType.
 */
export type Language = {
  __typename?: 'Language';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
};

export type LegalDocument = {
  __typename?: 'LegalDocument';
  buttonHint?: Maybe<Scalars['String']['output']>;
  detailHint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The text of the document */
  text: Scalars['String']['output'];
  /** The document type */
  type: Scalars['String']['output'];
  /** The document version */
  version: Scalars['String']['output'];
};

export type LicensingBody = {
  __typename?: 'LicensingBody';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  /** @deprecated Please use the camelCase version. */
  short_name: Scalars['String']['output'];
};

export type Link = {
  __typename?: 'Link';
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type LoginWithAmazon = {
  __typename?: 'LoginWithAmazon';
  enabled: Scalars['Boolean']['output'];
  enabledAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ManualHieQueryAvailability {
  /** able to invoke a manual hie query */
  Eligible = 'ELIGIBLE',
  /** patient has not consented to manual hie queries */
  NotEligible = 'NOT_ELIGIBLE',
  /** manual hie query invoked too recently */
  NotReady = 'NOT_READY'
}

export enum ManualHieQueryStatus {
  /** transaction not found */
  DoesNotExist = 'DOES_NOT_EXIST',
  /** transaction failed during execution */
  Failed = 'FAILED',
  /** transaction is ongoing */
  InProgress = 'IN_PROGRESS',
  /** transaction completed successfully */
  Succeeded = 'SUCCEEDED'
}

/** Autogenerated input type of MarkPdmpReportAsViewed */
export type MarkPdmpReportAsViewedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correlationId: Scalars['ID']['input'];
};

/** Autogenerated return type of MarkPdmpReportAsViewed. */
export type MarkPdmpReportAsViewedPayload = {
  __typename?: 'MarkPdmpReportAsViewedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MarkReadyToSign */
export type MarkReadyToSignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rxCartId: Scalars['ID']['input'];
  rxSourceId: Scalars['ID']['input'];
};

/** Autogenerated return type of MarkReadyToSign. */
export type MarkReadyToSignPayload = {
  __typename?: 'MarkReadyToSignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of MarkRxCartReadyToSign */
export type MarkRxCartReadyToSignInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  rxCartId: Scalars['ID']['input'];
};

/** Autogenerated return type of MarkRxCartReadyToSign. */
export type MarkRxCartReadyToSignPayload = {
  __typename?: 'MarkRxCartReadyToSignPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type MassAppointmentCancellationRequest = MassAppointmentChangeRequest & {
  __typename?: 'MassAppointmentCancellationRequest';
  appointmentCancellationReason?: Maybe<AppointmentCancellationReason>;
  appointments: AppointmentConnection;
  createdAt: Scalars['DateTime']['output'];
  displayStatus: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  massAppointmentChangeRequestItems: MassAppointmentChangeRequestItemConnection;
};


export type MassAppointmentCancellationRequestAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MassAppointmentCancellationRequestMassAppointmentChangeRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MassAppointmentCancellationRequestItem = MassAppointmentChangeRequestItem & {
  __typename?: 'MassAppointmentCancellationRequestItem';
  appointment: Appointment;
  failureMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: MassAppointmentChangeRequestItemStatus;
};

export type MassAppointmentChangeRequest = {
  appointments: AppointmentConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  massAppointmentChangeRequestItems: MassAppointmentChangeRequestItemConnection;
};


export type MassAppointmentChangeRequestAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MassAppointmentChangeRequestMassAppointmentChangeRequestItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum MassAppointmentChangeRequestChangeType {
  Cancellation = 'cancellation'
}

/** The connection type for MassAppointmentChangeRequest. */
export type MassAppointmentChangeRequestConnection = {
  __typename?: 'MassAppointmentChangeRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MassAppointmentChangeRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MassAppointmentChangeRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MassAppointmentChangeRequestEdge = {
  __typename?: 'MassAppointmentChangeRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MassAppointmentChangeRequest>;
};

export type MassAppointmentChangeRequestItem = {
  appointment: Appointment;
  id: Scalars['ID']['output'];
  status: MassAppointmentChangeRequestItemStatus;
};

/** The connection type for MassAppointmentChangeRequestItem. */
export type MassAppointmentChangeRequestItemConnection = {
  __typename?: 'MassAppointmentChangeRequestItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MassAppointmentChangeRequestItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<MassAppointmentChangeRequestItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MassAppointmentChangeRequestItemEdge = {
  __typename?: 'MassAppointmentChangeRequestItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MassAppointmentChangeRequestItem>;
};

export enum MassAppointmentChangeRequestItemStatus {
  Failed = 'failed',
  InProgress = 'in_progress',
  Succeeded = 'succeeded'
}

export type Measurement = {
  __typename?: 'Measurement';
  collectedAt?: Maybe<Scalars['DateTime']['output']>;
  facilityNotes?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * The expected value for the result of the test. Either a value france for
   * numerical results or a string indicating an expected result (i.e. 'non
   * reactive' or 'not detected')
   */
  referenceRange?: Maybe<Scalars['String']['output']>;
  /** The type of measurement. */
  type?: Maybe<Scalars['String']['output']>;
  /** The id of the measurement type */
  typeId?: Maybe<Scalars['Int']['output']>;
  /** The unit of measurement, either in metric or imperial, whichever applies. */
  unit: Scalars['String']['output'];
  /** The result value of the test. Can be string or number. For example 'non reactive' or 78 */
  value: Scalars['String']['output'];
  /** The type of the result of the test. */
  valueType: Scalars['String']['output'];
};

/** An edge in a connection. */
export type MeasurementEdge = {
  __typename?: 'MeasurementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Measurement>;
};

export type MeasurementOrderTypeSource = {
  __typename?: 'MeasurementOrderTypeSource';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type MeasurementType = {
  __typename?: 'MeasurementType';
  defaultMeasurementUnit?: Maybe<MeasurementUnit>;
  defaultMetric: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  loincCode?: Maybe<Scalars['String']['output']>;
  measurementTypeValueOptions?: Maybe<Array<MeasurementTypeValueOption>>;
  measurementValidationRange?: Maybe<MeasurementValidationRange>;
  name?: Maybe<Scalars['String']['output']>;
};

/** The connection type for MeasurementType. */
export type MeasurementTypeConnection = {
  __typename?: 'MeasurementTypeConnection';
  /** A list of edges. */
  edges: Array<MeasurementTypeEdge>;
  /** A list of nodes. */
  nodes: Array<MeasurementType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MeasurementTypeEdge = {
  __typename?: 'MeasurementTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MeasurementType>;
};

export type MeasurementTypeValueOption = {
  __typename?: 'MeasurementTypeValueOption';
  displayOrder: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  sublabel?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MeasurementUnit = {
  __typename?: 'MeasurementUnit';
  id: Scalars['ID']['output'];
  imperial?: Maybe<Scalars['String']['output']>;
  metric: Scalars['String']['output'];
};

export type MeasurementValidationRange = {
  __typename?: 'MeasurementValidationRange';
  id: Scalars['ID']['output'];
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
};

/** Interface for common measurement validation rule fields */
export type MeasurementValidationRule = {
  valueType: Scalars['String']['output'];
};

export type MeasurementsValidationRules = {
  __typename?: 'MeasurementsValidationRules';
  diastolicPressure: IntegerMeasurementValidationRule;
  heartRate: IntegerMeasurementValidationRule;
  respiratoryRate: IntegerMeasurementValidationRule;
  systolicPressure: IntegerMeasurementValidationRule;
  temperature: FloatMeasurementValidationRule;
};

export type MedLevelDeliveryPromise = {
  __typename?: 'MedLevelDeliveryPromise';
  dateRange?: Maybe<PharmacyMedicationDeliveryPromiseDateRange>;
  promiseMarkdown?: Maybe<Scalars['String']['output']>;
};

/** Type for medicare advantage alignemnt object */
export type MedicareAdvantageAlignment = {
  __typename?: 'MedicareAdvantageAlignment';
  id: Scalars['ID']['output'];
};

export type MedicareBasicPlan = Plan & {
  __typename?: 'MedicareBasicPlan';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MedicationDispensable = {
  __typename?: 'MedicationDispensable';
  id: Scalars['ID']['output'];
  textDesc: Scalars['String']['output'];
};

/** An alternative medication object returned by the formulary benefits */
export type MedicationFormulary = {
  __typename?: 'MedicationFormulary';
  ageLimit?: Maybe<Array<AgeLimit>>;
  copayFactors?: Maybe<Array<CopayFactors>>;
  formularyStatus: BenefitsTierEnum;
  /** Raw tier number (if present) */
  formularyStatusNum?: Maybe<Scalars['String']['output']>;
  genderLimit?: Maybe<Array<Scalars['String']['output']>>;
  /** Is the medication generic. Null if unknown */
  generic?: Maybe<Scalars['Boolean']['output']>;
  medication: MedicationRoute;
  medicationDispensable: MedicationDispensable;
  /** The medication regimen which matches the dispensable if it exists */
  medicationRegimenId?: Maybe<Scalars['Int']['output']>;
  messages?: Maybe<Array<BenefitsMessage>>;
  /** Is the medication is over the counter. Null if unknown */
  otc?: Maybe<Scalars['Boolean']['output']>;
  priorAuthorizationRequired?: Maybe<Scalars['Boolean']['output']>;
  quantityLimit?: Maybe<Array<QuantityLimit>>;
  resourceLink?: Maybe<Array<ResourceLink>>;
  stepDrugs?: Maybe<Array<StepDrug>>;
  /**
   * PBM/payer requires trying a more cost-effective medication in a specific drug
   * class before using the original requested medication.
   */
  stepTherapy?: Maybe<Scalars['Boolean']['output']>;
  /** Is the medication a supply type. Null if unknown */
  supply?: Maybe<Scalars['Boolean']['output']>;
};

/** A medication from the medication catalog */
export type MedicationRoute = {
  __typename?: 'MedicationRoute';
  genericSynonym?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isGeneric: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MedicationUnitOfMeasure = {
  __typename?: 'MedicationUnitOfMeasure';
  abbr: Scalars['String']['output'];
  abbrPlural: Scalars['String']['output'];
  desc: Scalars['String']['output'];
  descPlural: Scalars['String']['output'];
  descPluralPref: Scalars['String']['output'];
  descPref: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type Membership = {
  __typename?: 'Membership';
  canAccessHintPatientPortal: Scalars['Boolean']['output'];
  canCancel: Scalars['Boolean']['output'];
  canReactivate: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Custom Network for B2B Company associated with B2B Plan */
  customNetworks?: Maybe<Array<CustomNetwork>>;
  dropOffClaimCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isB2b: Scalars['Boolean']['output'];
  /** patient plan's dpc status */
  isDpc: Scalars['Boolean']['output'];
  /** This returns the latest membership event */
  latestEvent?: Maybe<MembershipEvent>;
  membershipGroupSubscription?: Maybe<MembershipGroupSubscription>;
  patientId: Scalars['ID']['output'];
  patientStatus?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  planId?: Maybe<Scalars['ID']['output']>;
  planType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  trialUntil?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  validUntil?: Maybe<Scalars['DateTime']['output']>;
};

export type MembershipEvent = {
  __typename?: 'MembershipEvent';
  /** The name of the event type */
  eventTypeName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

export type MembershipGroupInvite = {
  __typename?: 'MembershipGroupInvite';
  claimCode: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  membershipGroupSubscriptionId: Scalars['ID']['output'];
  status: MembershipGroupInviteStatus;
};

export enum MembershipGroupInviteStatus {
  Claimed = 'claimed',
  Created = 'created',
  FailedToSend = 'failed_to_send',
  InProgress = 'in_progress',
  Rejected = 'rejected',
  Revoked = 'revoked',
  Sent = 'sent'
}

export type MembershipGroupSeat = {
  __typename?: 'MembershipGroupSeat';
  id: Scalars['ID']['output'];
  isMembershipManager: Scalars['Boolean']['output'];
  membershipGroupSubscription?: Maybe<MembershipGroupSubscription>;
  status: MembershipGroupSeatStatus;
  stoppedBy?: Maybe<MembershipGroupSeatStoppedBy>;
};

export enum MembershipGroupSeatStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Open = 'open',
  Pending = 'pending'
}

export enum MembershipGroupSeatStoppedBy {
  External = 'external',
  OmMember = 'om_member'
}

export type MembershipGroupSubscription = {
  __typename?: 'MembershipGroupSubscription';
  billingCycle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxNumSeats?: Maybe<Scalars['Int']['output']>;
  membershipGroupSeats?: Maybe<Array<MembershipGroupSeat>>;
  occupiedMembershipGroupSeats?: Maybe<Array<MembershipGroupSeat>>;
  redeemableMembershipGroupInvites?: Maybe<Array<MembershipGroupInvite>>;
};

export type Message = {
  __typename?: 'Message';
  code: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export enum MessageAnalyzerFeedback {
  Downvote = 'DOWNVOTE',
  Upvote = 'UPVOTE'
}

export type MessageRecipient = {
  displayName: Scalars['String']['output'];
  globalId: Scalars['String']['output'];
};

export type MessageSender = {
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** The connection type for MessageTemplate. */
export type MessageTemplateConnection = {
  __typename?: 'MessageTemplateConnection';
  /** A list of edges. */
  edges: Array<MessageTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<MessageTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MessageTemplateEdge = {
  __typename?: 'MessageTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<MessageTemplate>;
};

/** Form data from MTS removing MFA */
export type MfaDisableData = {
  /** Free text comments around why MFA is being disabled */
  comments?: InputMaybe<Scalars['String']['input']>;
};

/** Form data from MTS adding an MFA Exception */
export type MfaExceptionData = {
  /** Free text comments around why MFA is being disabled */
  comments?: InputMaybe<Scalars['String']['input']>;
  /** Whenever the exception expires */
  expirationDate: Scalars['String']['input'];
};

export enum MfaFactor {
  Sms = 'sms',
  Voice = 'voice'
}

export type MlBillingProcedureSuggestion = {
  __typename?: 'MlBillingProcedureSuggestion';
  cleanedInputText?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isDismissed: Scalars['Boolean']['output'];
  itemId?: Maybe<Scalars['Int']['output']>;
  itemType?: Maybe<Scalars['String']['output']>;
  predictiveWords?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Float']['output']>;
  problemHistory: ProblemHistory;
  procedureType: Array<Maybe<ProcedureType>>;
  summaryId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MlFeedbackProcedureSuggestion = {
  __typename?: 'MlFeedbackProcedureSuggestion';
  createdAt: Scalars['DateTime']['output'];
  feedbackCategory: Scalars['String']['output'];
  feedbackText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mlBillingProcedureSuggestionId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MroWorkflow = {
  __typename?: 'MroWorkflow';
  id: Scalars['ID']['output'];
  mroWorkflowTemplate: MroWorkflowTemplate;
  mroWorkflowTemplateId: Scalars['Int']['output'];
  patient: Patient;
  patientId: Scalars['Int']['output'];
  state: Scalars['String']['output'];
  task?: Maybe<Task>;
  text: Scalars['String']['output'];
  workflowType: MroWorkflowTypeEnum;
};

export type MroWorkflowSearchQuery = {
  patientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  workflowType?: InputMaybe<MroWorkflowTypeEnum>;
};

export type MroWorkflowTemplate = {
  __typename?: 'MroWorkflowTemplate';
  id: Scalars['ID']['output'];
  initialWorkflowType: Scalars['String']['output'];
  segmentationLabel: Scalars['String']['output'];
  taskKeyword: Scalars['String']['output'];
  taskTemplateId: Scalars['Int']['output'];
};

export enum MroWorkflowTypeEnum {
  Birdseye = 'birdseye',
  Cdi = 'cdi',
  ChartPrep = 'chart_prep'
}

export type MultiFactorAuthentication = {
  __typename?: 'MultiFactorAuthentication';
  /** The email address used as a backup factor. */
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  /**
   * If the patient is enrolled in multi-factor authentication, returns the phone
   * number used for MFA. Note that the phone number will be redacted, showing only
   * the last four digits. If the patient is not registered for MFA, then null is returned.
   * @deprecated The word enrolled is superfluous; use phoneNumber instead.
   */
  enrolledPhoneNumber?: Maybe<Scalars['String']['output']>;
  exceptionUntil?: Maybe<Scalars['String']['output']>;
  /**
   * If the patient is enrolled in multi-factor authentication, returns the phone
   * number used for MFA. Note that the phone number will be redacted, showing only
   * the last four digits. If the patient is not registered for MFA, then null is returned.
   */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** The preferred delivery method. Can be sms or voice. */
  preferredDeliveryMethod?: Maybe<MfaFactor>;
};

export type MultipleChoicePayload = {
  __typename?: 'MultipleChoicePayload';
  /** An array containing multiple choice questions. */
  content: Array<MultipleChoiceQuestion>;
  /** if true, the client must collect input from the user in the form of a multiple choice answer selection. */
  requiresMultipleChoiceSelectionInput: Scalars['Boolean']['output'];
  /** must be "multipleChoice". */
  type: Scalars['String']['output'];
};

export type MultipleChoicePayloadInput = {
  /** An array containing multiple choice questions with their selection indices populated */
  content: Array<MultipleChoiceQuestionInput>;
  /** Set to true as returned by toucanNewConversation or toucanContinueConversation */
  requiresMultipleChoiceSelectionInput: Scalars['Boolean']['input'];
  /** must be "multipleChoice". */
  type: Scalars['String']['input'];
};

export type MultipleChoiceQuestion = {
  __typename?: 'MultipleChoiceQuestion';
  /**
   * Indicates how many of the provided options the user is allowed to select.
   * SINGLE_SELECTION means user can only select one option.
   */
  constraint: MultipleChoiceQuestionConstraint;
  /** An array containing a list of multiple choice options. */
  optionsList: Array<Scalars['String']['output']>;
  /** The multiple choice question text */
  question: Scalars['String']['output'];
  /** Will be null if the user input has not yet been collected. */
  selectionIndices: Array<Scalars['Int']['output']>;
};

export enum MultipleChoiceQuestionConstraint {
  MultipleSelection = 'MULTIPLE_SELECTION',
  SingleSelection = 'SINGLE_SELECTION'
}

export type MultipleChoiceQuestionInput = {
  /** Determines whether this question allows multiple options or just a single option to be selected */
  constraint: MultipleChoiceQuestionConstraint;
  /** An array containing answer texts as options for the user */
  optionsList: Array<Scalars['String']['input']>;
  /** The multiple choice question text as returned by toucanNewConversation or toucanContinueConversation */
  question: Scalars['String']['input'];
  /** Populate this array with the indices of the options selected by the user */
  selectionIndices: Array<Scalars['Int']['input']>;
};

export type MultipleChoiceResponseInput = {
  /** Unique ID assigned to each feedback question (client-defined) */
  questionId: Scalars['String']['input'];
  /** The text of the question */
  questionText: Scalars['String']['input'];
  /** A list of options selected by the user */
  response: Array<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Lock document to an internal user. */
  acquireDocumentLock?: Maybe<AcquireDocumentLockPayload>;
  /** Adds feedback to document summary */
  addDocumentSummaryFeedback?: Maybe<AddDocumentSummaryFeedbackMutationPayload>;
  /** Triggers the doc processing process to create a PDF from the C-CDA and make it available in the Chart */
  addExternalDocumentToChart?: Maybe<AddExternalDocumentToChartPayload>;
  /** Applies a schedule template variant to create appointment inventories */
  applyTemplateAppointmentInventory?: Maybe<ApplyTemplateAppointmentInventoryPayload>;
  archiveInsurance?: Maybe<ArchiveInsurancePayload>;
  attachDocumentToProcedure?: Maybe<AttachDocumentToProcedurePayload>;
  /** Attaches ServiceTicketImproperlyClosedOrder to a service ticket. */
  attachServiceTicketImproperlyClosedOrder?: Maybe<AttachServiceTicketImproperlyClosedOrderPayload>;
  authorizeConnectedDevices?: Maybe<AuthorizeConnectedDevicesPayload>;
  /** attempt to populate insurance values from ocr */
  autoVerifyInsuranceOcrResult?: Maybe<AutoVerifyInsuranceOcrResultPayload>;
  bookAppointment?: Maybe<BookAppointmentPayload>;
  bulkUpdateInsurancePlanType?: Maybe<BulkUpdateInsurancePlanPayload>;
  cancelAppointment?: Maybe<CancelAppointmentPayload>;
  cancelServiceTicketTransmission?: Maybe<CancelServiceTicketTransmissionPayload>;
  /** @deprecated Please use the camelCase version. */
  cancel_appointment?: Maybe<CancelAppointmentPayload>;
  chargePatientForCopay?: Maybe<ChargePatientForCopayPayload>;
  chargePatientForServiceTicket?: Maybe<ChargePatientForServiceTicketPayload>;
  checkInsuranceEligibility?: Maybe<CheckInsuranceEligibilityPayload>;
  /** Verifies access key and identity then updates email, password, and Auth0 record */
  claimChart?: Maybe<ClaimChartPayload>;
  claimVisit?: Maybe<ClaimVisitPayload>;
  /** Clone a consult order. The clone's status is set to `unsigned` so that it can be edited. */
  cloneConsultOrder?: Maybe<CloneConsultOrderPayload>;
  completeProcedureInteraction?: Maybe<CompleteProcedureInteractionPayload>;
  createAdmission?: Maybe<CreateAdmissionPayload>;
  createAdmissionAttachment?: Maybe<CreateAdmissionAttachmentPayload>;
  /** Creates a new appointment inventory. */
  createAppointmentInventory?: Maybe<CreateAppointmentInventoryPayload>;
  createAppointmentRecording?: Maybe<CreateAppointmentRecordingPayload>;
  createBillingDistrictInsuranceNetwork?: Maybe<CreateBillingDistrictInsuranceNetworkPayload>;
  createCazRequest?: Maybe<CreateCazRequestPayload>;
  createContact?: Maybe<CreateContactPayload>;
  createHelpRequest?: Maybe<CreateHelpRequestPayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createInsuranceNetworksProviders?: Maybe<CreateInsuranceNetworksProvidersPayload>;
  createInsurancePlan?: Maybe<CreateInsurancePlanPayload>;
  /** Creates a Lab Order outreach manager batch */
  createLabOrderOutreach?: Maybe<CreateLabOrderOutreachPayload>;
  createMassAppointmentCancellationRequest?: Maybe<CreateMassAppointmentCancellationRequestPayload>;
  createMiscClericalNote?: Maybe<CreateMiscClericalNotePayload>;
  createMlFeedbackProcedureSuggestion?: Maybe<CreateMlFeedbackProcedureSuggestionPayload>;
  createMroWorkflow?: Maybe<CreateMroWorkflowPayload>;
  createOrUpdateInsurancePlanInNetworkStatus?: Maybe<CreateOrUpdateInsurancePlanInNetworkStatusPayload>;
  createOrUpdateMeasurementForProcedure?: Maybe<CreateOrUpdateMeasurementForProcedurePayload>;
  /** Create patient address */
  createPatientAddress?: Maybe<CreatePatientAddressPayload>;
  /** Create patient email */
  createPatientEmail?: Maybe<CreatePatientEmailPayload>;
  /** Create patient emergency contact */
  createPatientEmergencyContact?: Maybe<CreatePatientEmergencyContactPayload>;
  /** Create patient phone number */
  createPatientPhoneNumber?: Maybe<CreatePatientPhoneNumberPayload>;
  createPrescriptionChangeRequests?: Maybe<CreatePrescriptionChangeRequestsPayload>;
  createPrescriptionRenewalRequests?: Maybe<CreatePrescriptionRenewalRequestsPayload>;
  createProblemAttachment?: Maybe<CreateProblemAttachmentPayload>;
  createProcedureInteraction?: Maybe<CreateProcedureInteractionPayload>;
  createProcedureInteractionIndication?: Maybe<CreateProcedureInteractionIndicationPayload>;
  createProgramEnrollmentCareGiver?: Maybe<CreateProgramEnrollmentCareGiverPayload>;
  /** Create a new schedule template */
  createScheduleTemplate?: Maybe<CreateScheduleTemplatePayload>;
  /** Create a new schedule template variant */
  createScheduleTemplateVariant?: Maybe<CreateScheduleTemplateVariantPayload>;
  createSmartDraft?: Maybe<CreateSmartDraftPayload>;
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntentPayload>;
  createSummary?: Maybe<CreateSummaryPayload>;
  /** To create survey orders via Outreach Manager */
  createSurveyOrderOutreach?: Maybe<CreateSurveyOrderOutreachPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createToken?: Maybe<CreateTokenPayload>;
  /** Create User Engagement Message outreach batches. */
  createUserEngagementMessageOutreach?: Maybe<CreateUserEngagementMessageOutreachPayload>;
  createVideoCall?: Maybe<CreateVideoCallPayload>;
  /** Create Visit Follow-Up Order outreach batches. */
  createVisitFollowUpOrderOutreach?: Maybe<CreateVisitFollowUpOrderOutreachPayload>;
  createVoluntaryAlignmentOffer?: Maybe<CreateVoluntaryAlignmentOfferPayload>;
  /** @deprecated Please use the camelCase version. */
  create_prescription_renewal_requests?: Maybe<CreatePrescriptionRenewalRequestsPayload>;
  debugServiceTicketTransmission?: Maybe<DebugServiceTicketTransmissionPayload>;
  deleteAdmission?: Maybe<DeleteAdmissionPayload>;
  deleteAdmissionAttachment?: Maybe<DeleteAdmissionAttachmentPayload>;
  deleteBillingDistrictInsuranceNetwork?: Maybe<DeleteBillingDistrictInsuranceNetworkPayload>;
  deleteDocumentFromProcedure?: Maybe<DeleteDocumentFromProcedurePayload>;
  deleteInsuranceCard?: Maybe<DeleteInsuranceCardPayload>;
  deleteInsuranceNetworksProviders?: Maybe<DeleteInsuranceNetworksProvidersPayload>;
  /** Delete patient address by id */
  deletePatientAddress?: Maybe<DeletePatientAddressPayload>;
  /** Delete patient email by id */
  deletePatientEmail?: Maybe<DeletePatientEmailPayload>;
  /** Delete patient emergency contact */
  deletePatientEmergencyContact?: Maybe<DeletePatientEmergencyContactPayload>;
  /** Delete patient phone number by id */
  deletePatientPhoneNumber?: Maybe<DeletePatientPhoneNumberPayload>;
  deleteProblemAttachment?: Maybe<DeleteProblemAttachmentPayload>;
  deleteProcedureInteraction?: Maybe<DeleteProcedureInteractionPayload>;
  deleteProcedureInteractionIndication?: Maybe<DeleteProcedureInteractionIndicationPayload>;
  deleteProgramEnrollmentCareGiver?: Maybe<DeleteProgramEnrollmentCareGiverPayload>;
  /** Delete a schedule template */
  deleteScheduleTemplate?: Maybe<DeleteScheduleTemplatePayload>;
  /** Delete a schedule template variant */
  deleteScheduleTemplateVariant?: Maybe<DeleteScheduleTemplateVariantPayload>;
  deleteServiceTicketItem?: Maybe<DeleteServiceTicketItemPayload>;
  deleteVoluntaryAlignmentOffer?: Maybe<DeleteVoluntaryAlignmentOfferPayload>;
  detachStripePaymentMethod?: Maybe<DetachStripePaymentMethodPayload>;
  dismissMlBillingProcedureSuggestion?: Maybe<DismissMlBillingProcedureSuggestionPayload>;
  /** Duplicate a specific schedule template variant */
  duplicateScheduleTemplateVariant?: Maybe<DuplicateScheduleTemplateVariantPayload>;
  endAmazonClinicVisit?: Maybe<EndAmazonClinicVisitPayload>;
  endVideoCall?: Maybe<EndVideoCallPayload>;
  exportOrphanedOrders?: Maybe<ExportOrphanedOrdersPayload>;
  forceSignSummary?: Maybe<ForceSignSummaryPayload>;
  /** Creates a new access key for a given patient returning key and url for usage */
  generateAccessKey?: Maybe<GenerateAccessKeyPayload>;
  /** Mark Prescription Drug Monitoring Program Report as viewed */
  markPdmpReportAsViewed?: Maybe<MarkPdmpReportAsViewedPayload>;
  /** Mark Rxs in cart as ready to sign */
  markReadyToSign?: Maybe<MarkReadyToSignPayload>;
  /** Mark all CS Rxs in cart as ready to sign */
  markRxCartReadyToSign?: Maybe<MarkRxCartReadyToSignPayload>;
  /** Used to let patients not be required to setup MFA */
  patientAddMfaException?: Maybe<PatientAddMfaExceptionPayload>;
  /** Used to let patients delete their existing MFA factors */
  patientDisableMfa?: Maybe<PatientDisableMfaPayload>;
  pauseScribeStream?: Maybe<PauseScribeStreamPayload>;
  /** Promotes insurance to the top of the list */
  promoteInsurance?: Maybe<PromoteInsurancePayload>;
  queueAudioChunk?: Maybe<QueueAudioChunkPayload>;
  refreshTaskInIndex?: Maybe<RefreshTaskInIndexPayload>;
  rejectSuspectProblem?: Maybe<RejectSuspectProblemPayload>;
  /** Unlock document locked to an internal user. */
  releaseDocumentLock?: Maybe<ReleaseDocumentLockPayload>;
  /** Removes appointment inventories for a given date and provider. */
  removeAppointmentInventories?: Maybe<RemoveAppointmentInventoriesPayload>;
  /** Removes a specific appointment inventory. */
  removeAppointmentInventory?: Maybe<RemoveAppointmentInventoryPayload>;
  removePatientPcpAndUpdateOffice?: Maybe<RemovePatientPcpAndUpdateOfficePayload>;
  requestDocusignVoluntaryAlignment?: Maybe<RequestDocusignVoluntaryAlignmentPayload>;
  /** Request a Prescription Drug Monitoring Program Report */
  requestPdmpReport?: Maybe<RequestPdmpReportPayload>;
  rescheduleAppointment?: Maybe<RescheduleAppointmentPayload>;
  resendDirectSignupEmail?: Maybe<ResendDirectSignupEmailPayload>;
  resendVoluntaryAlignmentDocusignRequest?: Maybe<ResendVoluntaryAlignmentDocusignRequestPayload>;
  resumeScribeStream?: Maybe<ResumeScribeStreamPayload>;
  /** Transitions a membership group invite status to revoked */
  revokeMembershipGroupInvite?: Maybe<RevokeMembershipGroupInvitePayload>;
  /** Run specified commands from the billing dashboard */
  runBillingAdminTask?: Maybe<RunBillingAdminTaskPayload>;
  /** Run a Waystar eligibility check on the given insurance */
  runInsuranceEligibilityAutomaticVerification?: Maybe<RunInsuranceEligibilityAutomaticVerificationPayload>;
  saveAppointmentRecordingDetailedLaunchFeedback?: Maybe<SaveAppointmentRecordingDetailedLaunchFeedbackPayload>;
  saveAppointmentRecordingFeedback?: Maybe<SaveAppointmentRecordingFeedbackPayload>;
  saveScribeSettings?: Maybe<SaveScribeSettingsPayload>;
  saveSmartDraftFeedback?: Maybe<SaveSmartDraftFeedbackPayload>;
  saveSmartDraftText?: Maybe<SaveSmartDraftTextPayload>;
  saveSuggestedProblemFeedback?: Maybe<SaveSuggestedProblemFeedbackPayload>;
  saveThreadTagFeedback?: Maybe<SaveThreadTagFeedbackPayload>;
  saveUserSmartDraftSettings?: Maybe<SaveUserSmartDraftSettingsPayload>;
  /** Send appointment confirmation email to the patient */
  sendAppointmentConfirmationEmail?: Maybe<SendAppointmentConfirmationEmailPayload>;
  /** Send a chart claiming email to a patient. */
  sendEmailForChartClaiming?: Maybe<SendEmailForChartClaimingPayload>;
  sendForgotPasswordEmail?: Maybe<SendForgotPasswordEmailPayload>;
  /** Sends or Re-Sends a Membership Group Invite */
  sendMembershipGroupInvite?: Maybe<SendMembershipGroupInvitePayload>;
  sendRegistrationReminderEmail?: Maybe<SendRegistrationReminderEmailPayload>;
  /** Send a chart claiming SMS text message to a patient. */
  sendSmsForChartClaiming?: Maybe<SendSmsForChartClaimingPayload>;
  /** Update the SeniorHealth::AdditionalEligbilityDetail for a patient. */
  seniorHealthUpdateAdditionalEligibilityDetail?: Maybe<UpdateAdditionalEligibilityDetailPayload>;
  /** Update the SeniorHealth::CareStatus for a patient. */
  seniorHealthUpdateCareStatus?: Maybe<UpdateCareStatusPayload>;
  /** Update the SeniorHealth::EnrollmentStatus for a patient. */
  seniorHealthUpdateEnrollmentStatus?: Maybe<UpdateEnrollmentStatusPayload>;
  /** Update the SeniorHealth::PatientData `is_insurance_active` flag for a patient. */
  seniorHealthUpdateIsInsuranceActive?: Maybe<UpdateIsInsuranceActivePayload>;
  setProviderIn?: Maybe<SetProviderInPayload>;
  setProviderOut?: Maybe<SetProviderOutPayload>;
  signLegalDocument?: Maybe<SignLegalDocumentPayload>;
  startAmazonClinicVisit?: Maybe<StartAmazonClinicVisitPayload>;
  startAppointmentRecording?: Maybe<StartAppointmentRecordingPayload>;
  startCareflow?: Maybe<StartCareflowPayload>;
  /** Triggers the manual hie query process */
  startManualHieQuery?: Maybe<StartManualHieQueryPayload>;
  startStreamingSession?: Maybe<StartStreamingSessionPayload>;
  stopAppointmentRecording?: Maybe<StopAppointmentRecordingPayload>;
  /** Send an ADT sync for given patient */
  syncPatient?: Maybe<SyncPatientPayload>;
  /** Continue an existing conversation with IHS-Service */
  toucanContinueConversation?: Maybe<ToucanContinueConversationPayload>;
  /** Generate and start streaming a response from IHS-Service */
  toucanGenerate?: Maybe<ToucanGeneratePayload>;
  /** Initiates a new conversation with IHS-Service */
  toucanNewConversation?: Maybe<ToucanNewConversationPayload>;
  /** Submits user feedback to IHS-Service */
  toucanRegisterFeedback?: Maybe<ToucanRegisterFeedbackPayload>;
  transcribeAppointmentRecording?: Maybe<TranscribeAppointmentRecordingPayload>;
  transmitServiceTicketTransmission?: Maybe<TransmitServiceTicketTransmissionPayload>;
  triggerAppointment?: Maybe<TriggerAppointmentPayload>;
  /**
   * Trigger events like 'file', 'delete' and flag. For availible events on a
   * specific document, query the `document.availableStatusEvents` field.
   */
  triggerDocumentStatusEvent?: Maybe<TriggerDocumentStatusEventPayload>;
  triggerTask?: Maybe<TriggerTaskPayload>;
  unarchiveInsurance?: Maybe<UnarchiveInsurancePayload>;
  /** Unlinks the Auth0 account and disables LWA for the patient ID */
  unlinkAmazonAccount?: Maybe<UnlinkAmazonAccountPayload>;
  unlinkProblemAttachment?: Maybe<UnlinkProblemAttachmentPayload>;
  updateAddress?: Maybe<UpdateAddressPayload>;
  updateAdmission?: Maybe<UpdateAdmissionPayload>;
  updateAppointment?: Maybe<UpdateAppointmentPayload>;
  /** Updates an existing appointment inventory. */
  updateAppointmentInventory?: Maybe<UpdateAppointmentInventoryPayload>;
  updateCazRequest?: Maybe<UpdateCazRequestPayload>;
  updateContact?: Maybe<UpdateContactPayload>;
  /** Mutation to update patient's default copay stripe ID */
  updateDefaultCopayStripeId?: Maybe<UpdateDefaultCopayStripeIdPayload>;
  updateHieOptIn?: Maybe<UpdateHieOptInPayload>;
  updateImproperlyClosedServiceTicketOrder?: Maybe<UpdateImproperlyClosedServiceTicketOrderPayload>;
  updateInsurance?: Maybe<UpdateInsurancePayload>;
  updateInsurancePlan?: Maybe<UpdateInsurancePlanPayload>;
  updateInternalUserPreferences?: Maybe<UpdateInternalUserPreferencesPayload>;
  updateLabOrder?: Maybe<UpdateLabOrderPayload>;
  updateMroWorkflow?: Maybe<UpdateMroWorkflowPayload>;
  /**
   * Can update and create any field that has accepts_nested_attributes_for in the
   * model. For deleting, the additional allow_destroy: true is required
   */
  updatePatient?: Maybe<UpdatePatientPayload>;
  /** Update patient address */
  updatePatientAddress?: Maybe<UpdatePatientAddressPayload>;
  /** Update patient admin comments */
  updatePatientAdminComments?: Maybe<UpdatePatientAdminCommentsPayload>;
  /** Update patient demographic info */
  updatePatientDemographics?: Maybe<UpdatePatientDemographicsPayload>;
  /** Update patient email */
  updatePatientEmail?: Maybe<UpdatePatientEmailPayload>;
  /** Update patient emergency contact */
  updatePatientEmergencyContact?: Maybe<UpdatePatientEmergencyContactPayload>;
  /** Update patient login password */
  updatePatientPassword?: Maybe<UpdatePatientPasswordPayload>;
  updatePatientPcp?: Maybe<UpdatePatientPcpPayload>;
  /** Update patient phone number */
  updatePatientPhoneNumber?: Maybe<UpdatePatientPhoneNumberPayload>;
  updatePatientPreferences?: Maybe<UpdatePatientPreferencesPayload>;
  updatePendingDirectSignupAccount?: Maybe<UpdatePendingDirectSignupAccountPayload>;
  updateProcedureInteraction?: Maybe<UpdateProcedureInteractionPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  updateProgramCarePlan?: Maybe<UpdateProgramCarePlanPayload>;
  updateProgramEnrollment?: Maybe<UpdateProgramEnrollmentPayload>;
  updateProgramEnrollmentCareTeam?: Maybe<UpdateProgramEnrollmentCareTeamPayload>;
  updateProgramForAppointment?: Maybe<UpdateProgramForAppointmentPayload>;
  updateProgramVisit?: Maybe<UpdateProgramVisitPayload>;
  /** Creates or updates patient's registration waitlist enrollment */
  updateRegistrationWaitlistEnrollment?: Maybe<UpdateRegistrationWaitlistEnrollmentPayload>;
  /** Update schedule template (folder) details */
  updateScheduleTemplate?: Maybe<UpdateScheduleTemplatePayload>;
  /** Update schedule template variant details */
  updateScheduleTemplateVariant?: Maybe<UpdateScheduleTemplateVariantPayload>;
  /** Updates slots only on the Template Variant */
  updateScheduleTemplateVariantSlots?: Maybe<UpdateScheduleTemplateVariantSlotsPayload>;
  updateServiceTicket?: Maybe<UpdateServiceTicketPayload>;
  updateServiceTicketItem?: Maybe<UpdateServiceTicketItemPayload>;
  updateStripePaymentMethodNickname?: Maybe<UpdateStripePaymentMethodNicknamePayload>;
  updateTask?: Maybe<UpdateTaskPayload>;
  updateVoluntaryAlignmentOffer?: Maybe<UpdateVoluntaryAlignmentOfferPayload>;
  upsertMlFeedbackTodoMessageReassignment?: Maybe<UpsertMlFeedbackTodoMessageReassignmentPayload>;
  /**
   * Validates a claim code, persisting subscription-related data as needed, and
   * returns information on payment provider subscription.
   */
  validateClaimCode?: Maybe<ValidateClaimCodePayload>;
};


export type MutationAcquireDocumentLockArgs = {
  input: AcquireDocumentLockInput;
};


export type MutationAddDocumentSummaryFeedbackArgs = {
  input: AddDocumentSummaryFeedbackMutationInput;
};


export type MutationAddExternalDocumentToChartArgs = {
  input: AddExternalDocumentToChartInput;
};


export type MutationApplyTemplateAppointmentInventoryArgs = {
  input: ApplyTemplateAppointmentInventoryInput;
};


export type MutationArchiveInsuranceArgs = {
  input: ArchiveInsuranceInput;
};


export type MutationAttachDocumentToProcedureArgs = {
  input: AttachDocumentToProcedureInput;
};


export type MutationAttachServiceTicketImproperlyClosedOrderArgs = {
  input: AttachServiceTicketImproperlyClosedOrderInput;
};


export type MutationAuthorizeConnectedDevicesArgs = {
  input: AuthorizeConnectedDevicesInput;
};


export type MutationAutoVerifyInsuranceOcrResultArgs = {
  input: AutoVerifyInsuranceOcrResultInput;
};


export type MutationBookAppointmentArgs = {
  input: BookAppointmentInput;
};


export type MutationBulkUpdateInsurancePlanTypeArgs = {
  input: BulkUpdateInsurancePlanInput;
};


export type MutationCancelAppointmentArgs = {
  input: CancelAppointmentInput;
};


export type MutationCancelServiceTicketTransmissionArgs = {
  input: CancelServiceTicketTransmissionInput;
};


export type MutationCancel_AppointmentArgs = {
  input: CancelAppointmentInput;
};


export type MutationChargePatientForCopayArgs = {
  input: ChargePatientForCopayInput;
};


export type MutationChargePatientForServiceTicketArgs = {
  input: ChargePatientForServiceTicketInput;
};


export type MutationCheckInsuranceEligibilityArgs = {
  input: CheckInsuranceEligibilityInput;
};


export type MutationClaimChartArgs = {
  input: ClaimChartInput;
};


export type MutationClaimVisitArgs = {
  input: ClaimVisitInput;
};


export type MutationCloneConsultOrderArgs = {
  input: CloneConsultOrderInput;
};


export type MutationCompleteProcedureInteractionArgs = {
  input: CompleteProcedureInteractionInput;
};


export type MutationCreateAdmissionArgs = {
  input: CreateAdmissionInput;
};


export type MutationCreateAdmissionAttachmentArgs = {
  input: CreateAdmissionAttachmentInput;
};


export type MutationCreateAppointmentInventoryArgs = {
  input: CreateAppointmentInventoryInput;
};


export type MutationCreateAppointmentRecordingArgs = {
  input: CreateAppointmentRecordingInput;
};


export type MutationCreateBillingDistrictInsuranceNetworkArgs = {
  input: CreateBillingDistrictInsuranceNetworkInput;
};


export type MutationCreateCazRequestArgs = {
  input: CreateCazRequestInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateHelpRequestArgs = {
  input: CreateHelpRequestInput;
};


export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};


export type MutationCreateInsuranceNetworksProvidersArgs = {
  input: CreateInsuranceNetworksProvidersInput;
};


export type MutationCreateInsurancePlanArgs = {
  input: CreateInsurancePlanInput;
};


export type MutationCreateLabOrderOutreachArgs = {
  input: CreateLabOrderOutreachInput;
};


export type MutationCreateMassAppointmentCancellationRequestArgs = {
  input: CreateMassAppointmentCancellationRequestInput;
};


export type MutationCreateMiscClericalNoteArgs = {
  input: CreateMiscClericalNoteInput;
};


export type MutationCreateMlFeedbackProcedureSuggestionArgs = {
  input: CreateMlFeedbackProcedureSuggestionInput;
};


export type MutationCreateMroWorkflowArgs = {
  input: CreateMroWorkflowInput;
};


export type MutationCreateOrUpdateInsurancePlanInNetworkStatusArgs = {
  input: CreateOrUpdateInsurancePlanInNetworkStatusInput;
};


export type MutationCreateOrUpdateMeasurementForProcedureArgs = {
  input: CreateOrUpdateMeasurementForProcedureInput;
};


export type MutationCreatePatientAddressArgs = {
  input: CreatePatientAddressInput;
};


export type MutationCreatePatientEmailArgs = {
  input: CreatePatientEmailInput;
};


export type MutationCreatePatientEmergencyContactArgs = {
  input: CreatePatientEmergencyContactInput;
};


export type MutationCreatePatientPhoneNumberArgs = {
  input: CreatePatientPhoneNumberInput;
};


export type MutationCreatePrescriptionChangeRequestsArgs = {
  input: CreatePrescriptionChangeRequestsInput;
};


export type MutationCreatePrescriptionRenewalRequestsArgs = {
  input: CreatePrescriptionRenewalRequestsInput;
};


export type MutationCreateProblemAttachmentArgs = {
  input: CreateProblemAttachmentInput;
};


export type MutationCreateProcedureInteractionArgs = {
  input: CreateProcedureInteractionInput;
};


export type MutationCreateProcedureInteractionIndicationArgs = {
  input: CreateProcedureInteractionIndicationInput;
};


export type MutationCreateProgramEnrollmentCareGiverArgs = {
  input: CreateProgramEnrollmentCareGiverInput;
};


export type MutationCreateScheduleTemplateArgs = {
  input: CreateScheduleTemplateInput;
};


export type MutationCreateScheduleTemplateVariantArgs = {
  input: CreateScheduleTemplateVariantInput;
};


export type MutationCreateSmartDraftArgs = {
  input: CreateSmartDraftInput;
};


export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};


export type MutationCreateSummaryArgs = {
  input: CreateSummaryInput;
};


export type MutationCreateSurveyOrderOutreachArgs = {
  input: CreateSurveyOrderOutreachInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};


export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};


export type MutationCreateUserEngagementMessageOutreachArgs = {
  input: CreateUserEngagementMessageOutreachInput;
};


export type MutationCreateVideoCallArgs = {
  input: CreateVideoCallInput;
};


export type MutationCreateVisitFollowUpOrderOutreachArgs = {
  input: CreateVisitFollowUpOrderOutreachInput;
};


export type MutationCreateVoluntaryAlignmentOfferArgs = {
  input: CreateVoluntaryAlignmentOfferInput;
};


export type MutationCreate_Prescription_Renewal_RequestsArgs = {
  input: CreatePrescriptionRenewalRequestsInput;
};


export type MutationDebugServiceTicketTransmissionArgs = {
  input: DebugServiceTicketTransmissionInput;
};


export type MutationDeleteAdmissionArgs = {
  input: DeleteAdmissionInput;
};


export type MutationDeleteAdmissionAttachmentArgs = {
  input: DeleteAdmissionAttachmentInput;
};


export type MutationDeleteBillingDistrictInsuranceNetworkArgs = {
  input: DeleteBillingDistrictInsuranceNetworkInput;
};


export type MutationDeleteDocumentFromProcedureArgs = {
  input: DeleteDocumentFromProcedureInput;
};


export type MutationDeleteInsuranceCardArgs = {
  input: DeleteInsuranceCardInput;
};


export type MutationDeleteInsuranceNetworksProvidersArgs = {
  input: DeleteInsuranceNetworksProvidersInput;
};


export type MutationDeletePatientAddressArgs = {
  input: DeletePatientAddressInput;
};


export type MutationDeletePatientEmailArgs = {
  input: DeletePatientEmailInput;
};


export type MutationDeletePatientEmergencyContactArgs = {
  input: DeletePatientEmergencyContactInput;
};


export type MutationDeletePatientPhoneNumberArgs = {
  input: DeletePatientPhoneNumberInput;
};


export type MutationDeleteProblemAttachmentArgs = {
  input: DeleteProblemAttachmentInput;
};


export type MutationDeleteProcedureInteractionArgs = {
  input: DeleteProcedureInteractionInput;
};


export type MutationDeleteProcedureInteractionIndicationArgs = {
  input: DeleteProcedureInteractionIndicationInput;
};


export type MutationDeleteProgramEnrollmentCareGiverArgs = {
  input: DeleteProgramEnrollmentCareGiverInput;
};


export type MutationDeleteScheduleTemplateArgs = {
  input: DeleteScheduleTemplateInput;
};


export type MutationDeleteScheduleTemplateVariantArgs = {
  input: DeleteScheduleTemplateVariantInput;
};


export type MutationDeleteServiceTicketItemArgs = {
  input: DeleteServiceTicketItemInput;
};


export type MutationDeleteVoluntaryAlignmentOfferArgs = {
  input: DeleteVoluntaryAlignmentOfferInput;
};


export type MutationDetachStripePaymentMethodArgs = {
  input: DetachStripePaymentMethodInput;
};


export type MutationDismissMlBillingProcedureSuggestionArgs = {
  input: DismissMlBillingProcedureSuggestionInput;
};


export type MutationDuplicateScheduleTemplateVariantArgs = {
  input: DuplicateScheduleTemplateVariantInput;
};


export type MutationEndAmazonClinicVisitArgs = {
  input: EndAmazonClinicVisitInput;
};


export type MutationEndVideoCallArgs = {
  input: EndVideoCallInput;
};


export type MutationExportOrphanedOrdersArgs = {
  input: ExportOrphanedOrdersInput;
};


export type MutationForceSignSummaryArgs = {
  input: ForceSignSummaryInput;
};


export type MutationGenerateAccessKeyArgs = {
  input: GenerateAccessKeyInput;
};


export type MutationMarkPdmpReportAsViewedArgs = {
  input: MarkPdmpReportAsViewedInput;
};


export type MutationMarkReadyToSignArgs = {
  input: MarkReadyToSignInput;
};


export type MutationMarkRxCartReadyToSignArgs = {
  input: MarkRxCartReadyToSignInput;
};


export type MutationPatientAddMfaExceptionArgs = {
  input: PatientAddMfaExceptionInput;
};


export type MutationPatientDisableMfaArgs = {
  input: PatientDisableMfaInput;
};


export type MutationPauseScribeStreamArgs = {
  input: PauseScribeStreamInput;
};


export type MutationPromoteInsuranceArgs = {
  input: PromoteInsuranceInput;
};


export type MutationQueueAudioChunkArgs = {
  input: QueueAudioChunkInput;
};


export type MutationRefreshTaskInIndexArgs = {
  input: RefreshTaskInIndexInput;
};


export type MutationRejectSuspectProblemArgs = {
  input: RejectSuspectProblemInput;
};


export type MutationReleaseDocumentLockArgs = {
  input: ReleaseDocumentLockInput;
};


export type MutationRemoveAppointmentInventoriesArgs = {
  input: RemoveAppointmentInventoriesInput;
};


export type MutationRemoveAppointmentInventoryArgs = {
  input: RemoveAppointmentInventoryInput;
};


export type MutationRemovePatientPcpAndUpdateOfficeArgs = {
  input: RemovePatientPcpAndUpdateOfficeInput;
};


export type MutationRequestDocusignVoluntaryAlignmentArgs = {
  input: RequestDocusignVoluntaryAlignmentInput;
};


export type MutationRequestPdmpReportArgs = {
  input: RequestPdmpReportInput;
};


export type MutationRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};


export type MutationResendDirectSignupEmailArgs = {
  input: ResendDirectSignupEmailInput;
};


export type MutationResendVoluntaryAlignmentDocusignRequestArgs = {
  input: ResendVoluntaryAlignmentDocusignRequestInput;
};


export type MutationResumeScribeStreamArgs = {
  input: ResumeScribeStreamInput;
};


export type MutationRevokeMembershipGroupInviteArgs = {
  input: RevokeMembershipGroupInviteInput;
};


export type MutationRunBillingAdminTaskArgs = {
  input: RunBillingAdminTaskInput;
};


export type MutationRunInsuranceEligibilityAutomaticVerificationArgs = {
  input: RunInsuranceEligibilityAutomaticVerificationInput;
};


export type MutationSaveAppointmentRecordingDetailedLaunchFeedbackArgs = {
  input: SaveAppointmentRecordingDetailedLaunchFeedbackInput;
};


export type MutationSaveAppointmentRecordingFeedbackArgs = {
  input: SaveAppointmentRecordingFeedbackInput;
};


export type MutationSaveScribeSettingsArgs = {
  input: SaveScribeSettingsInput;
};


export type MutationSaveSmartDraftFeedbackArgs = {
  input: SaveSmartDraftFeedbackInput;
};


export type MutationSaveSmartDraftTextArgs = {
  input: SaveSmartDraftTextInput;
};


export type MutationSaveSuggestedProblemFeedbackArgs = {
  input: SaveSuggestedProblemFeedbackInput;
};


export type MutationSaveThreadTagFeedbackArgs = {
  input: SaveThreadTagFeedbackInput;
};


export type MutationSaveUserSmartDraftSettingsArgs = {
  input: SaveUserSmartDraftSettingsInput;
};


export type MutationSendAppointmentConfirmationEmailArgs = {
  input: SendAppointmentConfirmationEmailInput;
};


export type MutationSendEmailForChartClaimingArgs = {
  input: SendEmailForChartClaimingInput;
};


export type MutationSendForgotPasswordEmailArgs = {
  input: SendForgotPasswordEmailInput;
};


export type MutationSendMembershipGroupInviteArgs = {
  input: SendMembershipGroupInviteInput;
};


export type MutationSendRegistrationReminderEmailArgs = {
  input: SendRegistrationReminderEmailInput;
};


export type MutationSendSmsForChartClaimingArgs = {
  input: SendSmsForChartClaimingInput;
};


export type MutationSeniorHealthUpdateAdditionalEligibilityDetailArgs = {
  input: UpdateAdditionalEligibilityDetailInput;
};


export type MutationSeniorHealthUpdateCareStatusArgs = {
  input: UpdateCareStatusInput;
};


export type MutationSeniorHealthUpdateEnrollmentStatusArgs = {
  input: UpdateEnrollmentStatusInput;
};


export type MutationSeniorHealthUpdateIsInsuranceActiveArgs = {
  input: UpdateIsInsuranceActiveInput;
};


export type MutationSetProviderInArgs = {
  input: SetProviderInInput;
};


export type MutationSetProviderOutArgs = {
  input: SetProviderOutInput;
};


export type MutationSignLegalDocumentArgs = {
  input: SignLegalDocumentInput;
};


export type MutationStartAmazonClinicVisitArgs = {
  input: StartAmazonClinicVisitInput;
};


export type MutationStartAppointmentRecordingArgs = {
  input: StartAppointmentRecordingInput;
};


export type MutationStartCareflowArgs = {
  input: StartCareflowInput;
};


export type MutationStartManualHieQueryArgs = {
  input: StartManualHieQueryInput;
};


export type MutationStartStreamingSessionArgs = {
  input: StartStreamingSessionInput;
};


export type MutationStopAppointmentRecordingArgs = {
  input: StopAppointmentRecordingInput;
};


export type MutationSyncPatientArgs = {
  input: SyncPatientInput;
};


export type MutationToucanContinueConversationArgs = {
  input: ToucanContinueConversationInput;
};


export type MutationToucanGenerateArgs = {
  input: ToucanGenerateInput;
};


export type MutationToucanNewConversationArgs = {
  input: ToucanNewConversationInput;
};


export type MutationToucanRegisterFeedbackArgs = {
  input: ToucanRegisterFeedbackInput;
};


export type MutationTranscribeAppointmentRecordingArgs = {
  input: TranscribeAppointmentRecordingInput;
};


export type MutationTransmitServiceTicketTransmissionArgs = {
  input: TransmitServiceTicketTransmissionInput;
};


export type MutationTriggerAppointmentArgs = {
  input: TriggerAppointmentInput;
};


export type MutationTriggerDocumentStatusEventArgs = {
  input: TriggerDocumentStatusEventInput;
};


export type MutationTriggerTaskArgs = {
  input: TriggerTaskInput;
};


export type MutationUnarchiveInsuranceArgs = {
  input: UnarchiveInsuranceInput;
};


export type MutationUnlinkAmazonAccountArgs = {
  input: UnlinkAmazonAccountInput;
};


export type MutationUnlinkProblemAttachmentArgs = {
  input: UnlinkProblemAttachmentInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAdmissionArgs = {
  input: UpdateAdmissionInput;
};


export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};


export type MutationUpdateAppointmentInventoryArgs = {
  input: UpdateAppointmentInventoryInput;
};


export type MutationUpdateCazRequestArgs = {
  input: UpdateCazRequestInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateDefaultCopayStripeIdArgs = {
  input: UpdateDefaultCopayStripeIdInput;
};


export type MutationUpdateHieOptInArgs = {
  input: UpdateHieOptInInput;
};


export type MutationUpdateImproperlyClosedServiceTicketOrderArgs = {
  input: UpdateImproperlyClosedServiceTicketOrderInput;
};


export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};


export type MutationUpdateInsurancePlanArgs = {
  input: UpdateInsurancePlanInput;
};


export type MutationUpdateInternalUserPreferencesArgs = {
  input: UpdateInternalUserPreferencesInput;
};


export type MutationUpdateLabOrderArgs = {
  input: UpdateLabOrderInput;
};


export type MutationUpdateMroWorkflowArgs = {
  input: UpdateMroWorkflowInput;
};


export type MutationUpdatePatientArgs = {
  input: UpdatePatientInput;
};


export type MutationUpdatePatientAddressArgs = {
  input: UpdatePatientAddressInput;
};


export type MutationUpdatePatientAdminCommentsArgs = {
  input: UpdatePatientAdminCommentsInput;
};


export type MutationUpdatePatientDemographicsArgs = {
  input: UpdatePatientDemographicsInput;
};


export type MutationUpdatePatientEmailArgs = {
  input: UpdatePatientEmailInput;
};


export type MutationUpdatePatientEmergencyContactArgs = {
  input: UpdatePatientEmergencyContactInput;
};


export type MutationUpdatePatientPasswordArgs = {
  input: UpdatePatientPasswordInput;
};


export type MutationUpdatePatientPcpArgs = {
  input: UpdatePatientPcpInput;
};


export type MutationUpdatePatientPhoneNumberArgs = {
  input: UpdatePatientPhoneNumberInput;
};


export type MutationUpdatePatientPreferencesArgs = {
  input: UpdatePatientPreferencesInput;
};


export type MutationUpdatePendingDirectSignupAccountArgs = {
  input: UpdatePendingDirectSignupAccountInput;
};


export type MutationUpdateProcedureInteractionArgs = {
  input: UpdateProcedureInteractionInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateProgramCarePlanArgs = {
  input: UpdateProgramCarePlanInput;
};


export type MutationUpdateProgramEnrollmentArgs = {
  input: UpdateProgramEnrollmentInput;
};


export type MutationUpdateProgramEnrollmentCareTeamArgs = {
  input: UpdateProgramEnrollmentCareTeamInput;
};


export type MutationUpdateProgramForAppointmentArgs = {
  input: UpdateProgramForAppointmentInput;
};


export type MutationUpdateProgramVisitArgs = {
  input: UpdateProgramVisitInput;
};


export type MutationUpdateRegistrationWaitlistEnrollmentArgs = {
  input: UpdateRegistrationWaitlistEnrollmentInput;
};


export type MutationUpdateScheduleTemplateArgs = {
  input: UpdateScheduleTemplateInput;
};


export type MutationUpdateScheduleTemplateVariantArgs = {
  input: UpdateScheduleTemplateVariantInput;
};


export type MutationUpdateScheduleTemplateVariantSlotsArgs = {
  input: UpdateScheduleTemplateVariantSlotsInput;
};


export type MutationUpdateServiceTicketArgs = {
  input: UpdateServiceTicketInput;
};


export type MutationUpdateServiceTicketItemArgs = {
  input: UpdateServiceTicketItemInput;
};


export type MutationUpdateStripePaymentMethodNicknameArgs = {
  input: UpdateStripePaymentMethodNicknameInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateVoluntaryAlignmentOfferArgs = {
  input: UpdateVoluntaryAlignmentOfferInput;
};


export type MutationUpsertMlFeedbackTodoMessageReassignmentArgs = {
  input: UpsertMlFeedbackTodoMessageReassignmentInput;
};


export type MutationValidateClaimCodeArgs = {
  input: ValidateClaimCodeInput;
};

/** A user-readable error */
export type MutationError = {
  __typename?: 'MutationError';
  /** A description of the error */
  messages: Array<Scalars['String']['output']>;
  /** Which input value this error came from */
  path: Array<Scalars['String']['output']>;
};

export type NarxMessage = {
  __typename?: 'NarxMessage';
  severity?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type NarxScore = {
  __typename?: 'NarxScore';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Note = {
  __typename?: 'Note';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['Int']['output']>;
  clinicalUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedBy?: Maybe<InternalUser>;
  emailSubject?: Maybe<Scalars['String']['output']>;
  encounterId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  internalUser?: Maybe<InternalUser>;
  internalUserId?: Maybe<Scalars['Int']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  noteBodyText?: Maybe<Scalars['String']['output']>;
  noteType?: Maybe<NoteType>;
  noteTypeId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated This field is replaced by note_type and its subfields */
  noteTypeName?: Maybe<Scalars['String']['output']>;
  redactedBy?: Maybe<InternalUser>;
  signature?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  signedBy?: Maybe<InternalUser>;
  signedById?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Note. */
export type NoteConnection = {
  __typename?: 'NoteConnection';
  /** A list of edges. */
  edges: Array<NoteEdge>;
  /** A list of nodes. */
  nodes: Array<Note>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NoteEdge = {
  __typename?: 'NoteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Note>;
};

export type NoteStateBucket = {
  __typename?: 'NoteStateBucket';
  docCount: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  noteStatus: TransmissionState;
};

export type NoteStateWithServiceTicketTransmission = {
  __typename?: 'NoteStateWithServiceTicketTransmission';
  buckets: Array<NoteStateBucket>;
  docCountErrorUpperBound: Scalars['Int']['output'];
  sumOtherDocCount: Scalars['Int']['output'];
};

export type NoteTemplate = {
  __typename?: 'NoteTemplate';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalUserId?: Maybe<Scalars['Int']['output']>;
  messageTemplateId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type NoteType = {
  __typename?: 'NoteType';
  name?: Maybe<Scalars['String']['output']>;
  tagList?: Maybe<Array<Scalars['String']['output']>>;
};

export enum NoteTypeSystemName {
  ArchiveEmail = 'ARCHIVE_EMAIL',
  ChronicCareService = 'CHRONIC_CARE_SERVICE',
  ConsultNoteDermatology = 'CONSULT_NOTE_DERMATOLOGY',
  EmailClerical = 'EMAIL_CLERICAL',
  EmailVisit = 'EMAIL_VISIT',
  FailedDirectMessage = 'FAILED_DIRECT_MESSAGE',
  FormstackSurveySubmission = 'FORMSTACK_SURVEY_SUBMISSION',
  FsaHsaReinbursmentRequest = 'FSA_HSA_REINBURSMENT_REQUEST',
  HmrLegacyEhr = 'HMR_LEGACY_EHR',
  InboundDirectMessage = 'INBOUND_DIRECT_MESSAGE',
  LabOrder = 'LAB_ORDER',
  LabOrderPath = 'LAB_ORDER_PATH',
  LabResult = 'LAB_RESULT',
  LogCall = 'LOG_CALL',
  MedicalRecordsRelease = 'MEDICAL_RECORDS_RELEASE',
  MedInformationReleaseAuthorization = 'MED_INFORMATION_RELEASE_AUTHORIZATION',
  MentalHealth = 'MENTAL_HEALTH',
  MiscClerical = 'MISC_CLERICAL',
  MiscClinical = 'MISC_CLINICAL',
  MiscProtected = 'MISC_PROTECTED',
  OfficeVisit = 'OFFICE_VISIT',
  OfficialLetter = 'OFFICIAL_LETTER',
  OnelifeMailer = 'ONELIFE_MAILER',
  PatientSurvey = 'PATIENT_SURVEY',
  PhoneVisit = 'PHONE_VISIT',
  ProcedureOrder = 'PROCEDURE_ORDER',
  RecordsImport = 'RECORDS_IMPORT',
  ReferralRequest = 'REFERRAL_REQUEST',
  RxCsTransmitFailed = 'RX_CS_TRANSMIT_FAILED',
  RxPhoneRequest = 'RX_PHONE_REQUEST',
  RxWebRenewal = 'RX_WEB_RENEWAL',
  RxWebRenewalMobile = 'RX_WEB_RENEWAL_MOBILE',
  TravelRequest = 'TRAVEL_REQUEST',
  TreatMeNow = 'TREAT_ME_NOW',
  TriageVisit = 'TRIAGE_VISIT',
  VideoEncounter = 'VIDEO_ENCOUNTER'
}

export type Observation = {
  __typename?: 'Observation';
  components: Array<ObservationComponent>;
  effectiveDateTime?: Maybe<Scalars['DateTime']['output']>;
  fromDevice: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  integerValue?: Maybe<Scalars['Int']['output']>;
  performerName?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ObservationComponent = {
  __typename?: 'ObservationComponent';
  code: Scalars['String']['output'];
  integerValue?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum OccurrenceOptions {
  Multiple = 'multiple',
  Single = 'single'
}

export type Office = {
  __typename?: 'Office';
  address?: Maybe<Address>;
  appointmentInventories?: Maybe<AppointmentInventoryConnection>;
  /** Search for today's appointments at a specific office. */
  appointments: OfficeAppointmentsSearchResult;
  b2bCompanyId?: Maybe<Scalars['ID']['output']>;
  b2bCompanyIds?: Maybe<Array<Scalars['ID']['output']>>;
  /** @deprecated Please use the camelCase version. */
  b2b_company_id?: Maybe<Scalars['ID']['output']>;
  closed?: Maybe<Scalars['Boolean']['output']>;
  closureReason?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  closure_reason?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  directions?: Maybe<Scalars['HTML']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailAccount?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  email_account?: Maybe<Scalars['String']['output']>;
  fax: Scalars['String']['output'];
  hours: Scalars['HTML']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  image_url?: Maybe<Scalars['String']['output']>;
  isHidden: Scalars['Boolean']['output'];
  /** @deprecated Please use the camelCase version. */
  is_hidden: Scalars['Boolean']['output'];
  labHours?: Maybe<Scalars['HTML']['output']>;
  /** @deprecated Please use the camelCase version. */
  lab_hours?: Maybe<Scalars['HTML']['output']>;
  mapLink?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  map_link?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  officeGroups?: Maybe<Array<Scalars['JSON']['output']>>;
  officeLabels?: Maybe<Array<Scalars['String']['output']>>;
  pediatricServices: Scalars['Boolean']['output'];
  /** @deprecated Please use the camelCase version. */
  pediatric_services: Scalars['Boolean']['output'];
  permalink?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  seesSeniors: Scalars['Boolean']['output'];
  serviceArea?: Maybe<ServiceArea>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  structuredHours?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  virtual: Scalars['Boolean']['output'];
};


export type OfficeAppointmentInventoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['DateTime']['input'];
};


export type OfficeAppointmentsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<OfficeAppointmentsSortField>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type OfficeImageUrlArgs = {
  size?: InputMaybe<Scalars['String']['input']>;
};


export type OfficeImage_UrlArgs = {
  size?: InputMaybe<Scalars['String']['input']>;
};

export type OfficeAppointmentsSearchResult = SearchResult & {
  __typename?: 'OfficeAppointmentsSearchResult';
  aggregations: OfficeAppointmentsSearchResultAggregations;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  nodes: Array<Appointment>;
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type OfficeAppointmentsSearchResultAggregation = {
  __typename?: 'OfficeAppointmentsSearchResultAggregation';
  arrivedCount: Scalars['Int']['output'];
  seenCount: Scalars['Int']['output'];
  startedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  upcomingCount: Scalars['Int']['output'];
};

export type OfficeAppointmentsSearchResultAggregations = {
  __typename?: 'OfficeAppointmentsSearchResultAggregations';
  scheduled: OfficeAppointmentsSearchResultAggregation;
  walkIns: OfficeAppointmentsSearchResultAggregation;
};

export enum OfficeAppointmentsSortField {
  ArrivedAt = 'arrivedAt',
  StartAt = 'startAt'
}

export type OpenPracticeMessage = {
  __typename?: 'OpenPracticeMessage';
  createdAt: Scalars['DateTime']['output'];
  hl7Msg?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  operation?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum OpmEligibilityStatus {
  Active = 'active',
  Error = 'error',
  Inactive = 'inactive',
  Pending = 'pending',
  SelfPay = 'self_pay'
}

/** Interface for different types of orders */
export type Order = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  patient: Patient;
  updatedAt: Scalars['DateTime']['output'];
};

export enum OrderWithConsultantTypes {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConnectedDeviceOrder = 'ConnectedDeviceOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder'
}

/** Type for original medicare aco reach object */
export type OriginalMedicareAcoReach = {
  __typename?: 'OriginalMedicareAcoReach';
  id: Scalars['ID']['output'];
};

/** Type for original medicare alignment object */
export type OriginalMedicareAlignment = {
  __typename?: 'OriginalMedicareAlignment';
  id: Scalars['ID']['output'];
};

/** An object from which a notification may originate */
export type OriginatingObject = Appointment | TimelineComment | TimelinePost;

/** The connection type for OriginatingObject. */
export type OriginatingObjectConnection = {
  __typename?: 'OriginatingObjectConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OriginatingObjectEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OriginatingObject>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total number of objects in query result */
  totalCount: Scalars['Int']['output'];
  /** Total number of pages based on 'first' argument or max_page_size */
  totalPageCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OriginatingObjectEdge = {
  __typename?: 'OriginatingObjectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OriginatingObject>;
};

/** Type for OutreachBatchPatient */
export type OutreachBatchPatient = {
  __typename?: 'OutreachBatchPatient';
  /** Datetime when OutreachBatchPatient was created */
  createdAt: Scalars['DateTime']['output'];
  /** ID of OutreachBatchPatient */
  id: Scalars['ID']['output'];
  /** Patient object */
  patient?: Maybe<Patient>;
  /** Provider object */
  provider?: Maybe<Provider>;
  /** Reason for rejection or failure status */
  reason?: Maybe<Scalars['String']['output']>;
  /** Enum status of OutreachBatchPatient */
  status: OutreachBatchPatientStatus;
  /** Datetime when OutreachBatchPatient was last updated */
  updatedAt: Scalars['DateTime']['output'];
};

/** Custom connection type for OutreachBatchPatientType */
export type OutreachBatchPatientConnection = {
  __typename?: 'OutreachBatchPatientConnection';
  /** Total count of 'completed' patients per batch */
  completedCount: Scalars['Int']['output'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OutreachBatchPatientEdge>>>;
  /** Total count of 'failed' patients per batch */
  failedCount: Scalars['Int']['output'];
  /** Total count of 'in_progress' patients per batch */
  inProgressCount: Scalars['Int']['output'];
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OutreachBatchPatient>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of 'rejected' patients per batch */
  rejectedCount: Scalars['Int']['output'];
  /** Total count of patients per batch */
  totalCount: Scalars['Int']['output'];
};

/** Custom edge type for OutreachBatchPatientType */
export type OutreachBatchPatientEdge = {
  __typename?: 'OutreachBatchPatientEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OutreachBatchPatient>;
};

/** Enum for OutreachBatchPatientType statuses */
export enum OutreachBatchPatientStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
  Rejected = 'rejected'
}

export type OutreachManagerAppointmentTypeGroup = {
  __typename?: 'OutreachManagerAppointmentTypeGroup';
  groupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** The connection type for OutreachManagerAppointmentTypeGroup. */
export type OutreachManagerAppointmentTypeGroupConnection = {
  __typename?: 'OutreachManagerAppointmentTypeGroupConnection';
  /** A list of edges. */
  edges: Array<OutreachManagerAppointmentTypeGroupEdge>;
  /** A list of nodes. */
  nodes: Array<OutreachManagerAppointmentTypeGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type OutreachManagerAppointmentTypeGroupEdge = {
  __typename?: 'OutreachManagerAppointmentTypeGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OutreachManagerAppointmentTypeGroup>;
};

/** Interface Outreach Manager Batch */
export type OutreachManagerBatch = {
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for this record. */
  id: Scalars['ID']['output'];
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime']['output'];
};


/** Interface Outreach Manager Batch */
export type OutreachManagerBatchOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

/** Custom connection type for OutreachManagerBatchInterfaceType */
export type OutreachManagerBatchInterfaceConnection = {
  __typename?: 'OutreachManagerBatchInterfaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OutreachManagerBatchInterfaceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OutreachManagerBatch>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total count of queried batches */
  totalCount: Scalars['Int']['output'];
};

/** Custom edge type for OutreachManagerBatchInterfaceType */
export type OutreachManagerBatchInterfaceEdge = {
  __typename?: 'OutreachManagerBatchInterfaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<OutreachManagerBatch>;
};

/** Enum for OutreachManagerBatch batch order types */
export enum OutreachManagerBatchOrderType {
  LabOrderOutreach = 'LabOrderOutreach',
  SurveyOrderOutreach = 'SurveyOrderOutreach',
  UserEngagementMessageOutreach = 'UserEngagementMessageOutreach',
  VisitFollowUpOrderOutreach = 'VisitFollowUpOrderOutreach'
}

export enum OutreachManagerStatus {
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
  Queued = 'queued'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Panel = {
  __typename?: 'Panel';
  id: Scalars['ID']['output'];
  officeId: Scalars['ID']['output'];
  preferredBooking: Scalars['Boolean']['output'];
  state: Scalars['String']['output'];
};

export enum ParamOptions {
  Amount = 'amount'
}

export type Patient = AppointmentActor & MessageRecipient & MessageSender & {
  __typename?: 'Patient';
  activeAllergies: Array<PatientAllergy>;
  activeInsurances: Array<Insurance>;
  activeMedication?: Maybe<PatientMedication>;
  activeMedications: Array<PatientMedication>;
  /** @deprecated Please use the camelCase version. */
  active_insurances: Array<Insurance>;
  adminComments?: Maybe<Scalars['HTML']['output']>;
  ageInYears?: Maybe<Scalars['Int']['output']>;
  /** If the patient has not visited their home office in the last 18 months, suggest the most visited office during that period */
  alternateHomeOfficeSuggestion?: Maybe<Office>;
  appointments: AppointmentConnection;
  /** List of patient insurances that have been archived  */
  archivedInsurances: Array<Insurance>;
  avatarFileName?: Maybe<Scalars['String']['output']>;
  billingSyncInProgress: Scalars['Boolean']['output'];
  billingSyncable: Scalars['Boolean']['output'];
  birthdayAlert?: Maybe<Scalars['String']['output']>;
  /** A list of appointment types bookable by the patient */
  bookableAppointmentTypes?: Maybe<Array<AppointmentType>>;
  /** Returns true if the current user can manage access relationships with this patient */
  canManageAccess: Scalars['Boolean']['output'];
  /** Whether current user has permission to create a new family account. */
  canManageFamily: Scalars['Boolean']['output'];
  careTeam: CareTeam;
  chartUrl?: Maybe<Scalars['String']['output']>;
  completedAmazonFlowStatus?: Maybe<Scalars['String']['output']>;
  connectedDevicesAuthorized: Scalars['Boolean']['output'];
  /** How many conversations this patient has had with a given provider */
  conversationCountWith?: Maybe<Scalars['Int']['output']>;
  currentVoluntaryAlignment?: Maybe<VoluntaryAlignmentOffer>;
  deceasedOn?: Maybe<Scalars['Date']['output']>;
  /** Patient default copay Stripe Payment Method details */
  defaultCopayMethod?: Maybe<PaymentMethod>;
  defaultPdmpReportState?: Maybe<Scalars['String']['output']>;
  detailedEthnicities: Array<Ethnicity>;
  displayGender?: Maybe<Scalars['String']['output']>;
  /**
   * Default format: '<preferred-name> <last-name>'. Follows the convention to use
   * the patient's preferred name over their first name when possible. Can be
   * customized by passing in options.
   */
  displayName: Scalars['String']['output'];
  dob?: Maybe<Scalars['String']['output']>;
  documents: DocumentConnection;
  electronicHealthInformationExport?: Maybe<ElectronicHealthInformationExport>;
  electronicHealthInformationExports: ElectronicHealthInformationExportConnection;
  /** The patient's email address. Returns null if the requestor does not have permission to view this patient's email address. */
  email?: Maybe<Scalars['String']['output']>;
  emailVerified: EmailVerificationStatus;
  /** A list of the patient's emails */
  emails: Array<Email>;
  /**
   * The patient's past, canceled, and no show appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  endedAndNotUtilizedAppointments: AppointmentConnection;
  ethnicity?: Maybe<Ethnicity>;
  /** The patient data in FHIR */
  fhir: Scalars['JSON']['output'];
  /** The patient's FHIR identifier */
  fhirIdentifier: Scalars['String']['output'];
  finishedTodos?: Maybe<PrioritizedTasks>;
  firstName: Scalars['String']['output'];
  formerFirstName?: Maybe<Scalars['String']['output']>;
  formerLastName?: Maybe<Scalars['String']['output']>;
  genderDetails?: Maybe<Scalars['String']['output']>;
  globalId: Scalars['String']['output'];
  hasAmazonPrimeTrial: Scalars['Boolean']['output'];
  hasBeenSeen: Scalars['Boolean']['output'];
  hasIncompleteServiceArea: Scalars['Boolean']['output'];
  hasUnsignedLegalDocuments: Scalars['Boolean']['output'];
  healthkitAuthorized: Scalars['Boolean']['output'];
  historyVaccines: Array<HistoryVaccine>;
  homeOffice?: Maybe<Office>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isAdult: Scalars['Boolean']['output'];
  isChartUnclaimed: Scalars['Boolean']['output'];
  isDeceased?: Maybe<Scalars['Boolean']['output']>;
  isLwaRegUser: Scalars['Boolean']['output'];
  isPediatric: Scalars['Boolean']['output'];
  isRegComplete: Scalars['Boolean']['output'];
  isSeniorHealthPatient: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  /**
   * The licensing body that this patient belongs to. This is effectively a
   * StateType, though non-states like DC and Puerto Rico are included.
   * If null, it means the patient is part of the virtual service area, and has no usable address.
   *
   * If given a latitude and longitude, the licensing body is looked up based on
   * that location. If reverse-geocoding fails, then we fall back on the above behavior.
   */
  licensingBody?: Maybe<State>;
  manualHieQueryAvailability: ManualHieQueryAvailability;
  manualHieQueryStatus: ManualHieQueryStatus;
  memberSinceAt: Scalars['DateTime']['output'];
  membership: Membership;
  /** @deprecated Use patient.multiFactorAuthentication.enabled instead */
  mfaEnabled: Scalars['Boolean']['output'];
  /** @deprecated Use patient.multiFactorAuthentication.exceptionUntil instead */
  mfaExceptionUntil?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  mobileDirectSignupEligible: Scalars['Boolean']['output'];
  mostRecentFitbitMeasurement?: Maybe<Measurement>;
  mostRecentHealthkitMeasurement?: Maybe<Measurement>;
  multiFactorAuthentication: MultiFactorAuthentication;
  /**
   * Returns offices located near the patient.
   * Providing geolocation argument values will return a list of offices in the search radius with distance data.
   * Providing offices_in_state will return all offices in the state with distance data.
   * Providing zip_code will return a list of offices in the search radius with approximate distance data.
   */
  nearbyOffices: PatientOfficeConnection;
  nickname?: Maybe<Scalars['String']['output']>;
  notes: NoteConnection;
  /**
   * The patient's past and current appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  pastAndCurrentAppointments: AppointmentConnection;
  /** A list of the patient's pharmacies, with preferred first then ordered by use count descending. */
  patientPharmacies: Array<PatientPharmacy>;
  /** AKA Action Items */
  patientTasks: PatientTasksConnection;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  /** @deprecated Use `care_team.pcp` instead. */
  pcp?: Maybe<Provider>;
  pendingAdmissions: Array<Admission>;
  /**
   * A list of the patient's pharmacies
   * @deprecated This actually returns PatientPharmacies, not Pharmacies; please use the patientPharmacies field instead.
   */
  pharmacies: Array<PatientPharmacy>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumbers: Array<PatientPhoneNumber>;
  /** Returns non-draft posts, unless IDs are provided. Patient clients will only receive posts marked as patient-visible. */
  posts: TimelinePostConnection;
  preferences?: Maybe<PatientPreferences>;
  /** patient's preferred email. In a few cases this can be null */
  preferredEmail?: Maybe<Email>;
  preferredLanguage?: Maybe<Language>;
  preferredName: Scalars['String']['output'];
  preferredPharmacy?: Maybe<PatientPharmacy>;
  primaryAddress?: Maybe<Address>;
  primaryInsurance: Insurance;
  /** Returns true if user has access disabled */
  privateAccount: Scalars['Boolean']['output'];
  problems?: Maybe<ProblemConnection>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  profileInfo: PatientProfileInfo;
  /** @deprecated Please use the camelCase version. */
  profile_info: PatientProfileInfo;
  /** Returns a list of programs available to a patient based on their available appointment types and problem type. */
  programSuggestions: ProgramSuggestionConnection;
  programs: PatientProgramConnection;
  pronounGroup: Scalars['String']['output'];
  /** This returns the patient's 'pronoun_id', i.e. a single word representing the pronoun group */
  pronouns: Scalars['String']['output'];
  /** Providers seen by the patient and when they last were seen. Not to be used as a proxy for care team */
  providersSeen?: Maybe<PatientProviderConnection>;
  races?: Maybe<Array<Race>>;
  racesAndEthnicities: Array<Race>;
  /** Most recent 5 admissions sorted by admitted_at desc */
  recentAdmissions: Array<Admission>;
  referral?: Maybe<Referral>;
  /** Useful for autocomplete features to show selected patient from id. These same features tend to use Query.patientSearch */
  searchResult: PatientSearchResult;
  seniorHealthPatientData?: Maybe<SeniorHealthPatientData>;
  serviceArea?: Maybe<ServiceArea>;
  sex: Scalars['String']['output'];
  signupIncomplete: Scalars['Boolean']['output'];
  stripeCustomerId?: Maybe<Scalars['ID']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  /** The patient's timezone */
  timezoneTzinfo: Scalars['TZInfo']['output'];
  unreadMessagesCount: Scalars['Int']['output'];
  /**
   * A list of the patient's upcoming and current appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAndCurrentAppointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAppointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments by appointment type
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAppointmentsByType: AppointmentConnection;
  /**
   * A list of the patient's upcoming appointments that are suitable for a discussion of connected device data
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcomingAppointmentsForConnectedDeviceDiscussion: AppointmentConnection;
  /**
   * A list of the patient's upcoming appointments
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcoming_appointments: Array<Appointment>;
  /**
   * A list of the patient's upcoming appointments by appointment type
   * @deprecated This field has been deprecated in favor of Patient.appointments. With Patient.appointments you can specify a strategy and order params that will meet your needs. If your needs aren't supported, please ask in #pd-guild-graphql for assistance.
   */
  upcoming_appointments_by_type: AppointmentConnection;
  /** How many visits this patient has had with a given provider */
  visitCountWith?: Maybe<Scalars['Int']['output']>;
  visitedServiceAreas?: Maybe<Array<ServiceArea>>;
  vitals: Vitals;
  /** Returns semantic name of patient integer worry score */
  worryScore: WorryScore;
};


export type PatientActiveMedicationArgs = {
  id: Scalars['ID']['input'];
};


export type PatientAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeFauxBilling?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<SortOrder>;
  strategies?: InputMaybe<Array<AppointmentQueryStrategy>>;
};


export type PatientBookableAppointmentTypesArgs = {
  appointmentCategory?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  serviceAreaId?: InputMaybe<Scalars['ID']['input']>;
  topicName?: InputMaybe<Scalars['String']['input']>;
};


export type PatientCareTeamArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<CareTeamType>;
};


export type PatientConversationCountWithArgs = {
  userId: Scalars['ID']['input'];
};


export type PatientDisplayNameArgs = {
  options?: InputMaybe<PatientDisplayNameOptions>;
};


export type PatientDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DocumentSort>;
};


export type PatientElectronicHealthInformationExportArgs = {
  format: ElectronicHealthInformationExportFormat;
  id: Scalars['ID']['input'];
};


export type PatientElectronicHealthInformationExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  format?: InputMaybe<ElectronicHealthInformationExportFormat>;
  last?: InputMaybe<Scalars['Int']['input']>;
  states?: InputMaybe<Array<ElectronicHealthInformationExportState>>;
};


export type PatientEndedAndNotUtilizedAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientFinishedTodosArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientHasBeenSeenArgs = {
  remotely?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PatientLicensingBodyArgs = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};


export type PatientManualHieQueryStatusArgs = {
  transactionId: Scalars['String']['input'];
};


export type PatientNearbyOfficesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  geolocation?: InputMaybe<Geolocation>;
  last?: InputMaybe<Scalars['Int']['input']>;
  officesInState?: InputMaybe<Scalars['String']['input']>;
  searchRadiusInMiles?: InputMaybe<Scalars['Int']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type PatientNotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  noteTypes?: InputMaybe<Array<NoteTypeSystemName>>;
};


export type PatientPastAndCurrentAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientPatientTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderTypes?: InputMaybe<Array<PatientTaskOrderType>>;
  sortBy?: InputMaybe<PatientTaskSort>;
  states?: InputMaybe<Array<PatientTaskState>>;
};


export type PatientPostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TimelinePostSort>;
};


export type PatientProblemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status: ProblemStatus;
};


export type PatientProgramSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  problemTypeId: Scalars['ID']['input'];
};


export type PatientProgramsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  systemName?: InputMaybe<ProgramSystemName>;
};


export type PatientProvidersSeenArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientUpcomingAppointmentsByTypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentTypeIds: Array<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientUpcomingAppointmentsForConnectedDeviceDiscussionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientUpcoming_Appointments_By_TypeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentTypeIds: Array<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientVisitCountWithArgs = {
  includeVideo?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of PatientAddMfaException */
export type PatientAddMfaExceptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mfaExceptionData: MfaExceptionData;
};

/** Autogenerated return type of PatientAddMfaException. */
export type PatientAddMfaExceptionPayload = {
  __typename?: 'PatientAddMfaExceptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

export type PatientAddressInputObject = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  destroy?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type PatientAllergy = {
  __typename?: 'PatientAllergy';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  reaction?: Maybe<Scalars['String']['output']>;
};

export type PatientCharge = {
  __typename?: 'PatientCharge';
  amountOwedCents: Scalars['Int']['output'];
  amountPaidCents: Scalars['Int']['output'];
  chargeAmount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  status: PatientChargeStatus;
};

export type PatientChargePayment = {
  __typename?: 'PatientChargePayment';
  amountPaidTowards: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  paymentType: Scalars['String']['output'];
};

export enum PatientChargeStatus {
  Paid = 'paid',
  Processing = 'processing',
  Refunded = 'refunded',
  Unpaid = 'unpaid',
  Waived = 'waived'
}

export type PatientDemographics = {
  deceasedOn?: InputMaybe<Scalars['Date']['input']>;
  /** an array of codes representing ethnicities */
  detailedEthnicities?: InputMaybe<Array<Scalars['String']['input']>>;
  dob?: InputMaybe<Scalars['Date']['input']>;
  /** snomed code of the high-level ethnicity category */
  ethnicity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formerFirstName?: InputMaybe<Scalars['String']['input']>;
  formerLastName?: InputMaybe<Scalars['String']['input']>;
  /** a free text string providing more information about the patient's gender */
  genderDetails?: InputMaybe<Scalars['String']['input']>;
  /** the snomed code of the gender identity */
  genderIdentity?: InputMaybe<Scalars['String']['input']>;
  isDeceased?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  /** the id of the preferred language */
  preferredLanguage?: InputMaybe<Scalars['ID']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  /** the string description of the pronoun set used i.e. 'They/Them' */
  pronounGroup?: InputMaybe<Scalars['String']['input']>;
  /** the pronoun id of the pronoun set used i.e. 'they' */
  pronouns?: InputMaybe<Scalars['String']['input']>;
  /** an array of codes representing races */
  races?: InputMaybe<Array<Scalars['String']['input']>>;
  /** an array of codes representing either a race or detailed ethnicity */
  racesAndEthnicities?: InputMaybe<Array<Scalars['String']['input']>>;
  sex?: InputMaybe<Scalars['Boolean']['input']>;
  sexAssignedAtBirth?: InputMaybe<Scalars['String']['input']>;
  /** the snomed code of the sexual orientation */
  sexualOrientation?: InputMaybe<Scalars['String']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of PatientDisableMfa */
export type PatientDisableMfaInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mfaDisableData: MfaDisableData;
};

/** Autogenerated return type of PatientDisableMfa. */
export type PatientDisableMfaPayload = {
  __typename?: 'PatientDisableMfaPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

export type PatientDisplayNameOptions = {
  /** Makes the last name appear first (Lastnamerson, Firsty) */
  lastFirst?: InputMaybe<Scalars['Boolean']['input']>;
  middleName?: InputMaybe<Scalars['Boolean']['input']>;
  preferredName?: InputMaybe<Scalars['Boolean']['input']>;
  suffix?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PatientEmailInput = {
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  kind: EmailKind;
};

export type PatientMedication = ProblemAttachableInterface & {
  __typename?: 'PatientMedication';
  genericSynonym?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isGeneric?: Maybe<Scalars['Boolean']['output']>;
  isOutOfDate: Scalars['Boolean']['output'];
  isRenewable: Scalars['Boolean']['output'];
  latestPatientMedicationRegimen?: Maybe<PatientMedicationRegimen>;
  name: Scalars['String']['output'];
  pendingRenewalRequest?: Maybe<RenewalRequest>;
  regimenDescription?: Maybe<Scalars['String']['output']>;
  /** Can be 'pending' or 'not_pending' */
  renewalStatus: Scalars['String']['output'];
  routeIsActive: Scalars['Boolean']['output'];
  status: PatientMedicationStatusEnum;
};

export type PatientMedicationRegimen = {
  __typename?: 'PatientMedicationRegimen';
  id: Scalars['ID']['output'];
  layInstructionsForUse?: Maybe<Scalars['String']['output']>;
  medicationDispensable?: Maybe<MedicationDispensable>;
  patientMedication?: Maybe<PatientMedication>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum PatientMedicationStatusEnum {
  Active = 'active',
  Deleted = 'deleted',
  Discontinued = 'discontinued',
  Pending = 'pending'
}

/** The connection type for Office. */
export type PatientOfficeConnection = {
  __typename?: 'PatientOfficeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientOfficeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Office>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Extra metadata modeling the general relationship between patients and offices. */
export type PatientOfficeEdge = {
  __typename?: 'PatientOfficeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** Distance between the patient and the office. */
  distanceInMiles: Scalars['Float']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Office>;
};

export type PatientPharmacy = {
  __typename?: 'PatientPharmacy';
  customerCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPreferred: Scalars['Boolean']['output'];
  /** @deprecated Please use lastUsedAtDate instead; this field is mistakenly string-type. */
  lastUsedAt?: Maybe<Scalars['String']['output']>;
  lastUsedAtDate?: Maybe<Scalars['DateTime']['output']>;
  patientId: Scalars['Int']['output'];
  pharmacy: Pharmacy;
  pharmacyId: Scalars['Int']['output'];
  useCount: Scalars['Int']['output'];
};

export type PatientPhoneNumber = {
  __typename?: 'PatientPhoneNumber';
  ext?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPreferred: Scalars['Boolean']['output'];
  kind: PatientPhoneNumberKind;
  number: Scalars['String']['output'];
  numberFormatted: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PatientPhoneNumberInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  kind: PhoneNumberKind;
  number: Scalars['String']['input'];
};

export type PatientPhoneNumberInputObject = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<PatientPhoneNumberKind>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export enum PatientPhoneNumberKind {
  Home = 'home',
  Mobile = 'mobile',
  Other = 'other',
  Work = 'work'
}

export type PatientPreferences = {
  __typename?: 'PatientPreferences';
  acceptsClinicalEmails: Scalars['Boolean']['output'];
  acceptsOnlineBills: Scalars['Boolean']['output'];
  acceptsPatientTaskEmails?: Maybe<Scalars['Boolean']['output']>;
  acceptsPatientTaskPush?: Maybe<Scalars['Boolean']['output']>;
  aiAssistantEnabled: Scalars['Boolean']['output'];
  avsHideWeight: Scalars['Boolean']['output'];
  chartHidden: Scalars['Boolean']['output'];
  connectedDevicesOptIn?: Maybe<Scalars['Boolean']['output']>;
  fullTextNotifications?: Maybe<Scalars['Boolean']['output']>;
  healthInformationExchangeLegalTextVersion?: Maybe<Scalars['Int']['output']>;
  healthInformationExchangeOptIn?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  loginWithAmazon: LoginWithAmazon;
  marketingEmailOptOut?: Maybe<Scalars['Boolean']['output']>;
  needsLanguageInterpreter?: Maybe<Scalars['Boolean']['output']>;
};

export type PatientProfileInfo = {
  __typename?: 'PatientProfileInfo';
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  addresses: Array<Address>;
  /** @deprecated Please use this field on the Patient type. */
  ageInYears?: Maybe<Scalars['Int']['output']>;
  allPhoneNumberKinds: Array<PatientPhoneNumberKind>;
  allPronounGroups: Array<Scalars['String']['output']>;
  allRelationshipTypes: Array<Scalars['String']['output']>;
  allSexes: Array<Scalars['String']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  deceasedOn?: Maybe<Scalars['Date']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  detailedEthnicities: Array<Ethnicity>;
  /** @deprecated the word 'display' is redundant. Use pronounGroup instead. Any other representations of the           pronouns are only a backend concern. */
  displayPronouns: Scalars['String']['output'];
  /** @deprecated Please use this field on the Patient type. */
  dob?: Maybe<Scalars['String']['output']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  emergencyContacts?: Maybe<Array<EmergencyContact>>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  employerName?: Maybe<Scalars['String']['output']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  employerZipCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  ethnicity?: Maybe<Ethnicity>;
  /** @deprecated Please use this field on the Patient type. */
  firstName: Scalars['String']['output'];
  /** @deprecated Please use this field on the Patient type. */
  formerFirstName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  formerLastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  genderDetails?: Maybe<Scalars['String']['output']>;
  genderIdentity?: Maybe<GenderIdentity>;
  id: Scalars['ID']['output'];
  insuranceVerifiedWithinPastYear: Scalars['Boolean']['output'];
  insurancesManuallyVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  isDeceased?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  isPregnant: Scalars['Boolean']['output'];
  /**
   * Whether the patient is an unborn child, can be null if the child_characteristic association doesn't exist
   * @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it!
   */
  isUnborn?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  languages?: Maybe<Array<Language>>;
  /** @deprecated Please use this field on the Patient type. */
  lastName: Scalars['String']['output'];
  /** @deprecated Please use this field on the Patient type. */
  middleName?: Maybe<Scalars['String']['output']>;
  onlyHasSelfPay: Scalars['Boolean']['output'];
  /** @deprecated Please use this field on the Patient type. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  phoneNumbers: Array<PatientPhoneNumber>;
  /** @deprecated Please use this field on the Patient type. */
  preferredLanguage?: Maybe<Language>;
  /** @deprecated Please use this field on the Patient type. */
  preferredName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use this field on the Patient type. */
  primaryAddress?: Maybe<Address>;
  /** @deprecated Please use this field on the Patient type. */
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  pronounGroup: Scalars['String']['output'];
  /** @deprecated Please use this field on the Patient type. */
  races?: Maybe<Array<Race>>;
  /** @deprecated We are working to move this field to the patient type. However, it may or may not yet be migrated. If it exists on patient, please use it there. If not, consider migrating it! */
  sex: Scalars['String']['output'];
  sexAssignedAtBirth?: Maybe<Scalars['String']['output']>;
  sexualOrientation?: Maybe<SexualOrientation>;
  /** @deprecated Please use this field on the Patient type. */
  suffix?: Maybe<Scalars['String']['output']>;
};

/** The connection type for Program. */
export type PatientProgramConnection = {
  __typename?: 'PatientProgramConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientProgramEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Program>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Describes the relationship between patients and programs */
export type PatientProgramEdge = {
  __typename?: 'PatientProgramEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Program>;
  programEnrollment: ProgramEnrollment;
};

/** The connection type for Provider. */
export type PatientProviderConnection = {
  __typename?: 'PatientProviderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientProviderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Provider>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Extra metadata modeling the general relationship between patients and providers. */
export type PatientProviderEdge = {
  __typename?: 'PatientProviderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The last time the patient was seen by the provider */
  lastSeenAt: Scalars['DateTime']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Provider>;
};

export type PatientProviderInput = {
  patientId: Scalars['ID']['input'];
  /** The Internal User ID that will be used to sign the Lab Order. */
  providerId: Scalars['ID']['input'];
};

export type PatientSearchResult = SearchResult & {
  __typename?: 'PatientSearchResult';
  dob?: Maybe<Scalars['Date']['output']>;
  emails: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  node: Patient;
  phones: Array<Scalars['String']['output']>;
};

/** Enum for PatientTaskType order types */
export enum PatientTaskOrderType {
  BasicFollowUpOrder = 'BasicFollowUpOrder',
  ConnectedDeviceOrder = 'ConnectedDeviceOrder',
  ConsultOrder = 'ConsultOrder',
  GenericFollowUpOrder = 'GenericFollowUpOrder',
  LabOrder = 'LabOrder',
  ProcedureOrder = 'ProcedureOrder',
  SurveyOrder = 'SurveyOrder',
  VaccineOrder = 'VaccineOrder',
  VisitFollowUpOrder = 'VisitFollowUpOrder'
}

export enum PatientTaskSort {
  /** Sorts patient tasks by updated_at date in descending order. */
  UpdatedAtDesc = 'UpdatedAtDesc'
}

/** Enum for PatientTaskType states */
export enum PatientTaskState {
  Assigned = 'assigned',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Declined = 'declined',
  Expired = 'expired'
}

/** The connection type for PatientTasks. */
export type PatientTasksConnection = {
  __typename?: 'PatientTasksConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PatientTasksEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PatientTasksEdge = {
  __typename?: 'PatientTasksEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
};

/** Autogenerated input type of PauseScribeStream */
export type PauseScribeStreamInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of PauseScribeStream. */
export type PauseScribeStreamPayload = {
  __typename?: 'PauseScribeStreamPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billingName?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  cardNickname?: Maybe<Scalars['String']['output']>;
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  /** Authorized to auto-charge for copay amount. */
  isDefaultCopay: Scalars['Boolean']['output'];
  /** Patient's preferred payment method. */
  isPreferredCopay: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
};

export type PcpAvailability = {
  __typename?: 'PcpAvailability';
  count: Scalars['Int']['output'];
  inventories: Array<AppointmentInventory>;
  proximalAvailableDates: Array<Scalars['Date']['output']>;
};

export type PdmpNonParticipatingState = {
  __typename?: 'PdmpNonParticipatingState';
  id: Scalars['ID']['output'];
  shortName: Scalars['String']['output'];
};

export type PdmpReportEvent = {
  __typename?: 'PdmpReportEvent';
  correlationId?: Maybe<Scalars['ID']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  requestedLocation?: Maybe<Scalars['String']['output']>;
  viewedAt?: Maybe<Scalars['DateTime']['output']>;
  viewedBy?: Maybe<Provider>;
};

export type Pharmacy = {
  __typename?: 'Pharmacy';
  address?: Maybe<Address>;
  /** Markdown-encoded */
  details?: Maybe<Scalars['Markdown']['output']>;
  dispensabilityAttributes?: Maybe<PharmacyMedicationAttributesResponse>;
  displayAddressStreet?: Maybe<Scalars['String']['output']>;
  /** Pharmacy brand name if it exists; otherwise, the pharmacy name title-ized */
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAmazonPharmacy: Scalars['Boolean']['output'];
  isMailOrder: Scalars['Boolean']['output'];
  mobileIconUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  workPhoneNumber?: Maybe<Scalars['String']['output']>;
};


export type PharmacyDispensabilityAttributesArgs = {
  pendingNewRxIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
  style?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The connection type for Pharmacy. */
export type PharmacyConnection = {
  __typename?: 'PharmacyConnection';
  /** A list of edges. */
  edges: Array<PharmacyEdge>;
  /** A list of nodes. */
  nodes: Array<Pharmacy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PharmacyEdge = {
  __typename?: 'PharmacyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Pharmacy>;
};

export type PharmacyMedicationAttributes = {
  __typename?: 'PharmacyMedicationAttributes';
  afterCouponPrice?: Maybe<Scalars['String']['output']>;
  availabilityStatus?: Maybe<PharmacyMedicationAvailabilityStatusEnum>;
  avgInsurancePrice?: Maybe<Scalars['String']['output']>;
  cashPrices?: Maybe<Array<CashPrice>>;
  deliveryEstimate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  medLevelDeliveryPromise?: Maybe<MedLevelDeliveryPromise>;
  packageDetails?: Maybe<Scalars['String']['output']>;
  primePrice?: Maybe<Scalars['String']['output']>;
  retailPrice?: Maybe<Scalars['String']['output']>;
  rxPassEligible?: Maybe<Scalars['Boolean']['output']>;
  totalDaySupply?: Maybe<Scalars['Int']['output']>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type PharmacyMedicationAttributesResponse = {
  __typename?: 'PharmacyMedicationAttributesResponse';
  anyAreRxPassEligible?: Maybe<Scalars['Boolean']['output']>;
  attributes?: Maybe<Array<PharmacyMedicationAttributes>>;
  deliveryPromise?: Maybe<PharmacyMedicationDeliveryPromise>;
};

export enum PharmacyMedicationAvailabilityStatusEnum {
  Available = 'available',
  CurrentlyUnavailable = 'currently_unavailable',
  DoesNotFill = 'does_not_fill',
  Unavailable = 'unavailable',
  Unknown = 'unknown'
}

export type PharmacyMedicationDeliveryPromise = {
  __typename?: 'PharmacyMedicationDeliveryPromise';
  confidence?: Maybe<PharmacyMedicationDeliveryPromiseConfidenceEnum>;
  dateRange?: Maybe<PharmacyMedicationDeliveryPromiseDateRange>;
  deliveryPromiseVariation?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  mixedDeliverySpeeds?: Maybe<Scalars['Boolean']['output']>;
  patientMessages?: Maybe<Array<PharmacyMedicationDeliveryPromisePatientMessage>>;
  zip?: Maybe<Scalars['String']['output']>;
};

export enum PharmacyMedicationDeliveryPromiseConfidenceEnum {
  High = 'high',
  Low = 'low'
}

export type PharmacyMedicationDeliveryPromiseDateRange = {
  __typename?: 'PharmacyMedicationDeliveryPromiseDateRange';
  end?: Maybe<Scalars['DateTime']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type PharmacyMedicationDeliveryPromisePatientMessage = {
  __typename?: 'PharmacyMedicationDeliveryPromisePatientMessage';
  promiseMarkdown?: Maybe<Scalars['String']['output']>;
  style?: Maybe<PharmacyMedicationDeliveryPromiseStyleEnum>;
};

export enum PharmacyMedicationDeliveryPromiseStyleEnum {
  Banner = 'BANNER',
  PharmacySearch = 'PHARMACY_SEARCH'
}

export type PhlebotomyVisitAggregations = {
  __typename?: 'PhlebotomyVisitAggregations';
  firstScheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  firstSeenStartAt?: Maybe<Scalars['DateTime']['output']>;
  lastScheduledStartAt?: Maybe<Scalars['DateTime']['output']>;
  lastSeenStartAt?: Maybe<Scalars['DateTime']['output']>;
  numberSeen: Scalars['Int']['output'];
  numberUpcoming: Scalars['Int']['output'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  ext?: Maybe<Scalars['String']['output']>;
  formattedNumber: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPreferred?: Maybe<Scalars['Boolean']['output']>;
  kind: PhoneNumberKind;
  number: Scalars['String']['output'];
};

export type PhoneNumberAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  ext?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  kind?: InputMaybe<PhoneNumberKind>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export enum PhoneNumberKind {
  BackOffice = 'back_office',
  Fax = 'fax',
  Home = 'home',
  Mobile = 'mobile',
  Other = 'other',
  Pager = 'pager',
  PatientAssistance = 'patient_assistance',
  Work = 'work'
}

/** Interface for different plans */
export type Plan = {
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Practitioner = {
  __typename?: 'Practitioner';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  roleName: Scalars['String']['output'];
};

/** Enum for preferred roles */
export enum PreferredRole {
  Admin = 'ADMIN',
  Clubhouse = 'CLUBHOUSE',
  Phlebotomist = 'PHLEBOTOMIST',
  Provider = 'PROVIDER'
}

export type PrescribingCredential = {
  __typename?: 'PrescribingCredential';
  deaLicenseNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  licenseNumber: Scalars['String']['output'];
  serviceArea?: Maybe<ServiceArea>;
  stateOfIssue?: Maybe<State>;
};

/**
 * We filter location data differently among providers and Ops roles. We care about
 * licensing for providers, which is reflected in LicensingBodyType. For Ops roles,
 * locations info will be reflected as ServiceAreaType.
 */
export type PrioritizedTaskLocation = LicensingBody | ServiceArea;

export type PrioritizedTasks = {
  __typename?: 'PrioritizedTasks';
  /**
   * These are raw aggregations results from the Elasticsearch query. WARNING: The
   * structure of this field is subject to change with the change of the
   * Elasticsearch index. A typed `aggregations`field should be added if more
   * stability is desired.
   */
  aggregationsRaw?: Maybe<Scalars['JSON']['output']>;
  /**
   * These are raw hits results from the Elasticsearch query. WARNING: The
   * structure of this field is subject to change with the change of the
   * Elasticsearch index. A typed `hits`field should be added if more stability is desired.
   */
  hitsRaw?: Maybe<Array<Scalars['JSON']['output']>>;
  tasks?: Maybe<Array<Task>>;
  totalHits: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum PrioritizedTasksBucket {
  /** All tasks for the current user or passed in user */
  All = 'all',
  /** All tasks assigned to the specified user */
  AssignedTo = 'assigned_to',
  /** Completed tasks from the past 7 days for the current user or passed in user */
  Finished = 'finished',
  /** Tasks that are assigned to me */
  Mine = 'mine',
  /** Tasks assigned to me including waiting or deferred tasks */
  MineWaiting = 'mine_waiting',
  /** All tasks with notifications and assigned to the current user */
  Notifications = 'notifications',
  /** Tasks that are in my panel */
  Panel = 'panel',
  /** All unfinished tasks for the specified patient */
  Patient = 'patient',
  /** All tasks, for the current user or passed in user, filtered by specified patient officeIds */
  PatientOffices = 'patient_offices',
  /** All tasks that are part of a task filter */
  TaskFilter = 'task_filter',
  /** Waiting or deferred tasks that are assigned to me */
  Waiting = 'waiting'
}

export enum Priority {
  High = 'high',
  Normal = 'normal'
}

export type Problem = {
  __typename?: 'Problem';
  aAndP?: Maybe<Scalars['String']['output']>;
  attachments: ProblemAttachmentConnection;
  createdAt: Scalars['DateTime']['output'];
  createdBy: InternalUser;
  icd10Codes: Array<ProblemCode>;
  id: Scalars['ID']['output'];
  layDescription: Scalars['String']['output'];
  problemType: ProblemType;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<InternalUser>;
  status: ProblemStatus;
};


export type ProblemAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProblemAttachableInterface = {
  id: Scalars['ID']['output'];
};

export enum ProblemAttachmentAttachmentEnum {
  Document = 'Document',
  PatientMedication = 'PatientMedication'
}

/** The connection type for ProblemAttachableInterface. */
export type ProblemAttachmentConnection = {
  __typename?: 'ProblemAttachmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProblemAttachmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProblemAttachableInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Describes the relationship between problems and attachments */
export type ProblemAttachmentEdge = {
  __typename?: 'ProblemAttachmentEdge';
  createdAt: Scalars['DateTime']['output'];
  createdBy: InternalUser;
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProblemAttachableInterface>;
};

export type ProblemCode = {
  __typename?: 'ProblemCode';
  code: Scalars['String']['output'];
  detailDescription?: Maybe<Scalars['String']['output']>;
  extensions: Array<ProblemCodeExtension>;
  generalDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProblemCodeExtension = {
  __typename?: 'ProblemCodeExtension';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProblemCodeLocation = {
  __typename?: 'ProblemCodeLocation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** The connection type for Problem. */
export type ProblemConnection = {
  __typename?: 'ProblemConnection';
  /** A list of edges. */
  edges: Array<ProblemEdge>;
  /** A list of nodes. */
  nodes: Array<Problem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProblemEdge = {
  __typename?: 'ProblemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Problem>;
};

export type ProblemHistory = {
  __typename?: 'ProblemHistory';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  problemCodeLocation?: Maybe<ProblemCodeLocation>;
  problemId: Scalars['Int']['output'];
  problemType?: Maybe<ProblemType>;
};

export enum ProblemStatus {
  Current = 'current',
  Mistaken = 'mistaken',
  Rejected = 'rejected',
  Resolved = 'resolved',
  Suspected = 'suspected',
  Unconfirmed = 'unconfirmed'
}

export type ProblemType = {
  __typename?: 'ProblemType';
  clinicalDescription: Scalars['String']['output'];
  commonProcedureTypes?: Maybe<Array<ProcedureType>>;
  id: Scalars['ID']['output'];
};

/** The connection type for ProblemType. */
export type ProblemTypeConnection = {
  __typename?: 'ProblemTypeConnection';
  /** A list of edges. */
  edges: Array<ProblemTypeEdge>;
  /** A list of nodes. */
  nodes: Array<ProblemType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProblemTypeEdge = {
  __typename?: 'ProblemTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProblemType>;
};

export type ProblemTypeResult = SearchResult & {
  __typename?: 'ProblemTypeResult';
  clinicalAbbreviation?: Maybe<Scalars['String']['output']>;
  clinicalDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  layDescription: Scalars['String']['output'];
  node: ProblemType;
};

export type ProcedureCode = {
  __typename?: 'ProcedureCode';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Type for procedure form type object */
export type ProcedureFormType = {
  __typename?: 'ProcedureFormType';
  id: Scalars['ID']['output'];
  requiredOccurrences?: Maybe<OccurrenceOptions>;
  requiredParams?: Maybe<Array<ParamOptions>>;
  systemName?: Maybe<Scalars['String']['output']>;
};

/** Type for procedure interaction object */
export type ProcedureInteraction = Order & {
  __typename?: 'ProcedureInteraction';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<InternalUser>;
  createdAt: Scalars['DateTime']['output'];
  dynamicForm?: Maybe<Scalars['JSON']['output']>;
  formData?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  indications: Array<Maybe<Indication>>;
  measurements?: Maybe<Array<Measurement>>;
  orderedBy?: Maybe<InternalUser>;
  patient: Patient;
  procedureCodes: Array<ProcedureCode>;
  procedureSummary?: Maybe<Scalars['String']['output']>;
  procedureType?: Maybe<ProcedureType>;
  resultDocuments: Array<Document>;
  resultsInterpretation?: Maybe<Scalars['String']['output']>;
  reviewedAt?: Maybe<Scalars['DateTime']['output']>;
  reviewedBy?: Maybe<InternalUser>;
  state: Scalars['String']['output'];
  totalComments?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProcedureMeasurementInputObject = {
  /** The id of the measurement type associated with this measurement. */
  typeId: Scalars['ID']['input'];
  /**
   * The result value of the test. Can be string or number. For example 'non
   * reactive' or 78. Null values will soft delete the measurement.
   */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Type for procedure order object */
export type ProcedureOrder = Order & {
  __typename?: 'ProcedureOrder';
  completedBy?: Maybe<InternalUser>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  measurements?: Maybe<Array<Measurement>>;
  orderedBy?: Maybe<InternalUser>;
  patient: Patient;
  procedureType?: Maybe<ProcedureType>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Source for a procedure */
export type ProcedureOrigin = ProcedureInteraction | ProcedureOrder;

export enum ProcedureOriginType {
  ProcedureInteraction = 'ProcedureInteraction',
  ProcedureOrder = 'ProcedureOrder'
}

export type ProcedureType = {
  __typename?: 'ProcedureType';
  administrationGuidance?: Maybe<Scalars['String']['output']>;
  billableUnitOfMeasure?: Maybe<MedicationUnitOfMeasure>;
  billableUnitOfMeasureId?: Maybe<Scalars['Int']['output']>;
  billingUnitOfMeasureHeading?: Maybe<Scalars['String']['output']>;
  clinicalDescription: Scalars['String']['output'];
  clinicalGuidance?: Maybe<Scalars['String']['output']>;
  diagnosticGroup?: Maybe<DiagnosticGroup>;
  externalOnly?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isBillableAmountRequired: Scalars['Boolean']['output'];
  isDocumentReferenceRequired: Scalars['Boolean']['output'];
  isKidneyFunctionDocRequired: Scalars['Boolean']['output'];
  isLateralityRequired: Scalars['Boolean']['output'];
  isPregnancyDocRequired: Scalars['Boolean']['output'];
  isResultsInterpretationRequired: Scalars['Boolean']['output'];
  layDescription: Scalars['String']['output'];
  maxBillableAmount?: Maybe<Scalars['Float']['output']>;
  measurementTypes: Array<MeasurementType>;
  orderable: Scalars['Boolean']['output'];
  performable: Scalars['Boolean']['output'];
  procedureCodes?: Maybe<Array<Maybe<ProcedureCode>>>;
  procedureFormType?: Maybe<ProcedureFormType>;
};

export type ProcedureTypesSearch = {
  __typename?: 'ProcedureTypesSearch';
  results: Array<ProcedureType>;
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Program = {
  __typename?: 'Program';
  appointmentTypes?: Maybe<Array<AppointmentType>>;
  careTeamSupport: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['Markdown']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  systemName?: Maybe<ProgramSystemName>;
};

export type ProgramCarePlan = {
  __typename?: 'ProgramCarePlan';
  carePlan?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  programEnrollment: ProgramEnrollment;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<InternalUser>;
};

export type ProgramEnrollment = {
  __typename?: 'ProgramEnrollment';
  careTeam: ProgramEnrollmentCareTeam;
  flag: Scalars['Boolean']['output'];
  frequency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  outreachComment?: Maybe<Scalars['String']['output']>;
  preferredTime?: Maybe<Scalars['String']['output']>;
  program: Program;
  programCarePlan?: Maybe<ProgramCarePlan>;
  programEnrollmentPhaseTransitions?: Maybe<Array<ProgramEnrollmentPhaseTransition>>;
  programPhase?: Maybe<ProgramPhase>;
};

export type ProgramEnrollmentCareTeam = {
  __typename?: 'ProgramEnrollmentCareTeam';
  careGivers: Array<CareTeamsCareGiver>;
  id: Scalars['ID']['output'];
  patient: Patient;
  providers: Array<Provider>;
  status: Scalars['String']['output'];
};

export type ProgramEnrollmentPhaseTransition = {
  __typename?: 'ProgramEnrollmentPhaseTransition';
  id: Scalars['ID']['output'];
  programEnrollmentId: Scalars['Int']['output'];
  programPhase?: Maybe<ProgramPhase>;
  transitionedAt?: Maybe<Scalars['DateTime']['output']>;
  transitionedBy?: Maybe<InternalUser>;
};

export type ProgramPhase = {
  __typename?: 'ProgramPhase';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  systemName: Scalars['String']['output'];
};

export type ProgramSuggestion = {
  __typename?: 'ProgramSuggestion';
  allowsOneClickReferrals: Scalars['Boolean']['output'];
  contactId?: Maybe<Scalars['ID']['output']>;
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  externalUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
  messageTemplate?: Maybe<MessageTemplate>;
};


export type ProgramSuggestionMessageTemplateArgs = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** The connection type for ProgramSuggestion. */
export type ProgramSuggestionConnection = {
  __typename?: 'ProgramSuggestionConnection';
  /** A list of edges. */
  edges: Array<ProgramSuggestionEdge>;
  /** A list of nodes. */
  nodes: Array<ProgramSuggestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProgramSuggestionEdge = {
  __typename?: 'ProgramSuggestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProgramSuggestion>;
};

export enum ProgramSystemName {
  HighRiskCareManagement = 'HIGH_RISK_CARE_MANAGEMENT',
  MindsetPlus = 'MINDSET_PLUS'
}

export type ProgramVisit = {
  __typename?: 'ProgramVisit';
  formData?: Maybe<ProgramVisitFormData>;
  id: Scalars['ID']['output'];
  summary?: Maybe<Summary>;
  summaryOfVisit?: Maybe<Scalars['String']['output']>;
};

export type ProgramVisitFormData = {
  __typename?: 'ProgramVisitFormData';
  time?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of PromoteInsurance */
export type PromoteInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of PromoteInsurance. */
export type PromoteInsurancePayload = {
  __typename?: 'PromoteInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Insurance object */
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type Provider = {
  __typename?: 'Provider';
  acceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Please use the camelCase version. */
  accepting_new_patients?: Maybe<Scalars['Boolean']['output']>;
  activeUniquePrescribingCredentials: Array<PrescribingCredential>;
  appointmentInventories?: Maybe<AppointmentInventoryConnection>;
  bio?: Maybe<Scalars['HTML']['output']>;
  bookable: Scalars['Boolean']['output'];
  defaultOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  displayName: Scalars['String']['output'];
  /** @deprecated Please use the camelCase version. */
  display_name: Scalars['String']['output'];
  email: Scalars['String']['output'];
  essentialProceduresNotPerformed: Array<ProcedureType>;
  essentialProceduresPerformed: Array<ProcedureType>;
  expertiseTypes: Array<Expertise>;
  firstName: Scalars['String']['output'];
  /** @deprecated Please use the camelCase version. */
  first_name: Scalars['String']['output'];
  fullImageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  full_image_url?: Maybe<Scalars['String']['output']>;
  gender: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageObjectId?: Maybe<Scalars['ID']['output']>;
  /** @deprecated Please use the camelCase version. */
  image_object_id?: Maybe<Scalars['ID']['output']>;
  isActive: Scalars['Boolean']['output'];
  isPatientBookable: Scalars['Boolean']['output'];
  /** @deprecated Please use the camelCase version. */
  is_active: Scalars['Boolean']['output'];
  /** @deprecated Please use the camelCase version. */
  is_patient_bookable: Scalars['Boolean']['output'];
  languages: Array<UserLanguage>;
  lastName: Scalars['String']['output'];
  /** @deprecated Please use the camelCase version. */
  last_name: Scalars['String']['output'];
  /** Licensing bodies (e.g. states, territories) where the provider is able to practice */
  locationsForPracticing: Array<LicensingBody>;
  npi?: Maybe<Scalars['String']['output']>;
  outOfOffice?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Please use the camelCase version. */
  out_of_office?: Maybe<Scalars['Boolean']['output']>;
  panels: Array<Panel>;
  preferredRole?: Maybe<Scalars['String']['output']>;
  prescribingCredentials: Array<PrescribingCredential>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  profile_image_url?: Maybe<Scalars['String']['output']>;
  returnDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Please use the camelCase version. */
  return_date?: Maybe<Scalars['DateTime']['output']>;
  roles?: Maybe<Array<Maybe<RoleType>>>;
  /** Provider's schedule of open inventory and booked appointments */
  schedule: ProviderScheduleInventoryResults;
  school?: Maybe<Scalars['String']['output']>;
  seeingPatientsSince?: Maybe<Scalars['Int']['output']>;
  serviceArea?: Maybe<ServiceArea>;
  serviceTypes: Array<SpecialtyType>;
  /** @deprecated Please use the camelCase version. */
  service_area?: Maybe<ServiceArea>;
  specializedProceduresPerformed: Array<ProcedureType>;
  specialtyTypes?: Maybe<Array<SpecialtyType>>;
  /** @deprecated Please use the camelCase version. */
  specialty_types?: Maybe<Array<SpecialtyType>>;
  suffix?: Maybe<Scalars['String']['output']>;
  supportedAgeRange?: Maybe<SupportedAgeRange>;
  updatedAt: Scalars['DateTime']['output'];
  workSchedule?: Maybe<WorkSchedule>;
  worksWith?: Maybe<Array<Provider>>;
  /** @deprecated Please use the camelCase version. */
  works_with?: Maybe<Array<Provider>>;
};


export type ProviderAppointmentInventoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['DateTime']['input'];
};


export type ProviderDisplayNameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type ProviderDisplay_NameArgs = {
  options?: InputMaybe<InternalUserDisplayNameOptions>;
};


export type ProviderScheduleArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The connection type for Provider. */
export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  /** A list of edges. */
  edges: Array<ProviderEdge>;
  /** A list of nodes. */
  nodes: Array<Provider>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProviderDirectorySearchResult = SearchResult & {
  __typename?: 'ProviderDirectorySearchResult';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  providers: Array<BookableProviderSearchResult>;
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ProviderDirectorySuggestionResult = SearchResult & {
  __typename?: 'ProviderDirectorySuggestionResult';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

/** An edge in a connection. */
export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Provider>;
};

export type ProviderPracticingLocations = {
  __typename?: 'ProviderPracticingLocations';
  practicingLocations: Array<ProviderPracticingLocationsState>;
  provider: Provider;
};

export type ProviderPracticingLocationsServiceArea = {
  __typename?: 'ProviderPracticingLocationsServiceArea';
  offices: Array<Office>;
  serviceArea: ServiceArea;
};

export type ProviderPracticingLocationsState = {
  __typename?: 'ProviderPracticingLocationsState';
  serviceAreas: Array<ProviderPracticingLocationsServiceArea>;
  state: State;
};

export type ProviderPronoun = {
  __typename?: 'ProviderPronoun';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ProviderScheduleInventory = {
  __typename?: 'ProviderScheduleInventory';
  appointmentInventoryBookableLocations: Array<AppointmentInventoryBookableLocation>;
  appointmentType?: Maybe<ProviderScheduleInventoryAppointmentType>;
  /** Whether the patient is in the zoom room for a remote visit */
  arrivedInZoomWaitingRoom: Scalars['Boolean']['output'];
  blocked?: Maybe<Scalars['Boolean']['output']>;
  bookedAppointments?: Maybe<AppointmentConnection>;
  cancelledAppointments?: Maybe<AppointmentConnection>;
  /** How many people can book this inventory */
  capacity?: Maybe<Scalars['Int']['output']>;
  /** A comments about why the appointment slot is blocked */
  comments: Scalars['String']['output'];
  date: Scalars['String']['output'];
  /** How long the appointment will last in seconds */
  duration?: Maybe<Scalars['Int']['output']>;
  /** The time the appointment will end */
  endAt: Scalars['DateTime']['output'];
  examRoomId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  inventoryId?: Maybe<Scalars['ID']['output']>;
  /** A description of the appointment slot */
  label?: Maybe<Scalars['String']['output']>;
  /** Licensing bodies where the inventory is available (e.g. for cross-region booking) */
  licensingBodies: Array<LicensingBody>;
  numBookedAppointments: Scalars['Int']['output'];
  office: AppointmentInventorySearchResultsOffice;
  officeId: Scalars['Int']['output'];
  patient?: Maybe<ProviderScheduleInventoryPatient>;
  patientRunningLateAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether this is an appointment slot reserved for patients in the provider's panel */
  preferredBooking: Scalars['Boolean']['output'];
  provider?: Maybe<AppointmentInventoryProviderSearchResultsProvider>;
  providerId: Scalars['Int']['output'];
  /** The patient-reported reason for the appointment */
  reason?: Maybe<Scalars['String']['output']>;
  remoteMeetingId?: Maybe<Scalars['ID']['output']>;
  remoteMeetingLink?: Maybe<Scalars['String']['output']>;
  reserved: Scalars['Boolean']['output'];
  restricted: Scalars['Boolean']['output'];
  /** Names of appointment type restrictions on this appointment */
  restrictedAppointmentTypes?: Maybe<Array<Scalars['String']['output']>>;
  restrictedSpecialties: Array<Scalars['ID']['output']>;
  serviceAreaId?: Maybe<Scalars['ID']['output']>;
  /** The time the appointment will start */
  startAt: Scalars['DateTime']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
  /** The state of the appointment (created, booked, confirmed, arrived, started, cancelled, seen, or no_show) */
  state?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  timezoneName: Scalars['String']['output'];
  timezoneTzinfo: Scalars['String']['output'];
  utcStartDatetime: Scalars['String']['output'];
  visitSummaryId?: Maybe<Scalars['ID']['output']>;
};


export type ProviderScheduleInventoryBookedAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProviderScheduleInventoryCancelledAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProviderScheduleInventoryAppointmentType = {
  __typename?: 'ProviderScheduleInventoryAppointmentType';
  color?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  isWalkin: Scalars['Boolean']['output'];
  /** Appointment Type name in the context of provider schedule inventory */
  name?: Maybe<Scalars['String']['output']>;
};

export type ProviderScheduleInventoryCount = {
  __typename?: 'ProviderScheduleInventoryCount';
  count: Scalars['Int']['output'];
  key: Scalars['ID']['output'];
};

export type ProviderScheduleInventoryPatient = {
  __typename?: 'ProviderScheduleInventoryPatient';
  currentVoluntaryAlignment?: Maybe<VoluntaryAlignmentOffer>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  unregistered?: Maybe<Scalars['Boolean']['output']>;
};

export type ProviderScheduleInventoryResults = {
  __typename?: 'ProviderScheduleInventoryResults';
  groupAppointmentCountsByInventoryId: Array<ProviderScheduleInventoryCount>;
  nodes: Array<ProviderScheduleInventory>;
  totalGroupInventory: Scalars['Int']['output'];
};

export enum ProviderSortBy {
  LastName = 'LAST_NAME'
}

/** Quantity Limits for a coverage */
export type QuantityLimit = {
  __typename?: 'QuantityLimit';
  maximumAmount?: Maybe<Scalars['Float']['output']>;
  maximumAmountQualifier?: Maybe<Scalars['String']['output']>;
  maximumAmountTimePeriod?: Maybe<Scalars['String']['output']>;
  maximumAmountTimePeriodEndDate?: Maybe<Scalars['DateTime']['output']>;
  maximumAmountTimePeriodStartDate?: Maybe<Scalars['DateTime']['output']>;
  maximumAmountTimePeriodUnits?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  acceptedSuspectProblems: ProblemConnection;
  /**
   * Returns a token that was previously associated with the provided nonce.
   * Subsequent calls with the same nonce will return nothing.
   */
  accessToken?: Maybe<Scalars['String']['output']>;
  activePharmacyCoverageDetails?: Maybe<Array<ActivePharmacyCoverageDetails>>;
  admission?: Maybe<Admission>;
  /**
   * Use this query to search admissions. Note: while the type signature suggests 2
   * way pagination to conform with Relay, this resource can only paginate
   * forwards. Do not use `before` and `last`
   */
  admissions: AdmissionSearchPage;
  appointment?: Maybe<Appointment>;
  appointmentCancellationReasons: Array<AppointmentCancellationReason>;
  appointmentInventory?: Maybe<AppointmentInventory>;
  /** Retrieves a list of valid reasons for blocking appointment slots. */
  appointmentInventoryBlocks: Array<AppointmentInventoryBlock>;
  /** Searches for available appointment inventories based on various filters. */
  appointmentInventorySearch?: Maybe<AppointmentInventorySearchResults>;
  appointmentLengths: Array<AppointmentLength>;
  /** Returns a list of candidate locations based on the given search text query. */
  appointmentLocationSearch?: Maybe<Array<Address>>;
  appointmentRecording?: Maybe<AppointmentRecording>;
  appointmentRooms?: Maybe<Array<AppointmentRoom>>;
  appointmentStatuses?: Maybe<Array<AppointmentStatus>>;
  /** Retrieves alternative appointment types for a given appointment type and service area. */
  appointmentTypeAlternatives: AppointmentTypeAlternative;
  appointmentTypeGroups: Array<AppointmentTypeGroup>;
  appointmentTypes: Array<AppointmentType>;
  /** Search for booked and confirmed appointments (does not include walk-ins) with Elasticsearch/OpenSearch. */
  appointments: AppointmentsSearchResults;
  /** Returns the list of assignable roles. */
  assignableRoles: Array<RoleType>;
  /** Searches for available appointment inventories for a given visit reason and filters. */
  availableAppointmentSearch?: Maybe<AppointmentInventorySearchResults>;
  b2bCompany?: Maybe<B2bCompany>;
  billingAdminTasks: Array<Scalars['String']['output']>;
  billingDistrict: BillingDistrict;
  billingDistrictSearch: BillingDistrictSearch;
  /** Search for bookable providers by name or ID. Not paginated and designed for autocompletes. */
  bookableProviderSearch: Array<BookableProviderSearchResult>;
  /** Returns CAZ Request */
  cazRequest?: Maybe<CazRequest>;
  /** Returns generated report contents according to selected options */
  cervicalCancerScreeningReport?: Maybe<CervicalCancerReportContents>;
  /** Returns reporting field options */
  cervicalCancerScreeningReportOptions: CervicalCancerResultOptions;
  chartingTemplates: ChartingTemplateConnection;
  checkVersion: CheckVersion;
  /** calls OpenSearch query to find co-signers by name */
  coSigners: Array<TaskAssignee>;
  consultOrder?: Maybe<ConsultOrder>;
  /** Search for contact autocomplete options. Specifically in the context of consult and procedure orders. */
  consultantAutocomplete?: Maybe<Array<ConsultantAutocomplete>>;
  contact?: Maybe<Contact>;
  /** Provides auto-completion suggestions for contacts based on the input query */
  contactSuggestions: Array<ContactSearchResult>;
  /**
   * Use this query to search contacts. Note: while the type signature suggests 2
   * way pagination to conform with Relay, this resource can only paginate
   * forwards. Do not use `before` and `last`
   */
  contacts: ContactSearchPage;
  /** @deprecated Please reference the ContactStatus enum instead */
  contactsStatuses: Array<Maybe<ContactsStatus>>;
  customNetworks: Array<CustomNetwork>;
  detailedEthnicities: Array<Ethnicity>;
  diagnosticGroups: Array<Maybe<DiagnosticGroup>>;
  directSignupToken?: Maybe<DirectSignupToken>;
  /** Finds a B2B discount type, consumer discount type, or referral code by its code */
  discountType?: Maybe<DiscountType>;
  document: Document;
  /** Filing templates for documents */
  documentFilingTemplates: Array<FilingTemplate>;
  /** Documents inbox */
  documents: DocumentConnection;
  /** Search for providers by name, ID, or preferred roles. Not paginated and designed for autocompletes. */
  elasticsearchProviderSearch: Array<BookableProviderSearchResult>;
  ethnicities?: Maybe<Array<Ethnicity>>;
  expertiseTypes: Array<Maybe<Expertise>>;
  externalDocuments?: Maybe<Array<ExternalDocument>>;
  featuredPharmacy?: Maybe<Pharmacy>;
  firebase?: Maybe<Firebase>;
  formularyBenefits?: Maybe<BenefitsResponse>;
  genderIdentities?: Maybe<Array<GenderIdentity>>;
  hearabouts: Array<Hearabout>;
  helpflowTypes: Array<HelpFlow>;
  inboundFhirItems?: Maybe<Array<InboundFhirItem>>;
  indication?: Maybe<Indication>;
  institutions: Array<Maybe<Institution>>;
  insurance?: Maybe<Insurance>;
  insuranceCarrierSearch: Array<InsuranceCarrier>;
  insuranceCarriers: Array<InsuranceCarrier>;
  insuranceEligibilityRules: Array<EligibilityRule>;
  insuranceNetworks: Array<Maybe<InsuranceNetwork>>;
  insuranceNetworksProviders: Array<Maybe<InsuranceNetworksProviders>>;
  insurancePlan?: Maybe<InsurancePlan>;
  insurancePlanNameSearch: Array<InsurancePlan>;
  /** Returns a patient's insurance plan, if returned from Waystar, given the carrier member number */
  insurancePlanPopulation?: Maybe<InsurancePlan>;
  insurancePlanSearch: InsurancePlansConnection;
  insuranceRelationshipTypes: Array<Maybe<InsuranceRelationshipType>>;
  insuranceTypes: Array<Maybe<InsuranceType>>;
  internalUser: InternalUser;
  internalUsers: InternalUserConnection;
  labOrder: LabOrder;
  /**
   * Search for lab order type by clinical abbreviation, clinical description, lay
   * description, id, etc. Not paginated and designed for autocompletes
   */
  labOrderTypeSearch: Array<LabOrderTypeResult>;
  labOrderTypes: LabOrderTypeConnection;
  languages?: Maybe<Array<Language>>;
  /** The required legal documents */
  legalDocuments: Array<Maybe<LegalDocument>>;
  /** The required legal documents for beneficiary */
  legalDocumentsForBeneficiary: Array<LegalDocument>;
  /**
   * The required legal documents
   * @deprecated Please use the camelCase version.
   */
  legal_documents: Array<Maybe<LegalDocument>>;
  /**
   * A list of bodies (e.g. states and non-states like Washington DC) that are
   * relevant with respect to medical licensing. Differs from the `states` field
   * only in name; since `states` actually includes regions that aren't states, it
   * seems clearer to use a more generic name.
   */
  licensingBodies: Array<State>;
  massAppointmentChangeRequest?: Maybe<MassAppointmentChangeRequest>;
  massAppointmentChangeRequests: MassAppointmentChangeRequestConnection;
  measurementOrderTypeSources: Array<MeasurementOrderTypeSource>;
  measurementTypes: MeasurementTypeConnection;
  measurementsValidationRules: MeasurementsValidationRules;
  membership?: Maybe<Membership>;
  messageTemplates: MessageTemplateConnection;
  mlBillingProcedureSuggestion?: Maybe<MlBillingProcedureSuggestion>;
  mlFeedbackProcedureSuggestion?: Maybe<MlFeedbackProcedureSuggestion>;
  mroWorkflow?: Maybe<MroWorkflow>;
  /**
   * Use this query to return all opened MroWorkflows, with the option to remove
   * filtering or only view certain workflow types.  Search and pagination not
   * currently supported
   */
  mroWorkflows?: Maybe<Array<MroWorkflow>>;
  note?: Maybe<Note>;
  noteTemplate?: Maybe<NoteTemplate>;
  observations?: Maybe<Array<Observation>>;
  offices: Array<Office>;
  /** Returns a list of offices that have open lab hours for the current week */
  officesWithOpenLabHoursInServiceArea?: Maybe<Array<Office>>;
  openPracticeMessage?: Maybe<OpenPracticeMessage>;
  openTokToken: Scalars['String']['output'];
  outreachManagerAppointmentTypeGroups: OutreachManagerAppointmentTypeGroupConnection;
  /**
   * The interface for all types of outreach via the Outreach Manager.
   * A single batch order mainly consists of the type of outreach, the patients who receive the order, and the orderer.
   * Currently the subtypes are:
   * Survey Order, Visit Follow-up Order, Lab Order, and User Engagement Message
   */
  outreachManagerBatches: OutreachManagerBatchInterfaceConnection;
  patient?: Maybe<Patient>;
  patientCharge?: Maybe<PatientCharge>;
  patientChargePayment?: Maybe<PatientChargePayment>;
  patientMedications: Array<PatientMedication>;
  /** Search for patients by name, email, DOB, id, etc. Not paginated and designed for autocompletes */
  patientSearch: Array<PatientSearchResult>;
  patients: Array<Patient>;
  pdmpLastViewed?: Maybe<PdmpReportEvent>;
  pdmpNonParticipatingStates: Array<PdmpNonParticipatingState>;
  pharmacy: Pharmacy;
  /**
   * Searches for pharmacies close to a location OR mail order pharmacies.
   *
   * When not searching for mail order pharmacies, a location or patient must be provided.
   *
   * If no location is provided, the location of the patient's home address, home
   * office address, service area default office address will be used in that order.
   */
  pharmacySearch: PharmacyConnection;
  phasedPrograms: Array<Program>;
  phlebotomyVisitAggregations: PhlebotomyVisitAggregations;
  /** Search for possible parent contacts by name. Not paginated and designed for autocompletes */
  possibleParentContacts: Array<ContactSearchResult>;
  /** calls Elasticsearch query for the prioritized task list for the user */
  prioritizedTasks: PrioritizedTasks;
  /** Retrieve Problem by ID */
  problem: Problem;
  /** Search for Problem Types. */
  problemTypeSearch: Array<ProblemTypeResult>;
  problemTypes: ProblemTypeConnection;
  /** Retrieve Problems that match specific criteria */
  problems: Array<Problem>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  procedureOrder?: Maybe<ProcedureOrder>;
  procedureType?: Maybe<ProcedureType>;
  procedureTypesSearch?: Maybe<ProcedureTypesSearch>;
  profile?: Maybe<InternalUser>;
  program: Program;
  programEnrollmentCareTeam: ProgramEnrollmentCareTeam;
  programVisit?: Maybe<ProgramVisit>;
  /** Search for providers based on name, expertise, role, specialities, procedures, languages and location. */
  providerDirectorySearch: ProviderDirectorySearchResult;
  /** Provides auto-completion suggestions for provider directory based on the input query */
  providerDirectorySuggestions: Array<ProviderDirectorySuggestionResult>;
  /** Returns offices that a provider is licensed to practice in, grouped by state and service area. */
  providerPracticingLocations?: Maybe<ProviderPracticingLocations>;
  /** @deprecated Use providersConnection instead; this deprecated field should have ids as an array of strings instead of an array of integers, and should be paginated. */
  providers: Array<Maybe<Provider>>;
  providersConnection: ProviderConnection;
  races?: Maybe<Array<Race>>;
  recommendedAppointmentLocations?: Maybe<RecommendedAppointmentLocations>;
  rejectedSuspectProblems: ProblemConnection;
  /** Retrieves a list of reservation tags for appointment inventories. */
  reservationTags: Array<ReservationTags>;
  scheduleTemplate: ScheduleTemplate;
  scheduleTemplateVariant: ScheduleTemplateVariant;
  scheduleTemplateVariants: Array<ScheduleTemplateVariant>;
  scheduleTemplates: ScheduleTemplateConnection;
  seniorHealthReferenceData: SeniorHealthReferenceData;
  serviceArea: ServiceArea;
  serviceAreas: Array<ServiceArea>;
  serviceTicket?: Maybe<ServiceTicket>;
  serviceTicketImproperlyClosedOrdersSearch: ServiceTicketImproperlyClosedOrdersConnection;
  serviceTicketItem?: Maybe<ServiceTicketItem>;
  serviceTicketPaymentTransmission?: Maybe<ServiceTicketPaymentTransmission>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  serviceTicketTransmissionsByDateAggregation: TransmittedAggregationResult;
  serviceTicketTransmissionsByMarketAggregation: ServiceTicketTransmissionSearch;
  /** @deprecated Please use the camelCase version. */
  service_areas: Array<ServiceArea>;
  sexualOrientations?: Maybe<Array<SexualOrientation>>;
  /** calls OpenSearch query to find sign-on-behalf-of users by name */
  signOnBehalfOf: Array<TaskAssignee>;
  signedS3Url?: Maybe<SignedS3Url>;
  smartDraft: SmartDraft;
  specialties: Array<Maybe<Specialty>>;
  states: Array<State>;
  stripeSetupIntent?: Maybe<StripeSetupIntent>;
  summary?: Maybe<Summary>;
  /** A list of currently active surveys. */
  surveys: Array<Survey>;
  task?: Maybe<Task>;
  /** calls OpenSearch query to find task assignee by name */
  taskAssignee: Array<TaskAssignee>;
  /** Calls Elasticsearch query to get task aggregrations only, by location. */
  taskCounts: TasksAggregations;
  textTemplate?: Maybe<TextTemplate>;
  /** A list of all User Engagement Messages */
  userEngagementMessages: UserEngagementMessageConnection;
  /** Validates access key independent of patient attributes. Returns true when valid, false otherwise */
  validateChartClaimingAccessKey: Scalars['Boolean']['output'];
  /**
   * Validates a claim code and returns information on payment provider subscription.
   * @deprecated Use the mutation validateClaimCode instead
   */
  validateClaimCode?: Maybe<ClaimCodeValidation>;
  verifiedEligibleEmployee?: Maybe<VerifiedEligibleEmployee>;
  /**
   * Validates access key and verifies that patient information matches associated
   * record. Returns true when verified, false otherwise
   */
  verifyChartClaimingAccessKey: Scalars['Boolean']['output'];
  /** Represents a mapping of most booked and remaining available visit reasons for a patient. */
  visitReasonMap?: Maybe<VisitReasonMap>;
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
  voluntaryAlignmentOffers: Array<VoluntaryAlignmentOffer>;
  /** Search for voluntary alignment offers. */
  voluntaryAlignmentOffersSearch: VoluntaryAlignmentOffersSearch;
  /** Fetch a list of walkin (phlebotomy) appointments. */
  walkins: Array<Appointment>;
  /** Returns an HTML response displaying the result of a Waystar eligibility check */
  waystarAdhocHtmlResponse?: Maybe<Scalars['HTML']['output']>;
  /**
   * Returns an HTML response displaying the result of a Waystar eligibility check.
   * Requires a Patient ID, Carrier ID & Subscriber Number
   */
  waystarAutoVerificationHtmlResponse?: Maybe<Scalars['HTML']['output']>;
  waystarEligibilityStatus?: Maybe<WaystarEligibilityStatus>;
  /** Returns a HTML response displaying the result of a Waystar eligibility check. Requires an Insurance id */
  waystarHtmlResponse?: Maybe<Scalars['HTML']['output']>;
  waystarParsedData?: Maybe<WaystarParsedData>;
  weightBasedDosing?: Maybe<WeightBasedDosingResponse>;
  whitelistedEmployee?: Maybe<WhitelistedEmployee>;
  whitelistedEmployeeDependent?: Maybe<WhitelistedEmployee>;
  zendeskToken?: Maybe<Scalars['String']['output']>;
  zoom: Zoom;
};


export type QueryAcceptedSuspectProblemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
};


export type QueryAccessTokenArgs = {
  nonce: Scalars['String']['input'];
};


export type QueryActivePharmacyCoverageDetailsArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryAdmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  query: AdmissionSearchQuery;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentInventoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentInventorySearchArgs = {
  appointmentCategory?: InputMaybe<Scalars['String']['input']>;
  appointmentDate: Scalars['Date']['input'];
  appointmentTypeId: Scalars['ID']['input'];
  excludeClosedPanels?: InputMaybe<Scalars['Boolean']['input']>;
  excludePreferredBooking?: InputMaybe<Scalars['Boolean']['input']>;
  expertiseTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  providerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceAreaId: Scalars['ID']['input'];
  spokenLanguageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  view?: InputMaybe<AppointmentInventoryView>;
};


export type QueryAppointmentLocationSearchArgs = {
  textQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAppointmentRecordingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentTypeAlternativesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appointmentTypeId: Scalars['ID']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  serviceAreaId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAppointmentTypesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryAppointmentsArgs = {
  appointmentTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  providerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  showApproved?: InputMaybe<Scalars['Boolean']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryAvailableAppointmentSearchArgs = {
  appointmentDate: Scalars['Date']['input'];
  excludeClosedPanels?: InputMaybe<Scalars['Boolean']['input']>;
  excludePreferredBooking?: InputMaybe<Scalars['Boolean']['input']>;
  expertiseTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  providerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceAreaId: Scalars['ID']['input'];
  spokenLanguageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  view?: InputMaybe<AppointmentInventoryView>;
  visitReasonId: Scalars['ID']['input'];
};


export type QueryB2bCompanyArgs = {
  b2bEmail?: InputMaybe<Scalars['String']['input']>;
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBillingDistrictArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBillingDistrictSearchArgs = {
  districtName?: InputMaybe<Scalars['String']['input']>;
  officeName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBookableProviderSearchArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  textQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCazRequestArgs = {
  patientId: Scalars['String']['input'];
  practitionerId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};


export type QueryCervicalCancerScreeningReportArgs = {
  cervicalCancerScreeningReportInput?: InputMaybe<CervicalCancerScreeningReportInput>;
};


export type QueryChartingTemplatesArgs = {
  params: ChartingTemplateSearchParams;
};


export type QueryCheckVersionArgs = {
  appId: Scalars['String']['input'];
  currentVersion: Scalars['String']['input'];
};


export type QueryCoSignersArgs = {
  name: Scalars['String']['input'];
};


export type QueryConsultOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConsultantAutocompleteArgs = {
  orderType: OrderWithConsultantTypes;
  queryString: Scalars['String']['input'];
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactSuggestionsArgs = {
  query: Scalars['String']['input'];
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRibbonPilot?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pageInfo?: InputMaybe<ContactPageInfo>;
  query: ContactSearchQuery;
};


export type QueryCustomNetworksArgs = {
  restricted?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDirectSignupTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryDiscountTypeArgs = {
  code: Scalars['String']['input'];
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  serviceAreaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  statuses?: InputMaybe<Array<DocumentStatus>>;
  suggestedPatientId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryElasticsearchProviderSearchArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  preferredRoles?: InputMaybe<Array<PreferredRole>>;
  textQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExternalDocumentsArgs = {
  patientId: Scalars['ID']['input'];
  searchString?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFormularyBenefitsArgs = {
  medicationDispensableId: Scalars['ID']['input'];
  pageCursor?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  pbmId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInboundFhirItemsArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryIndicationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInsuranceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInsuranceCarrierSearchArgs = {
  excludeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceAreaId: Scalars['ID']['input'];
  textQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInsuranceCarriersArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInsuranceEligibilityRulesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInsurancePlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInsurancePlanNameSearchArgs = {
  carrierName: Scalars['String']['input'];
  textQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInsurancePlanPopulationArgs = {
  insuranceCarrierId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  subscriberNumber: Scalars['String']['input'];
};


export type QueryInsurancePlanSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  networkStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInternalUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInternalUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  withMassCancellations?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLabOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLabOrderTypeSearchArgs = {
  searchQuery: Scalars['String']['input'];
};


export type QueryLabOrderTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLegalDocumentsForBeneficiaryArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type QueryLicensingBodiesArgs = {
  excludeTerritories?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMassAppointmentChangeRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMassAppointmentChangeRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  changeTypes: Array<MassAppointmentChangeRequestChangeType>;
  date?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  submittedByIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryMeasurementOrderTypeSourcesArgs = {
  limitByCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMeasurementTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forConnectedDeviceOrders?: InputMaybe<Scalars['Boolean']['input']>;
  forInsightsPanel?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMessageTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMlBillingProcedureSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMlFeedbackProcedureSuggestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMroWorkflowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMroWorkflowsArgs = {
  openOnly?: Scalars['Boolean']['input'];
  query: MroWorkflowSearchQuery;
};


export type QueryNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNoteTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryObservationsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOfficesArgs = {
  excludeSeniorSpecific?: InputMaybe<Scalars['Boolean']['input']>;
  excludeVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceAreaId?: InputMaybe<Scalars['ID']['input']>;
  sortByDistance?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOfficesWithOpenLabHoursInServiceAreaArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOpenPracticeMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOpenTokTokenArgs = {
  sessionId: Scalars['String']['input'];
};


export type QueryOutreachManagerAppointmentTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOutreachManagerBatchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  outreachType?: InputMaybe<OutreachManagerBatchOrderType>;
  status?: InputMaybe<OutreachManagerStatus>;
};


export type QueryPatientArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPatientChargeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPatientChargePaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPatientMedicationsArgs = {
  patientId: Scalars['ID']['input'];
  status?: InputMaybe<PatientMedicationStatusEnum>;
};


export type QueryPatientSearchArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  textQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPatientsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryPdmpLastViewedArgs = {
  patientId: Scalars['ID']['input'];
  requestedLocation: Scalars['String']['input'];
};


export type QueryPharmacyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPharmacySearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  geolocation?: InputMaybe<Geolocation>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  mailOrder?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPhlebotomyVisitAggregationsArgs = {
  dateMax: Scalars['DateTime']['input'];
  dateMin: Scalars['DateTime']['input'];
  officeId: Scalars['ID']['input'];
};


export type QueryPossibleParentContactsArgs = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  query: Scalars['String']['input'];
};


export type QueryPrioritizedTasksArgs = {
  b2bCompanyId?: InputMaybe<Scalars['ID']['input']>;
  bucket: PrioritizedTasksBucket;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  filterId?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  licensingBodyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  patientOfficeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceAreaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  similarToTaskId?: InputMaybe<Scalars['ID']['input']>;
  sortOption?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProblemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProblemTypeSearchArgs = {
  searchQuery: Scalars['String']['input'];
};


export type QueryProblemTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProblemsArgs = {
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  attachmentType?: InputMaybe<ProblemAttachmentAttachmentEnum>;
};


export type QueryProcedureInteractionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcedureOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcedureTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProcedureTypesSearchArgs = {
  interactions?: InputMaybe<Scalars['Boolean']['input']>;
  internal?: InputMaybe<Scalars['Boolean']['input']>;
  orderable?: InputMaybe<Scalars['Boolean']['input']>;
  performable?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
};


export type QueryProgramArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProgramEnrollmentCareTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProgramVisitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  summaryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProviderDirectorySearchArgs = {
  location?: InputMaybe<Geolocation>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProviderDirectorySuggestionsArgs = {
  query: Scalars['String']['input'];
};


export type QueryProviderPracticingLocationsArgs = {
  providerId: Scalars['ID']['input'];
};


export type QueryProvidersArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryProvidersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  bookable?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['ID']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceAreaId?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<ProviderSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type QueryRecommendedAppointmentLocationsArgs = {
  patientId: Scalars['ID']['input'];
  serviceAreaId: Scalars['ID']['input'];
};


export type QueryRejectedSuspectProblemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
};


export type QueryScheduleTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScheduleTemplateVariantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScheduleTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchTerms?: InputMaybe<Scalars['String']['input']>;
};


export type QueryServiceAreaArgs = {
  id: Scalars['Int']['input'];
};


export type QueryServiceAreasArgs = {
  excludeNoOffices?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryServiceTicketArgs = {
  id: Scalars['ID']['input'];
};


export type QueryServiceTicketImproperlyClosedOrdersSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  market?: InputMaybe<Scalars['String']['input']>;
  office?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  procedureType?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};


export type QueryServiceTicketItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryServiceTicketPaymentTransmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryServiceTicketTransmissionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryServiceTicketTransmissionsByDateAggregationArgs = {
  dateDailyMax: Scalars['ISO8601Date']['input'];
  dateDailyMin: Scalars['ISO8601Date']['input'];
  dateMonthlyMax: Scalars['ISO8601Date']['input'];
  dateMonthlyMin: Scalars['ISO8601Date']['input'];
};


export type QueryServiceTicketTransmissionsByMarketAggregationArgs = {
  dateDailyMax: Scalars['ISO8601Date']['input'];
  dateDailyMin: Scalars['ISO8601Date']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryService_AreasArgs = {
  excludeNoOffices?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySignOnBehalfOfArgs = {
  name: Scalars['String']['input'];
};


export type QuerySignedS3UrlArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySmartDraftArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStripeSetupIntentArgs = {
  id: Scalars['String']['input'];
};


export type QuerySummaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskAssigneeArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  strategy?: InputMaybe<TaskAssigneeSearchStrategy>;
};


export type QueryTaskCountsArgs = {
  roleIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryTextTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserEngagementMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryValidateChartClaimingAccessKeyArgs = {
  accessKey: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidateClaimCodeArgs = {
  claimCode: Scalars['String']['input'];
};


export type QueryVerifiedEligibleEmployeeArgs = {
  token: Scalars['String']['input'];
};


export type QueryVerifyChartClaimingAccessKeyArgs = {
  accessKey: Scalars['String']['input'];
  dob: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVisitReasonMapArgs = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  serviceAreaId: Scalars['ID']['input'];
};


export type QueryVoluntaryAlignmentOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVoluntaryAlignmentOffersArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryVoluntaryAlignmentOffersSearchArgs = {
  alignmentStatus?: InputMaybe<Scalars['String']['input']>;
  endAt?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  pcp?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWalkinsArgs = {
  appointmentStates?: InputMaybe<Array<Scalars['String']['input']>>;
  dateMax?: InputMaybe<Scalars['DateTime']['input']>;
  dateMin?: InputMaybe<Scalars['DateTime']['input']>;
  officeId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  patientSearchText?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWaystarAdhocHtmlResponseArgs = {
  dob: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  insuranceCarrierId: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  serviceAreaId: Scalars['ID']['input'];
  subscriberNumberId: Scalars['ID']['input'];
};


export type QueryWaystarAutoVerificationHtmlResponseArgs = {
  insuranceCarrierId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  subscriberNumber: Scalars['String']['input'];
};


export type QueryWaystarEligibilityStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWaystarHtmlResponseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWaystarParsedDataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWeightBasedDosingArgs = {
  medicationDispensableId: Scalars['ID']['input'];
  medicationRegimenId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
};


export type QueryWhitelistedEmployeeArgs = {
  b2bCompanyId?: InputMaybe<Scalars['String']['input']>;
  dob?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
  workEmail?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWhitelistedEmployeeDependentArgs = {
  b2bCompanyId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  parentId: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of QueueAudioChunk */
export type QueueAudioChunkInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  audioChunk: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of QueueAudioChunk. */
export type QueueAudioChunkPayload = {
  __typename?: 'QueueAudioChunkPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type Race = {
  __typename?: 'Race';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type RangeBucket = {
  __typename?: 'RangeBucket';
  /** Document count for the range */
  docCount: Scalars['Int']['output'];
  /** Timestamp for the start of the range */
  from?: Maybe<Scalars['Float']['output']>;
  /** Formatted start of the range */
  fromAsString?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  /** Timestamp for the end of the range */
  to?: Maybe<Scalars['Float']['output']>;
  /** Formatted end of the range */
  toAsString?: Maybe<Scalars['String']['output']>;
};

export enum Rating {
  Acceptable = 'acceptable',
  Great = 'great',
  Problematic = 'problematic',
  Unrated = 'unrated'
}

export enum ReadmissionRisk {
  HighRisk = 'HIGH_RISK',
  LowToMediumRisk = 'LOW_TO_MEDIUM_RISK',
  NotEvaluatedOrNa = 'NOT_EVALUATED_OR_NA'
}

export type RecommendedAppointmentLocations = {
  __typename?: 'RecommendedAppointmentLocations';
  patientSavedAddresses?: Maybe<Array<Address>>;
  previouslyVisitedOffices?: Maybe<Array<Office>>;
};

export type Referral = {
  __typename?: 'Referral';
  /** For use with the dismissAlert mutation */
  bannerAlertKey: Scalars['String']['output'];
  messageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pageAction: Scalars['String']['output'];
  pageBody: Scalars['String']['output'];
  pageHeader: Scalars['String']['output'];
};

/** Autogenerated input type of RefreshTaskInIndex */
export type RefreshTaskInIndexInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RefreshTaskInIndex. */
export type RefreshTaskInIndexPayload = {
  __typename?: 'RefreshTaskInIndexPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type RegistrationWaitlistEnrollment = {
  __typename?: 'RegistrationWaitlistEnrollment';
  id: Scalars['ID']['output'];
  patient: Patient;
  serviceArea: ServiceArea;
  state: RegistrationWaitlistEnrollmentState;
};

export enum RegistrationWaitlistEnrollmentState {
  Registered = 'registered',
  Waitlisted = 'waitlisted'
}

/** Autogenerated input type of RejectSuspectProblem */
export type RejectSuspectProblemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  problemId: Scalars['ID']['input'];
};

/** Autogenerated return type of RejectSuspectProblem. */
export type RejectSuspectProblemPayload = {
  __typename?: 'RejectSuspectProblemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean']['output'];
};

export type RelatedPerson = {
  __typename?: 'RelatedPerson';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  /** The related person data in FHIR */
  fhir?: Maybe<Scalars['JSON']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ReleaseDocumentLock */
export type ReleaseDocumentLockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

/** Autogenerated return type of ReleaseDocumentLock. */
export type ReleaseDocumentLockPayload = {
  __typename?: 'ReleaseDocumentLockPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Document unlocked */
  document?: Maybe<Document>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type Remindable = {
  /** id of remindable object */
  id: Scalars['ID']['output'];
  /**
   * class name of remindable object
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String']['output'];
  /** string to indicate a status of the remindable object */
  notifiableStatus?: Maybe<Scalars['String']['output']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']['output']>;
  /** notification subtitle based on remindable type */
  notificationSubtitle?: Maybe<Scalars['String']['output']>;
  /** notification title based on remindable type */
  notificationTitle: Scalars['String']['output'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String']['output'];
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']['output']>;
  /** a weight given to remindable objects to define sort order */
  sortWeight: Scalars['Int']['output'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int']['output'];
  /** universal link for notification */
  universalLink: Scalars['String']['output'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String']['output'];
};

/** Autogenerated input type of RemoveAppointmentInventories */
export type RemoveAppointmentInventoriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTime']['input'];
  providerId: Scalars['Int']['input'];
};

/** Autogenerated return type of RemoveAppointmentInventories. */
export type RemoveAppointmentInventoriesPayload = {
  __typename?: 'RemoveAppointmentInventoriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  inventoryIds?: Maybe<Array<Scalars['ID']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemoveAppointmentInventory */
export type RemoveAppointmentInventoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of RemoveAppointmentInventory. */
export type RemoveAppointmentInventoryPayload = {
  __typename?: 'RemoveAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RemovePatientPcpAndUpdateOffice */
export type RemovePatientPcpAndUpdateOfficeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of RemovePatientPcpAndUpdateOffice. */
export type RemovePatientPcpAndUpdateOfficePayload = {
  __typename?: 'RemovePatientPcpAndUpdateOfficePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

export type RenewalRequest = {
  __typename?: 'RenewalRequest';
  createdAt: Scalars['DateTime']['output'];
  patientPharmacy?: Maybe<PatientPharmacy>;
  pharmacy?: Maybe<Pharmacy>;
};

export type Report = {
  __typename?: 'Report';
  additionalLinks?: Maybe<Array<Link>>;
  correlationId?: Maybe<Scalars['ID']['output']>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
  message?: Maybe<Scalars['String']['output']>;
  narxMessages?: Maybe<Array<NarxMessage>>;
  narxScores?: Maybe<Array<NarxScore>>;
  pmpDestinations?: Maybe<Array<Scalars['String']['output']>>;
  reportUrl?: Maybe<Scalars['String']['output']>;
  requestEvent?: Maybe<Scalars['JSON']['output']>;
  requestedLocation?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of RequestDocusignVoluntaryAlignment */
export type RequestDocusignVoluntaryAlignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of RequestDocusignVoluntaryAlignment. */
export type RequestDocusignVoluntaryAlignmentPayload = {
  __typename?: 'RequestDocusignVoluntaryAlignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

/** Autogenerated input type of RequestPdmpReport */
export type RequestPdmpReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  pendingNewRxIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  prescribingCredentialId?: InputMaybe<Scalars['ID']['input']>;
  rxCartId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of RequestPdmpReport. */
export type RequestPdmpReportPayload = {
  __typename?: 'RequestPdmpReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  reports?: Maybe<Array<Report>>;
  success: Scalars['Boolean']['output'];
};

/** Contains the elements/parameters for the requested drug details. */
export type RequestedDrugDoseDetails = {
  __typename?: 'RequestedDrugDoseDetails';
  dosingFrequencyInterval?: Maybe<Scalars['String']['output']>;
  drugDesc?: Maybe<Scalars['String']['output']>;
  drugId?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  singleDoseAmount?: Maybe<Scalars['Float']['output']>;
  singleDoseUnit?: Maybe<Scalars['String']['output']>;
};

export type RescheduleAppointmentAttributes = {
  appointmentTypeId: Scalars['ID']['input'];
  /** ID of the appointment inventory that will be booked as a part of rescheduling. */
  inventoryId: Scalars['ID']['input'];
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of RescheduleAppointment */
export type RescheduleAppointmentInput = {
  appointmentCancellationReasonId?: InputMaybe<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the appointment that will be cancelled as a part of rescheduling. */
  fromAppointmentId: Scalars['ID']['input'];
  newAppointmentAttributes: RescheduleAppointmentAttributes;
  patientId: Scalars['ID']['input'];
  sendRescheduleNotice?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of RescheduleAppointment. */
export type RescheduleAppointmentPayload = {
  __typename?: 'RescheduleAppointmentPayload';
  /** The new appointment that was created from rescheduling. */
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient: Patient;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ResendDirectSignupEmail */
export type ResendDirectSignupEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of ResendDirectSignupEmail. */
export type ResendDirectSignupEmailPayload = {
  __typename?: 'ResendDirectSignupEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ResendVoluntaryAlignmentDocusignRequest */
export type ResendVoluntaryAlignmentDocusignRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  voluntaryAlignmentOfferId: Scalars['ID']['input'];
};

/** Autogenerated return type of ResendVoluntaryAlignmentDocusignRequest. */
export type ResendVoluntaryAlignmentDocusignRequestPayload = {
  __typename?: 'ResendVoluntaryAlignmentDocusignRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

export type ReservationTags = {
  __typename?: 'ReservationTags';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Formulary resource link message */
export type ResourceLink = {
  __typename?: 'ResourceLink';
  resourceLink?: Maybe<Scalars['String']['output']>;
  resourceLinkMessageType: BenefitsMessageTypeEnum;
};

/** The connection type for Measurement. */
export type RespiratoryRateMeasurementConnection = {
  __typename?: 'RespiratoryRateMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  validationRules?: Maybe<IntegerMeasurementValidationRule>;
};

export type Response = {
  __typename?: 'Response';
  accudrawCollectionInstructions?: Maybe<Array<CollectionInstruction>>;
  messages: Array<Message>;
};

/** Contains WBD result details for requested medication */
export type Result = {
  __typename?: 'Result';
  /**
   * Contains the calculated daily dose amount based upon the SingleDoseAmount,
   * DosingFrequencyInterval, PatientWeight (if required) and BodySurfaceArea (if required).
   */
  calculatedDoseThresholdAmount?: Maybe<Scalars['String']['output']>;
  /** Contains the unit of measure for the CalculatedDoseThresholdAmount. */
  calculatedDoseThresholdUnit?: Maybe<Scalars['String']['output']>;
  doseThresholdRanges?: Maybe<DoseThresholdRanges>;
  doseThresholdResult?: Maybe<DoseThresholdResult>;
  dosingMessages?: Maybe<Array<DosingMessage>>;
  /**
   * Indicates if the record has been excluded from the dose checking. If true, a
   * clinician should evaluate the output results. Manual dose checking could be required.
   */
  isExcluded: Scalars['Boolean']['output'];
  /** Indicates service did not run successfully. */
  isSuccessful: Scalars['Boolean']['output'];
  requestedDrugDoseDetails?: Maybe<RequestedDrugDoseDetails>;
  /** Contains a text description of the patient parameters which are required for dosing screening of the drug */
  requiredPatientParameters?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ResumeScribeStream */
export type ResumeScribeStreamInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResumeScribeStream. */
export type ResumeScribeStreamPayload = {
  __typename?: 'ResumeScribeStreamPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RevokeMembershipGroupInvite */
export type RevokeMembershipGroupInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inviteId: Scalars['ID']['input'];
};

/** Autogenerated return type of RevokeMembershipGroupInvite. */
export type RevokeMembershipGroupInvitePayload = {
  __typename?: 'RevokeMembershipGroupInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  invite?: Maybe<MembershipGroupInvite>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type RoamMessageLabelPrediction = {
  __typename?: 'RoamMessageLabelPrediction';
  annotationLabel: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type RoamMessageLabelPredictionAttributes = {
  _destroy?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  annotationLabel?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum RoleEnum {
  Acupuncturist = 'ACUPUNCTURIST',
  Admin = 'ADMIN',
  Allergist = 'ALLERGIST',
  AutomatedTemplateEditor = 'AUTOMATED_TEMPLATE_EDITOR',
  AutoReplyTemplateManager = 'AUTO_REPLY_TEMPLATE_MANAGER',
  BillingAdmin = 'BILLING_ADMIN',
  BirdseyeReview = 'BIRDSEYE_REVIEW',
  CalendarAdmin = 'CALENDAR_ADMIN',
  Cardiologist = 'CARDIOLOGIST',
  CareManager = 'CARE_MANAGER',
  CareNav = 'CARE_NAV',
  CentralizedAdmin = 'CENTRALIZED_ADMIN',
  CentralBillingSpecialist = 'CENTRAL_BILLING_SPECIALIST',
  CentralSupportSpecialistOne = 'CENTRAL_SUPPORT_SPECIALIST_ONE',
  CentralSupportSpecialistThree = 'CENTRAL_SUPPORT_SPECIALIST_THREE',
  CentralSupportSpecialistTwo = 'CENTRAL_SUPPORT_SPECIALIST_TWO',
  CeClinicalTrialTeam = 'CE_CLINICAL_TRIAL_TEAM',
  ChargeCaptureAndCodingSpecialists = 'CHARGE_CAPTURE_AND_CODING_SPECIALISTS',
  ChartPrepReview = 'CHART_PREP_REVIEW',
  Chiropractic = 'CHIROPRACTIC',
  ChronicCareProvider = 'CHRONIC_CARE_PROVIDER',
  ClientServices = 'CLIENT_SERVICES',
  ClinicalDocumentIntegrityReview = 'CLINICAL_DOCUMENT_INTEGRITY_REVIEW',
  ClinicalProgramsManager = 'CLINICAL_PROGRAMS_MANAGER',
  ClinicalSupportTeam = 'CLINICAL_SUPPORT_TEAM',
  Clubhouse = 'CLUBHOUSE',
  Colpo = 'COLPO',
  CommunityHealthWorker = 'COMMUNITY_HEALTH_WORKER',
  ContactAdmin = 'CONTACT_ADMIN',
  ContentAdmin = 'CONTENT_ADMIN',
  DceProvider = 'DCE_PROVIDER',
  Dermatologist = 'DERMATOLOGIST',
  DiabeticRetinopathyTeam = 'DIABETIC_RETINOPATHY_TEAM',
  DiscountTypeAdmin = 'DISCOUNT_TYPE_ADMIN',
  DuplicateReviewer = 'DUPLICATE_REVIEWER',
  EndometrialBiopsy = 'ENDOMETRIAL_BIOPSY',
  Engineer = 'ENGINEER',
  FamilyMedicine = 'FAMILY_MEDICINE',
  FrontDeskAdmin = 'FRONT_DESK_ADMIN',
  GoogleCepheidProvider = 'GOOGLE_CEPHEID_PROVIDER',
  GoogleNycWellness = 'GOOGLE_NYC_WELLNESS',
  GroupVisitCoordinator = 'GROUP_VISIT_COORDINATOR',
  GroupVisitFacilitator = 'GROUP_VISIT_FACILITATOR',
  Gyn = 'GYN',
  HealthCoach = 'HEALTH_COACH',
  HealthNavigator = 'HEALTH_NAVIGATOR',
  HighRiskCare = 'HIGH_RISK_CARE',
  HiResAnoscopy = 'HI_RES_ANOSCOPY',
  Hypnotherapist = 'HYPNOTHERAPIST',
  ImpactProvider = 'IMPACT_PROVIDER',
  IntegrativeHealthLabs = 'INTEGRATIVE_HEALTH_LABS',
  Itstuff = 'ITSTUFF',
  IudInserter = 'IUD_INSERTER',
  IudRemover = 'IUD_REMOVER',
  JaneStreetAdmin = 'JANE_STREET_ADMIN',
  LactationConsultant = 'LACTATION_CONSULTANT',
  Legal = 'LEGAL',
  LicensedPracticalNurse = 'LICENSED_PRACTICAL_NURSE',
  Manager = 'MANAGER',
  Marketer = 'MARKETER',
  MassCancellation = 'MASS_CANCELLATION',
  MediaAndSocialResponseTeam = 'MEDIA_AND_SOCIAL_RESPONSE_TEAM',
  MedicalRecordsAdmin = 'MEDICAL_RECORDS_ADMIN',
  MedicationAdmin = 'MEDICATION_ADMIN',
  MedStudent = 'MED_STUDENT',
  MembershipAdvisor = 'MEMBERSHIP_ADVISOR',
  MemberIdentityAdmin = 'MEMBER_IDENTITY_ADMIN',
  MemberTechnicalSupport = 'MEMBER_TECHNICAL_SUPPORT',
  MenopauseProvider = 'MENOPAUSE_PROVIDER',
  MentalHealth = 'MENTAL_HEALTH',
  MindsetEnterprise = 'MINDSET_ENTERPRISE',
  MindBody = 'MIND_BODY',
  Naturopath = 'NATUROPATH',
  NexplanonInserter = 'NEXPLANON_INSERTER',
  NexplanonRemover = 'NEXPLANON_REMOVER',
  Nutritionist = 'NUTRITIONIST',
  Ob = 'OB',
  OfficeRnNyc = 'OFFICE_RN_NYC',
  Osteopath = 'OSTEOPATH',
  OsteopathOnly = 'OSTEOPATH_ONLY',
  PanelManagementAdmin = 'PANEL_MANAGEMENT_ADMIN',
  Papper = 'PAPPER',
  ParetoAdmin = 'PARETO_ADMIN',
  Pcp = 'PCP',
  PeakmedCss = 'PEAKMED_CSS',
  Pediatrics = 'PEDIATRICS',
  PediatricsAdmin = 'PEDIATRICS_ADMIN',
  PedsUrgentCare = 'PEDS_URGENT_CARE',
  Pharmacist = 'PHARMACIST',
  Phlebotomist = 'PHLEBOTOMIST',
  PhysicalTherapy = 'PHYSICAL_THERAPY',
  PreconceptionCounseling = 'PRECONCEPTION_COUNSELING',
  PrenatalAdmin = 'PRENATAL_ADMIN',
  PrenatalUltrasound = 'PRENATAL_ULTRASOUND',
  ProblemTypeAdmin = 'PROBLEM_TYPE_ADMIN',
  Provider = 'PROVIDER',
  PsychiatricServices = 'PSYCHIATRIC_SERVICES',
  RadiologicTechnologist = 'RADIOLOGIC_TECHNOLOGIST',
  RespiratorProvider = 'RESPIRATOR_PROVIDER',
  Rn = 'RN',
  SeniorBehavioralHealth = 'SENIOR_BEHAVIORAL_HEALTH',
  SeniorHealthProvider = 'SENIOR_HEALTH_PROVIDER',
  SexualAndReproductiveHealthCss = 'SEXUAL_AND_REPRODUCTIVE_HEALTH_CSS',
  SocialWorker = 'SOCIAL_WORKER',
  SpacexWellness = 'SPACEX_WELLNESS',
  SportsMedicine = 'SPORTS_MEDICINE',
  Teacher = 'TEACHER',
  TemplateEditor = 'TEMPLATE_EDITOR',
  Ucp = 'UCP',
  UcsfOb = 'UCSF_OB',
  UltrasoundTechnician = 'ULTRASOUND_TECHNICIAN',
  UnlicensedMedicalAssistant = 'UNLICENSED_MEDICAL_ASSISTANT',
  UrgentCareProvider = 'URGENT_CARE_PROVIDER',
  Vaccinator = 'VACCINATOR',
  VaccineDocumenter = 'VACCINE_DOCUMENTER',
  VirtualPhysician = 'VIRTUAL_PHYSICIAN',
  VmtSupportQueueAccess = 'VMT_SUPPORT_QUEUE_ACCESS'
}

export type RoleType = Assignee & MessageRecipient & {
  __typename?: 'RoleType';
  assignable: Scalars['Boolean']['output'];
  /** Describes this role to patients for purposes of messaging */
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  globalId: Scalars['String']['output'];
  /** @deprecated Please use `normalizedId`. This ID field is an `int` but should be type `ID` */
  id: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /**
   * Once clients switch over to :normalized_id, we can change the deprecated :id
   * field to be of type ID and then switch clients back.
   */
  normalizedId: Scalars['ID']['output'];
  /** A display string that states how long a user of this role may take to respond to a patient message */
  responseTimeText?: Maybe<Scalars['String']['output']>;
  todoFilters: Array<Maybe<TodoFilter>>;
};

/** Autogenerated input type of RunBillingAdminTask */
export type RunBillingAdminTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  params: Scalars['String']['input'];
  task: Scalars['String']['input'];
};

/** Autogenerated return type of RunBillingAdminTask. */
export type RunBillingAdminTaskPayload = {
  __typename?: 'RunBillingAdminTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RunInsuranceEligibilityAutomaticVerification */
export type RunInsuranceEligibilityAutomaticVerificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  insuranceCarrierId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  subscriberNumber: Scalars['String']['input'];
};

/** Autogenerated return type of RunInsuranceEligibilityAutomaticVerification. */
export type RunInsuranceEligibilityAutomaticVerificationPayload = {
  __typename?: 'RunInsuranceEligibilityAutomaticVerificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Insurance plan object */
  insurancePlan?: Maybe<InsurancePlan>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
  /** Waystar status object */
  waystarEligibilityStatus?: Maybe<WaystarEligibilityStatus>;
  /** Waystar error - waystar text response */
  waystarError?: Maybe<Scalars['String']['output']>;
};

export type S3Attributes = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

/** S3 Pointer Metadata for an Outreach Batch */
export type S3Pointers = {
  __typename?: 'S3Pointers';
  bucket: Scalars['String']['output'];
  contentLength?: Maybe<Scalars['Int']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  outreachManagerBatch: OutreachManagerBatch;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Autogenerated input type of SaveAppointmentRecordingDetailedLaunchFeedback */
export type SaveAppointmentRecordingDetailedLaunchFeedbackInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  detailedLaunchFeedback: Scalars['JSON']['input'];
};

/** Autogenerated return type of SaveAppointmentRecordingDetailedLaunchFeedback. */
export type SaveAppointmentRecordingDetailedLaunchFeedbackPayload = {
  __typename?: 'SaveAppointmentRecordingDetailedLaunchFeedbackPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveAppointmentRecordingFeedback */
export type SaveAppointmentRecordingFeedbackInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedback: Array<AppointmentRecordingFeedbackInput>;
};

/** Autogenerated return type of SaveAppointmentRecordingFeedback. */
export type SaveAppointmentRecordingFeedbackPayload = {
  __typename?: 'SaveAppointmentRecordingFeedbackPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveScribeSettings */
export type SaveScribeSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  combinedSubjectiveSection?: InputMaybe<Scalars['Boolean']['input']>;
  defaultFields?: InputMaybe<Scalars['JSON']['input']>;
  displayAsNarrative?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SaveScribeSettings. */
export type SaveScribeSettingsPayload = {
  __typename?: 'SaveScribeSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveSmartDraftFeedback */
export type SaveSmartDraftFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedback: SmartDraftFeedback;
  smartDraftId: Scalars['ID']['input'];
};

/** Autogenerated return type of SaveSmartDraftFeedback. */
export type SaveSmartDraftFeedbackPayload = {
  __typename?: 'SaveSmartDraftFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  smartDraft?: Maybe<SmartDraft>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveSmartDraftText */
export type SaveSmartDraftTextInput = {
  action: SmartDraftSaveAction;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  editedText?: InputMaybe<Scalars['String']['input']>;
  smartDraftId: Scalars['ID']['input'];
};

/** Autogenerated return type of SaveSmartDraftText. */
export type SaveSmartDraftTextPayload = {
  __typename?: 'SaveSmartDraftTextPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveSuggestedProblemFeedback */
export type SaveSuggestedProblemFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  suggestedProblemId: Scalars['ID']['input'];
  userFeedback: Scalars['JSON']['input'];
};

/** Autogenerated return type of SaveSuggestedProblemFeedback. */
export type SaveSuggestedProblemFeedbackPayload = {
  __typename?: 'SaveSuggestedProblemFeedbackPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveThreadTagFeedback */
export type SaveThreadTagFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedback: MessageAnalyzerFeedback;
  llmOutputId: Scalars['ID']['input'];
};

/** Autogenerated return type of SaveThreadTagFeedback. */
export type SaveThreadTagFeedbackPayload = {
  __typename?: 'SaveThreadTagFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SaveUserSmartDraftSettings */
export type SaveUserSmartDraftSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SaveUserSmartDraftSettings. */
export type SaveUserSmartDraftSettingsPayload = {
  __typename?: 'SaveUserSmartDraftSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type ScheduleTemplate = {
  __typename?: 'ScheduleTemplate';
  color?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scheduleTemplateVariants: ScheduleTemplateVariantConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type ScheduleTemplateScheduleTemplateVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for ScheduleTemplate. */
export type ScheduleTemplateConnection = {
  __typename?: 'ScheduleTemplateConnection';
  /** A list of edges. */
  edges: Array<ScheduleTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ScheduleTemplateEdge = {
  __typename?: 'ScheduleTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleTemplate>;
};

export type ScheduleTemplateVariant = {
  __typename?: 'ScheduleTemplateVariant';
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  scheduleTemplate: ScheduleTemplate;
  scheduleTemplateId: Scalars['ID']['output'];
  slots: Array<ScheduleTemplateVariantSlot>;
  startTime?: Maybe<Scalars['Int']['output']>;
};

/** The connection type for ScheduleTemplateVariant. */
export type ScheduleTemplateVariantConnection = {
  __typename?: 'ScheduleTemplateVariantConnection';
  /** A list of edges. */
  edges: Array<ScheduleTemplateVariantEdge>;
  /** A list of nodes. */
  nodes: Array<ScheduleTemplateVariant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ScheduleTemplateVariantEdge = {
  __typename?: 'ScheduleTemplateVariantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduleTemplateVariant>;
};

export type ScheduleTemplateVariantSlot = {
  __typename?: 'ScheduleTemplateVariantSlot';
  appointmentTypeGroupIds: Array<Scalars['ID']['output']>;
  blocked: Scalars['Boolean']['output'];
  capacity: Scalars['Int']['output'];
  comments?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  label?: Maybe<Scalars['String']['output']>;
  /** Maximum allowed duration value to prevent overlap with upcoming slot */
  maxDuration: Scalars['Int']['output'];
  reserved: Scalars['Boolean']['output'];
  startTime: Scalars['Int']['output'];
};

export type ScrubbedWhitelistedEmployee = {
  __typename?: 'ScrubbedWhitelistedEmployee';
  id: Scalars['ID']['output'];
  registered: Scalars['Boolean']['output'];
};

export type SearchResult = {
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

/** Autogenerated input type of SendAppointmentConfirmationEmail */
export type SendAppointmentConfirmationEmailInput = {
  appointmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendAppointmentConfirmationEmail. */
export type SendAppointmentConfirmationEmailPayload = {
  __typename?: 'SendAppointmentConfirmationEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendEmailForChartClaiming */
export type SendEmailForChartClaimingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of SendEmailForChartClaiming. */
export type SendEmailForChartClaimingPayload = {
  __typename?: 'SendEmailForChartClaimingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendForgotPasswordEmail */
export type SendForgotPasswordEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  /** Set to true if the user is in the process of resetting their password for the purposes of enabling MFA. */
  mfaVerification?: InputMaybe<Scalars['Boolean']['input']>;
  usePreferredEmail?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SendForgotPasswordEmail. */
export type SendForgotPasswordEmailPayload = {
  __typename?: 'SendForgotPasswordEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendMembershipGroupInvite */
export type SendMembershipGroupInviteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of SendMembershipGroupInvite. */
export type SendMembershipGroupInvitePayload = {
  __typename?: 'SendMembershipGroupInvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  membershipGroupInvite?: Maybe<MembershipGroupInvite>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendRegistrationReminderEmail */
export type SendRegistrationReminderEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of SendRegistrationReminderEmail. */
export type SendRegistrationReminderEmailPayload = {
  __typename?: 'SendRegistrationReminderEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SendSmsForChartClaiming */
export type SendSmsForChartClaimingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendSmsForChartClaiming. */
export type SendSmsForChartClaimingPayload = {
  __typename?: 'SendSmsForChartClaimingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type SeniorHealthAdditionalEligibilityDetail = {
  __typename?: 'SeniorHealthAdditionalEligibilityDetail';
  editedBy?: Maybe<InternalUser>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<SeniorHealthAdditionalEligibilityDetailValue>;
};

export type SeniorHealthAdditionalEligibilityDetailValue = {
  __typename?: 'SeniorHealthAdditionalEligibilityDetailValue';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SeniorHealthCareStatus = {
  __typename?: 'SeniorHealthCareStatus';
  editedBy?: Maybe<InternalUser>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<SeniorHealthCareStatusValue>;
};

export type SeniorHealthCareStatusValue = {
  __typename?: 'SeniorHealthCareStatusValue';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SeniorHealthEnrollmentStatus = {
  __typename?: 'SeniorHealthEnrollmentStatus';
  editedBy?: Maybe<InternalUser>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  values: Array<SeniorHealthEnrollmentStatusValue>;
};

export type SeniorHealthEnrollmentStatusValue = {
  __typename?: 'SeniorHealthEnrollmentStatusValue';
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SeniorHealthPatientData = {
  __typename?: 'SeniorHealthPatientData';
  additionalEligibilityDetail?: Maybe<SeniorHealthAdditionalEligibilityDetail>;
  careStatus?: Maybe<SeniorHealthCareStatus>;
  enrollmentStatus?: Maybe<SeniorHealthEnrollmentStatus>;
  id: Scalars['ID']['output'];
  isInsuranceActive: Scalars['Boolean']['output'];
};

export type SeniorHealthReferenceData = {
  __typename?: 'SeniorHealthReferenceData';
  additionalEligibilityDetailValues: Array<SeniorHealthAdditionalEligibilityDetailValue>;
  careStatusValues: Array<SeniorHealthCareStatusValue>;
  enrollmentStatusValues: Array<SeniorHealthEnrollmentStatusValue>;
};

export enum Sentiment {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export type SentimentResponseInput = {
  /** Unique ID assigned to each feedback question (client-defined) */
  questionId: Scalars['String']['input'];
  /** The text of the question */
  questionText: Scalars['String']['input'];
  /** Response from the user, either positive or negative */
  response: Sentiment;
};

export type ServiceArea = {
  __typename?: 'ServiceArea';
  code?: Maybe<Scalars['String']['output']>;
  defaultOffice?: Maybe<Office>;
  /** @deprecated Please use the camelCase version. */
  default_office?: Maybe<Office>;
  faxNumber?: Maybe<Scalars['String']['output']>;
  hasHmoPartnerships: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  insuranceCarriers: Array<InsuranceCarrier>;
  latitude?: Maybe<Scalars['Float']['output']>;
  /**
   * The licensing body that this service area belongs to. This is effectively a
   * StateType, though non-states like DC are included.
   * If null, it means this service area is virtual so any licensing body applies.
   *
   * If you're trying to determine the licensing body for a patient, use patient ->
   * licensingBody instead. In the future, it's possible that the licensing body is
   * determined from a combination of address and service area, so try not to
   * couple your client to the service area -> licensing body approach.
   */
  licensingBody?: Maybe<State>;
  locationDescription?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  offices?: Maybe<Array<Office>>;
  registrationHidden?: Maybe<Scalars['Boolean']['output']>;
  serviceAreaGroup?: Maybe<ServiceAreaGroup>;
  stripePlan?: Maybe<StripePlan>;
  /** @deprecated Please use the camelCase version. */
  stripe_plan?: Maybe<StripePlan>;
  timezone?: Maybe<Scalars['TZInfo']['output']>;
  virtual: Scalars['Boolean']['output'];
};


export type ServiceAreaInsuranceCarriersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type ServiceAreaOfficesArgs = {
  excludeVirtual?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ServiceAreaAggregation = {
  __typename?: 'ServiceAreaAggregation';
  buckets: Array<ServiceAreaBucket>;
  docCountErrorUpperBound: Scalars['Int']['output'];
  sumOtherDocCount: Scalars['Int']['output'];
};

export type ServiceAreaBucket = {
  __typename?: 'ServiceAreaBucket';
  docCount: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  noteState: TransmissionState;
  noteStateWithServiceTicketTransmissionState: NoteStateWithServiceTicketTransmission;
  serviceTicketTransmissionState: TransmissionState;
};

export type ServiceAreaGroup = {
  __typename?: 'ServiceAreaGroup';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  serviceAreas?: Maybe<Array<ServiceArea>>;
};

export type ServiceTicket = {
  __typename?: 'ServiceTicket';
  administrativeComments?: Maybe<Scalars['String']['output']>;
  appointment?: Maybe<Appointment>;
  id: Scalars['ID']['output'];
  incompleteReasons: Array<Scalars['String']['output']>;
  internalUser: InternalUser;
  labOrders?: Maybe<Array<LabOrder>>;
  processingInstructions?: Maybe<Scalars['String']['output']>;
  serviceTicketItems: Array<ServiceTicketItem>;
  serviceTicketTransmissionId?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  visitProcedure?: Maybe<ServiceTicketItem>;
  visitSummary?: Maybe<Summary>;
};

export type ServiceTicketImproperlyClosedOrder = {
  __typename?: 'ServiceTicketImproperlyClosedOrder';
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  possibleServiceTicket?: Maybe<ServiceTicket>;
  reason?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

/** The connection type for ServiceTicketImproperlyClosedOrder. */
export type ServiceTicketImproperlyClosedOrdersConnection = {
  __typename?: 'ServiceTicketImproperlyClosedOrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ServiceTicketImproperlyClosedOrdersEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ServiceTicketImproperlyClosedOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** represents an edge in the connection for ServiceTicketImproperlyClosedOrders, a node is a singular order */
export type ServiceTicketImproperlyClosedOrdersEdge = {
  __typename?: 'ServiceTicketImproperlyClosedOrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ServiceTicketImproperlyClosedOrder>;
};

export type ServiceTicketItem = {
  __typename?: 'ServiceTicketItem';
  billingAmount?: Maybe<Scalars['Float']['output']>;
  billingAmountOwed: Scalars['Float']['output'];
  billingMethod?: Maybe<Scalars['String']['output']>;
  /** Informs if the item is marked as self pay */
  billingMethodIsSelfPay: Scalars['Boolean']['output'];
  /** CPT codes for a given service ticket item */
  cpts?: Maybe<Array<Scalars['String']['output']>>;
  /** Direct billing packages that this item is covered by */
  enterpriseQualifyingBillingPackages: Array<Scalars['String']['output']>;
  /** Informs if the item is hidden from the note */
  hideFromNote: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Indications for a given service ticket item */
  indications?: Maybe<Array<Scalars['String']['output']>>;
  /** Displays if a Visit Procedure has new findings */
  newFindings?: Maybe<Scalars['Boolean']['output']>;
  patientCharge?: Maybe<PatientCharge>;
  /** Displays if an item has been marked as direct bill automatically. */
  patientDirectBillable?: Maybe<Scalars['Boolean']['output']>;
  patientResponsibilityType?: Maybe<Scalars['String']['output']>;
  /**
   * Informs if the item is marked as self pay
   * @deprecated Replaced by billingMethodIsSelfPay
   */
  selfPay?: Maybe<Scalars['Boolean']['output']>;
  /** Amount in cents that should be paid if doing self-pay */
  selfPayAmount?: Maybe<Scalars['Float']['output']>;
  selfPayReason?: Maybe<Scalars['String']['output']>;
  /** Informs if the item is able to be marked self pay */
  selfPayable: Scalars['Boolean']['output'];
  serviceTicketId?: Maybe<Scalars['Int']['output']>;
  /** Shorter display title for a service ticket item */
  shortTitle?: Maybe<Scalars['String']['output']>;
  /** Display summary for a service ticket item */
  summary?: Maybe<Scalars['String']['output']>;
  /** Display title for a service ticket item */
  title?: Maybe<Scalars['String']['output']>;
  /** Displays if a Visit Procedure is Virtual */
  virtual?: Maybe<Scalars['Boolean']['output']>;
};

export type ServiceTicketPaymentTransmission = {
  __typename?: 'ServiceTicketPaymentTransmission';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastTransmissionAttemptAt?: Maybe<Scalars['DateTime']['output']>;
  numberOfAttempts?: Maybe<Scalars['Int']['output']>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  patientChargePayment: PatientChargePayment;
  state: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ServiceTicketTransmission = {
  __typename?: 'ServiceTicketTransmission';
  /** Service Ticket Items Associated to  */
  closedServiceTicketItems: Array<ServiceTicketItem>;
  /** Service Tickets Associated to  */
  closedServiceTickets: Array<ServiceTicket>;
  createdAt: Scalars['DateTime']['output'];
  /** Output for a rules engine run in debug mode */
  debugOutput?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastHl7Message?: Maybe<Scalars['String']['output']>;
  lastTransmissionAttemptAt?: Maybe<Scalars['DateTime']['output']>;
  markedReadyAt?: Maybe<Scalars['DateTime']['output']>;
  mutatedData?: Maybe<Scalars['String']['output']>;
  numberOfAttempts?: Maybe<Scalars['Int']['output']>;
  openPracticeMessages: Array<OpenPracticeMessage>;
  parsedErrorMessage?: Maybe<Scalars['String']['output']>;
  patient: Patient;
  /** Service Ticket Payment Transmissions associated with service tickets */
  serviceTicketPaymentTransmissions?: Maybe<Array<ServiceTicketPaymentTransmission>>;
  serviceTickets: Array<ServiceTicket>;
  state: Scalars['String']['output'];
  waystarEligibilityStatuses?: Maybe<Array<WaystarEligibilityStatus>>;
};

export type ServiceTicketTransmissionAggregations = {
  __typename?: 'ServiceTicketTransmissionAggregations';
  serviceArea: ServiceAreaAggregation;
};

export type ServiceTicketTransmissionSearch = {
  __typename?: 'ServiceTicketTransmissionSearch';
  aggregations?: Maybe<ServiceTicketTransmissionAggregations>;
  totalHits: Scalars['Int']['output'];
};

/** Autogenerated input type of SetProviderIn */
export type SetProviderInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SetProviderIn. */
export type SetProviderInPayload = {
  __typename?: 'SetProviderInPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  internalUser?: Maybe<InternalUser>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SetProviderOut */
export type SetProviderOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  returningOn: Scalars['Date']['input'];
};

/** Autogenerated return type of SetProviderOut. */
export type SetProviderOutPayload = {
  __typename?: 'SetProviderOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  internalUser?: Maybe<InternalUser>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type SexualOrientation = {
  __typename?: 'SexualOrientation';
  description?: Maybe<Scalars['String']['output']>;
  snomedCode?: Maybe<Scalars['String']['output']>;
};

export type ShipContainer = {
  __typename?: 'ShipContainer';
  destinationLabs?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  originalsNeeded?: Maybe<Scalars['Boolean']['output']>;
  pcsVolume: Scalars['Float']['output'];
  pcsVolumeUnits?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sampleDescription?: Maybe<Scalars['String']['output']>;
  sampleType: Scalars['String']['output'];
  sampleTypeDescription?: Maybe<Scalars['String']['output']>;
  storageCodeDescription?: Maybe<Scalars['String']['output']>;
  storageShortName?: Maybe<Scalars['String']['output']>;
  unopenedNeeded?: Maybe<Scalars['Boolean']['output']>;
  yieldVolume: Scalars['Float']['output'];
  yieldVolumeUnits?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SignLegalDocument */
export type SignLegalDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

/** Autogenerated return type of SignLegalDocument. */
export type SignLegalDocumentPayload = {
  __typename?: 'SignLegalDocumentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  legalDocument?: Maybe<LegalDocument>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type SignedS3Url = {
  __typename?: 'SignedS3Url';
  getUrl?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  urlFields?: Maybe<Scalars['JSON']['output']>;
};

export type SlotInput = {
  appointmentTypeGroupIds: Array<Scalars['ID']['input']>;
  blocked: Scalars['Boolean']['input'];
  capacity?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  reserved: Scalars['Boolean']['input'];
  startTime: Scalars['Int']['input'];
};

export type SmartDraft = {
  __typename?: 'SmartDraft';
  feedback?: Maybe<SmartDraftFeedback>;
  id: Scalars['ID']['output'];
  replyInstructions: Scalars['HTML']['output'];
  state: SmartDraftState;
  text?: Maybe<Scalars['HTML']['output']>;
};

export enum SmartDraftFeedback {
  /** @deprecated Feedback value no longer allowed */
  Copy = 'COPY',
  Downvote = 'DOWNVOTE',
  Good = 'GOOD',
  Unusable = 'UNUSABLE',
  Upvote = 'UPVOTE',
  Usable = 'USABLE'
}

export enum SmartDraftSaveAction {
  Draft = 'DRAFT',
  Save = 'SAVE',
  Send = 'SEND',
  Update = 'UPDATE'
}

export enum SmartDraftState {
  Complete = 'COMPLETE',
  Enqueued = 'ENQUEUED',
  Failed = 'FAILED',
  Running = 'RUNNING'
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Source {
  System = 'SYSTEM',
  User = 'USER'
}

export type SpecialInstruction = {
  __typename?: 'SpecialInstruction';
  code?: Maybe<Scalars['String']['output']>;
  instruction?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Specialty = {
  __typename?: 'Specialty';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SpecialtyType = {
  __typename?: 'SpecialtyType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of StartAmazonClinicVisit */
export type StartAmazonClinicVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  visitId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartAmazonClinicVisit. */
export type StartAmazonClinicVisitPayload = {
  __typename?: 'StartAmazonClinicVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of StartAppointmentRecording */
export type StartAppointmentRecordingInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StartAppointmentRecording. */
export type StartAppointmentRecordingPayload = {
  __typename?: 'StartAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of StartCareflow */
export type StartCareflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of Awell Careflow Pathway to be started */
  pathwayId: Scalars['ID']['input'];
  /** 1Life ID of Patient the Careflow Pathway is being started for */
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartCareflow. */
export type StartCareflowPayload = {
  __typename?: 'StartCareflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** URL of active Careflow to redirect client to */
  sessionUrl?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of StartManualHieQuery */
export type StartManualHieQueryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of StartManualHieQuery. */
export type StartManualHieQueryPayload = {
  __typename?: 'StartManualHieQueryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of StartStreamingSession */
export type StartStreamingSessionInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StartStreamingSession. */
export type StartStreamingSessionPayload = {
  __typename?: 'StartStreamingSessionPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type State = {
  __typename?: 'State';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  /** @deprecated Please use the camelCase version. */
  short_name: Scalars['String']['output'];
};

export type StateBucket = {
  __typename?: 'StateBucket';
  docCount: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

/** A recommended step drug */
export type StepDrug = {
  __typename?: 'StepDrug';
  diagnosisCode?: Maybe<Scalars['String']['output']>;
  drugName?: Maybe<Scalars['String']['output']>;
  generic?: Maybe<Scalars['Boolean']['output']>;
  numberToTry?: Maybe<Scalars['Int']['output']>;
  otc?: Maybe<Scalars['Boolean']['output']>;
  stepOrder?: Maybe<Scalars['String']['output']>;
  supply?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of StopAppointmentRecording */
export type StopAppointmentRecordingInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  defaultFields?: InputMaybe<Scalars['JSON']['input']>;
  scribeSettings?: InputMaybe<InternalUserSettingsInput>;
};

/** Autogenerated return type of StopAppointmentRecording. */
export type StopAppointmentRecordingPayload = {
  __typename?: 'StopAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type StripePlan = Plan & {
  __typename?: 'StripePlan';
  amount?: Maybe<Scalars['Int']['output']>;
  amountInDollars?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Please use the camelCase version. */
  amount_in_dollars?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  clientSecret?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
};

export type SuggestedProblem = {
  __typename?: 'SuggestedProblem';
  id: Scalars['ID']['output'];
  problemId?: Maybe<Scalars['ID']['output']>;
  problemTypeId: Scalars['ID']['output'];
  userFeedback?: Maybe<Scalars['JSON']['output']>;
};

export type Summary = {
  __typename?: 'Summary';
  appointment?: Maybe<Appointment>;
  id: Scalars['ID']['output'];
  internalUser?: Maybe<InternalUser>;
  note?: Maybe<Note>;
  procedureSuggestions?: Maybe<Array<Maybe<MlBillingProcedureSuggestion>>>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The appointment that is linked to the Summary through the Encounter. Which exist when appointment is first booked. */
  visitAppointment?: Maybe<Appointment>;
};

export type SummaryLine = {
  __typename?: 'SummaryLine';
  id: Scalars['ID']['output'];
  rating?: Maybe<Scalars['Int']['output']>;
  segmentIds: Array<Scalars['ID']['output']>;
  summary: Scalars['String']['output'];
};

export enum SummaryPlanSectionEnum {
  Plan = 'PLAN',
  ProblemBasedAp = 'PROBLEM_BASED_AP'
}

export type SupportedAgeRange = {
  __typename?: 'SupportedAgeRange';
  id: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** A set of structured questions for Patients. */
export type Survey = {
  __typename?: 'Survey';
  active: Scalars['Boolean']['output'];
  allowMultiplePerPatient: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  introduction?: Maybe<Scalars['String']['output']>;
  /** A human readable name for this survey. */
  layName: Scalars['String']['output'];
  /** An alias for `layName`. */
  name: Scalars['String']['output'];
  singlePageForm: Scalars['Boolean']['output'];
  systemName: Scalars['String']['output'];
  /** An alias for `layName`. */
  title: Scalars['String']['output'];
  uniqueName: Scalars['String']['output'];
};

/** Type for Survey Order Outreach */
export type SurveyOrderOutreach = OutreachManagerBatch & {
  __typename?: 'SurveyOrderOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']['output']>;
  /** Survey expiration date */
  dueDate: Scalars['DateTime']['output'];
  /** A unique identifier for this record. */
  id: Scalars['ID']['output'];
  /** Note for outreach shown to patients */
  note?: Maybe<Scalars['String']['output']>;
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** The survey the outreach was created for */
  survey: Survey;
  /** Title for outreach shown to patients */
  title?: Maybe<Scalars['String']['output']>;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime']['output'];
};


/** Type for Survey Order Outreach */
export type SurveyOrderOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

/** Autogenerated input type of SyncPatient */
export type SyncPatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of SyncPatient. */
export type SyncPatientPayload = {
  __typename?: 'SyncPatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** Patient object */
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

export type SystemMessagePayload = {
  __typename?: 'SystemMessagePayload';
  /** Payload text content; will be null if the content has not yet been generate. */
  content: Scalars['String']['output'];
  /** If true, this system message will need to be provided to the toucanGenerate mutation to populate the content field. */
  requiresSystemMessageInput: Scalars['Boolean']['output'];
  /** must be "systemMessage". */
  type: Scalars['String']['output'];
  /** Indicates the urgency level with which this system message needs to be displayed by the client. */
  urgency: SystemMessageUrgency;
};

export type SystemMessagePayloadInput = {
  content: Scalars['String']['input'];
  requiresSystemMessageInput: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
  urgency: SystemMessageUrgency;
};

export enum SystemMessageUrgency {
  Emergency = 'emergency',
  NotUrgent = 'not_urgent',
  Urgent = 'urgent'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Task = {
  __typename?: 'Task';
  assignee?: Maybe<InternalUser>;
  assigneeIdent?: Maybe<Scalars['String']['output']>;
  associatedWith?: Maybe<TaskAssociation>;
  associatedWithId?: Maybe<Scalars['ID']['output']>;
  associatedWithType?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['HTML']['output']>;
  category?: Maybe<TaskCategory>;
  categoryId?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  deferUntil?: Maybe<Scalars['DateTime']['output']>;
  displayCategory: Scalars['String']['output'];
  finishedBy?: Maybe<InternalUser>;
  finishedByThankYou: Scalars['Boolean']['output'];
  /** Describes task history in descending order, from most recent to oldest. Returns empty list otherwise. */
  history: Array<TaskHistory>;
  id: Scalars['ID']['output'];
  isAssociatedWithPriorityLabResults: Scalars['Boolean']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  licensingBody?: Maybe<LicensingBody>;
  name: Scalars['String']['output'];
  nextActions: Scalars['JSON']['output'];
  nextTaskTemplates?: Maybe<Array<TaskTemplate>>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['ID']['output']>;
  priority: Priority;
  relatedTaskTemplates?: Maybe<Array<TaskTemplate>>;
  roamMessageLabelPredictions: Array<RoamMessageLabelPrediction>;
  slaDueAt?: Maybe<Scalars['DateTime']['output']>;
  state: Scalars['String']['output'];
  stateTransitionedAt?: Maybe<Scalars['DateTime']['output']>;
  subcategory?: Maybe<TaskCategory>;
  tags?: Maybe<Array<Tag>>;
  targetUrl?: Maybe<Scalars['String']['output']>;
  taskTemplate?: Maybe<TaskTemplate>;
  updatedAt: Scalars['DateTime']['output'];
};


export type TaskHistoryArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskAssignee = {
  __typename?: 'TaskAssignee';
  databaseId: Scalars['ID']['output'];
  doctype: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  outUntil?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
};

export enum TaskAssigneeSearchStrategy {
  BoostRoles = 'BOOST_ROLES',
  ExcludeRoles = 'EXCLUDE_ROLES',
  IncludeRoles = 'INCLUDE_ROLES'
}

/** What is the object on the graph that this task relates to */
export type TaskAssociation = Document | Insurance | LabOrder | MedicareAdvantageAlignment | Note | OriginalMedicareAcoReach | OriginalMedicareAlignment | ProcedureInteraction | ProcedureOrder | TimelinePost;

export type TaskCategory = {
  __typename?: 'TaskCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TaskHistory = {
  __typename?: 'TaskHistory';
  changedAt?: Maybe<Scalars['DateTime']['output']>;
  changedBy?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  detail: Scalars['String']['output'];
};

export type TaskTemplate = {
  __typename?: 'TaskTemplate';
  id: Scalars['ID']['output'];
  isChangeable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  standardWorkUrl?: Maybe<Scalars['String']['output']>;
};

export type TasksAggregations = {
  __typename?: 'TasksAggregations';
  /**
   * These are raw aggregations results from the Elasticsearch query. WARNING: The
   * structure of this field is subject to change with the change of the
   * Elasticsearch index. A typed `aggregations`field should be added if more
   * stability is desired.
   */
  aggregationsRaw: Scalars['JSON']['output'];
};

/** The connection type for Measurement. */
export type TemperatureMeasurementConnection = {
  __typename?: 'TemperatureMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  validationRules?: Maybe<FloatMeasurementValidationRule>;
};

export type TemplatedResponse = ErrorResponse | Response;

export type TextTemplate = {
  __typename?: 'TextTemplate';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalUserId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  purpose?: Maybe<Scalars['Int']['output']>;
};

export type ThreadTag = {
  __typename?: 'ThreadTag';
  llmOutputId?: Maybe<Scalars['ID']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
};

export type TimelineComment = Remindable & {
  __typename?: 'TimelineComment';
  chartUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  draft: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  messagePreview: Scalars['String']['output'];
  /**
   * class name for a TimelineComment
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String']['output'];
  /** whether it is unread */
  notifiableStatus?: Maybe<Scalars['String']['output']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']['output']>;
  /** notification subtitle */
  notificationSubtitle?: Maybe<Scalars['String']['output']>;
  /** notification title */
  notificationTitle: Scalars['String']['output'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String']['output'];
  patient: Patient;
  postedBy: MessageSender;
  /** The internal user who posted this post */
  postedByInternalUser?: Maybe<Provider>;
  /**
   * The internal user who posted this post
   * @deprecated Please use the camelCase version.
   */
  posted_by_internal_user?: Maybe<Provider>;
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  /** weight for TimelineComment reminders */
  sortWeight: Scalars['Int']['output'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int']['output'];
  text?: Maybe<Scalars['HTML']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
  /** universal link for the message */
  universalLink: Scalars['String']['output'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TimelinePost = Remindable & {
  __typename?: 'TimelinePost';
  chartUrl: Scalars['String']['output'];
  /** Returns only non-draft comments by default. */
  comments: Array<TimelineComment>;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  draft: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  messagePreview: Scalars['String']['output'];
  /**
   * class name for a TimelinePost
   * @deprecated Field is redundant, use reminder.type instead
   */
  name: Scalars['String']['output'];
  /** whether it is unread */
  notifiableStatus?: Maybe<Scalars['String']['output']>;
  /**
   * string to indicate a status of the remindable object
   * @deprecated Please use the camelCase version.
   */
  notifiable_status?: Maybe<Scalars['String']['output']>;
  /** notification subtitle for TimelinePost */
  notificationSubtitle?: Maybe<Scalars['String']['output']>;
  /** notification title for TimelinePost */
  notificationTitle: Scalars['String']['output'];
  /**
   * notification subtitle based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_subtitle?: Maybe<Scalars['String']['output']>;
  /**
   * notification title based on remindable type
   * @deprecated Please use the camelCase version.
   */
  notification_title: Scalars['String']['output'];
  patient: Patient;
  postedBy: MessageSender;
  recipient: MessageRecipient;
  /** Long-form description of this object */
  reminderDescription?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  /** weight for TimelinePost reminders */
  sortWeight: Scalars['Int']['output'];
  /**
   * a weight given to remindable objects to define sort order
   * @deprecated Please use the camelCase version.
   */
  sort_weight: Scalars['Int']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  /** The text content of this post. */
  textContent?: Maybe<TimelinePostTextContent>;
  /**
   * The text content of this post.
   * @deprecated Please use the camelCase version.
   */
  text_content?: Maybe<TimelinePostTextContent>;
  threadTag?: Maybe<ThreadTag>;
  /**
   * If the internal Todo associated with this post's conversation was reassigned
   * by ML, then it will be returned. If it was manually reassigned or not
   * reassigned, then null is returned.
   */
  todoMessageReassignment?: Maybe<TodoMessageReassignment>;
  topic?: Maybe<Scalars['String']['output']>;
  /** universal link for the message */
  universalLink: Scalars['String']['output'];
  /**
   * universal link for notification
   * @deprecated Please use the camelCase version.
   */
  universal_link: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type TimelinePostCommentsArgs = {
  includeDraftComments?: InputMaybe<Scalars['Boolean']['input']>;
};


export type TimelinePostTodoMessageReassignmentArgs = {
  onlyReassigned?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The connection type for TimelinePost. */
export type TimelinePostConnection = {
  __typename?: 'TimelinePostConnection';
  /** A list of edges. */
  edges: Array<TimelinePostEdge>;
  /** A list of nodes. */
  nodes: Array<TimelinePost>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TimelinePostEdge = {
  __typename?: 'TimelinePostEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TimelinePost>;
};

export enum TimelinePostSort {
  /**
   * Sorts posts by sent date. If a post has comments, the most recent sent date is
   * used for sorting. If a post does not have comments, the post's sent date is
   * used for sorting.
   */
  RecentActivity = 'RecentActivity'
}

export type TimelinePostTextContent = {
  __typename?: 'TimelinePostTextContent';
  id: Scalars['ID']['output'];
  /** @deprecated Part of the legacy messaging model. Use Types::TimelinePostType.recipient */
  notificationTarget?: Maybe<Scalars['String']['output']>;
  /** @deprecated Part of the legacy messaging model. Use Types::TimelinePostType.recipient */
  notification_target?: Maybe<Scalars['String']['output']>;
  /** The provider who posted this post */
  postedByInternalUser?: Maybe<Provider>;
  /**
   * The provider who posted this post
   * @deprecated Please use the camelCase version.
   */
  posted_by_internal_user?: Maybe<Provider>;
  /** The subject topic for this post. */
  subject?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['HTML']['output']>;
};

export type TodoFilter = {
  __typename?: 'TodoFilter';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TodoMessageReassignment = {
  __typename?: 'TodoMessageReassignment';
  feedback?: Maybe<TodoMessageReassignmentFeedback>;
  id: Scalars['ID']['output'];
  labels: Array<Scalars['String']['output']>;
  newAssignee?: Maybe<Assignee>;
  originalAssignee?: Maybe<Assignee>;
  reason: Scalars['String']['output'];
  wasReassigned: Scalars['Boolean']['output'];
};

export type TodoMessageReassignmentFeedback = {
  __typename?: 'TodoMessageReassignmentFeedback';
  feedbackCategory?: Maybe<Scalars['String']['output']>;
  feedbackText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newAssignee?: Maybe<Assignee>;
  todoMessageReassignment: TodoMessageReassignment;
};

/** Autogenerated input type of ToucanContinueConversation */
export type ToucanContinueConversationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['String']['input'];
  /** Dialog turn with the payload populated with the user's response */
  dialogTurn: ToucanDialogTurnInput;
};

/** Autogenerated return type of ToucanContinueConversation. */
export type ToucanContinueConversationPayload = {
  __typename?: 'ToucanContinueConversationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Contains conversation ID and an array of dialog turns */
  conversation?: Maybe<ToucanConversation>;
  errorType?: Maybe<ErrorTypes>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** HTTP-like status code indicating success (200) or failure */
  status: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

export type ToucanConversation = {
  __typename?: 'ToucanConversation';
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['ID']['output'];
  /** An array containing dialog turns */
  dialogTurns: Array<ToucanDialogTurn>;
};

export type ToucanDialogTurn = {
  __typename?: 'ToucanDialogTurn';
  /** ID of the current dialog turn (UUID) */
  dialogTurnId: Scalars['String']['output'];
  /** A payload whose content was or will need to be generated via a subsequent request to the toucanGenerate mutation. */
  generatedResponsePayload?: Maybe<GeneratedResponsePayload>;
  /** If true, indicates that IHS considers this the end of the conversation. */
  isTerminal: Scalars['Boolean']['output'];
  /** This payload allows a multiple choice question to be presented to the user. */
  multipleChoicePayload?: Maybe<MultipleChoicePayload>;
  /** Indicates weather a given DialogTurn was created by the client or IHS. Value is either "SYSTEM" or "USER". */
  source: Source;
  /** A message from the backend that needs to be shown to the user. */
  systemMessagePayload?: Maybe<SystemMessagePayload>;
  /** This payload allows the user to respond to a dialog with a text response. */
  userFreetextPayload?: Maybe<UserFreeTextPayload>;
};

export type ToucanDialogTurnInput = {
  /** ID of the current dialog turn (UUID) */
  dialogTurnId: Scalars['String']['input'];
  /** Not used since the user won't be the source of generated responses */
  generatedResponsePayload?: InputMaybe<GeneratedResponsePayloadInput>;
  /** If true, indicates that IHS considers this the end of the conversation. */
  isTerminal: Scalars['Boolean']['input'];
  /** Multiple choice payload populated with the user's answer(s) */
  multipleChoicePayload?: InputMaybe<MultipleChoicePayloadInput>;
  /** Set to "USER" to indicate this dialog turn is a response from the user. */
  source: Source;
  /** Not used since the user won't be the source of system messages */
  systemMessagePayload?: InputMaybe<SystemMessagePayloadInput>;
  /** Free text payload populated with response text from the user's */
  userFreetextPayload?: InputMaybe<UserFreeTextPayloadInput>;
};

export type ToucanFeedbackResponseInput = {
  /** Zero or more responses for free-text feedback questions */
  freetextResponse?: InputMaybe<Array<FreetextResponseInput>>;
  /** Zero or more responses for multiple choice feedback questions */
  multipleChoiceResponse?: InputMaybe<Array<MultipleChoiceResponseInput>>;
  /** Zero or more responses for sentiment feedback questions */
  sentimentResponse?: InputMaybe<Array<SentimentResponseInput>>;
};

/** Autogenerated input type of ToucanGenerate */
export type ToucanGenerateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['String']['input'];
  /** ID of the dialog turn whose content to be generated (UUID) */
  dialogTurnId: Scalars['String']['input'];
};

/** Autogenerated return type of ToucanGenerate. */
export type ToucanGeneratePayload = {
  __typename?: 'ToucanGeneratePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<ErrorTypes>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** HTTP-like status code indicating success (200) or failure */
  status: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ToucanNewConversation */
export type ToucanNewConversationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The initial prompt to start a new conversation */
  content: Scalars['String']['input'];
};

/** Autogenerated return type of ToucanNewConversation. */
export type ToucanNewConversationPayload = {
  __typename?: 'ToucanNewConversationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Contains conversation ID and an array of dialog turns */
  conversation?: Maybe<ToucanConversation>;
  errorType?: Maybe<ErrorTypes>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** HTTP-like status code indicating success (200) or failure */
  status: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of ToucanRegisterFeedback */
export type ToucanRegisterFeedbackInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** ID of the current conversation (UUID) */
  conversationId: Scalars['String']['input'];
  /** ID of the dialog turn this feedback applies to */
  dialogTurnId: Scalars['String']['input'];
  /** Feedback responses from the user */
  feedbackResponse: ToucanFeedbackResponseInput;
};

/** Autogenerated return type of ToucanRegisterFeedback. */
export type ToucanRegisterFeedbackPayload = {
  __typename?: 'ToucanRegisterFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Message returned by IHS-Service */
  message?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  /** HTTP-like status code indicating success (200) or failure */
  status: Scalars['Int']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of TranscribeAppointmentRecording */
export type TranscribeAppointmentRecordingInput = {
  appointmentRecordingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of TranscribeAppointmentRecording. */
export type TranscribeAppointmentRecordingPayload = {
  __typename?: 'TranscribeAppointmentRecordingPayload';
  appointmentRecording?: Maybe<AppointmentRecording>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type TranscriptSegment = {
  __typename?: 'TranscriptSegment';
  content: Scalars['String']['output'];
  participant: Scalars['String']['output'];
  segmentId: Scalars['ID']['output'];
};

export type TransmissionState = {
  __typename?: 'TransmissionState';
  buckets: Array<StateBucket>;
  docCountErrorUpperBound: Scalars['Int']['output'];
  sumOtherDocCount: Scalars['Int']['output'];
};

/** Autogenerated input type of TransmitServiceTicketTransmission */
export type TransmitServiceTicketTransmissionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of TransmitServiceTicketTransmission. */
export type TransmitServiceTicketTransmissionPayload = {
  __typename?: 'TransmitServiceTicketTransmissionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  numberQueued?: Maybe<Scalars['Int']['output']>;
  serviceTicketTransmission?: Maybe<ServiceTicketTransmission>;
  serviceTicketTransmissions?: Maybe<Array<ServiceTicketTransmission>>;
  success: Scalars['Boolean']['output'];
};

export type TransmittedAggregationResult = {
  __typename?: 'TransmittedAggregationResult';
  /** Aggregation results by date range */
  aggregations?: Maybe<Aggregation>;
  /** Total hits for the transmitted aggregation search */
  totalHits: Scalars['Int']['output'];
};

/** Autogenerated input type of TriggerAppointment */
export type TriggerAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TriggerAppointment. */
export type TriggerAppointmentPayload = {
  __typename?: 'TriggerAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of TriggerDocumentStatusEvent */
export type TriggerDocumentStatusEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  event: DocumentStatusEvent;
  /** Used by file event only, will throw without `file_options` if event is `file` */
  fileOptions?: InputMaybe<FileDocumentInput>;
};

/** Autogenerated return type of TriggerDocumentStatusEvent. */
export type TriggerDocumentStatusEventPayload = {
  __typename?: 'TriggerDocumentStatusEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of TriggerTask */
export type TriggerTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deferUntil?: InputMaybe<Scalars['DateTime']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of TriggerTask. */
export type TriggerTaskPayload = {
  __typename?: 'TriggerTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of UnarchiveInsurance */
export type UnarchiveInsuranceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UnarchiveInsurance. */
export type UnarchiveInsurancePayload = {
  __typename?: 'UnarchiveInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UnlinkAmazonAccount */
export type UnlinkAmazonAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The login email the patient will have after unlinking */
  loginEmail: Scalars['String']['input'];
  /** The ID of the patient */
  patientId: Scalars['ID']['input'];
  /** The reason why the unlinking is taking place */
  reason: Scalars['String']['input'];
  /** Determines if unlinking confirmation email includes password reset instructions */
  sendPasswordResetEmail: Scalars['Boolean']['input'];
};

/** Autogenerated return type of UnlinkAmazonAccount. */
export type UnlinkAmazonAccountPayload = {
  __typename?: 'UnlinkAmazonAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UnlinkProblemAttachment */
export type UnlinkProblemAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  problemAttachmentId: Scalars['ID']['input'];
  problemAttachmentType: ProblemAttachmentAttachmentEnum;
  problemId: Scalars['ID']['input'];
};

/** Autogenerated return type of UnlinkProblemAttachment. */
export type UnlinkProblemAttachmentPayload = {
  __typename?: 'UnlinkProblemAttachmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  problem?: Maybe<Problem>;
  success: Scalars['Boolean']['output'];
};

export type UnregisteredPatient = {
  __typename?: 'UnregisteredPatient';
  id: Scalars['ID']['output'];
  info: Scalars['String']['output'];
};

export type UnregisteredPatientAttributes = {
  dob?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isGuest?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateAdditionalEligibilityDetail */
export type UpdateAdditionalEligibilityDetailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  valueId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateAdditionalEligibilityDetail. */
export type UpdateAdditionalEligibilityDetailPayload = {
  __typename?: 'UpdateAdditionalEligibilityDetailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patientData?: Maybe<SeniorHealthPatientData>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateAddress */
export type UpdateAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateAddress. */
export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateAdmission */
export type UpdateAdmissionInput = {
  admitReason?: InputMaybe<Scalars['String']['input']>;
  admittedAt?: InputMaybe<Scalars['Date']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dischargeType?: InputMaybe<AdmissionDischargeType>;
  dischargedAt?: InputMaybe<Scalars['Date']['input']>;
  facilityLocation?: InputMaybe<Scalars['String']['input']>;
  facilityType?: InputMaybe<AdmissionFacilityType>;
  hasDischargeSummary?: InputMaybe<Scalars['Boolean']['input']>;
  hrcmEnrollment?: InputMaybe<HrcmEnrollment>;
  id: Scalars['ID']['input'];
  monitoring?: InputMaybe<AdmissionMonitoring>;
  nextCheckInDate?: InputMaybe<Scalars['Date']['input']>;
  nextSteps?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  readmissionRisk?: InputMaybe<ReadmissionRisk>;
  story?: InputMaybe<Scalars['HTML']['input']>;
  type?: InputMaybe<AdmissionType>;
};

/** Autogenerated return type of UpdateAdmission. */
export type UpdateAdmissionPayload = {
  __typename?: 'UpdateAdmissionPayload';
  admission?: Maybe<Admission>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateAppointment */
export type UpdateAppointmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isBillingReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  room?: InputMaybe<Scalars['String']['input']>;
  sendSms?: InputMaybe<Scalars['Boolean']['input']>;
  smsNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateAppointmentInventory */
export type UpdateAppointmentInventoryInput = {
  appointmentInventoryBookableLocations?: InputMaybe<Array<AppointmentInventoryBookableLocationInput>>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Autogenerated return type of UpdateAppointmentInventory. */
export type UpdateAppointmentInventoryPayload = {
  __typename?: 'UpdateAppointmentInventoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  inventory?: Maybe<AppointmentInventory>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of UpdateAppointment. */
export type UpdateAppointmentPayload = {
  __typename?: 'UpdateAppointmentPayload';
  appointment?: Maybe<Appointment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCareStatus */
export type UpdateCareStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  valueId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateCareStatus. */
export type UpdateCareStatusPayload = {
  __typename?: 'UpdateCareStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patientData?: Maybe<SeniorHealthPatientData>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateCazRequest */
export type UpdateCazRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  practitionerId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['String']['input'];
  reviewerId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateCazRequest. */
export type UpdateCazRequestPayload = {
  __typename?: 'UpdateCazRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateContact */
export type UpdateContactInput = {
  acceptingNewPatients?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  addressAttributes?: InputMaybe<AddressAttributes>;
  availability?: InputMaybe<Rating>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  customNetworkIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  customWarning?: InputMaybe<Scalars['String']['input']>;
  degrees?: InputMaybe<Scalars['String']['input']>;
  diagnosticGroupIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  emailsAttributes?: InputMaybe<Array<InputMaybe<EmailAttributes>>>;
  expertise?: InputMaybe<Rating>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formerName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  hasPreferredAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  incompleteReason?: InputMaybe<Scalars['String']['input']>;
  institutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNetworkIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNotes?: InputMaybe<Scalars['String']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  isEconsult?: InputMaybe<Scalars['Boolean']['input']>;
  isElectronicallyIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegratedPartner?: InputMaybe<Scalars['Boolean']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  isPartner?: InputMaybe<Scalars['Boolean']['input']>;
  isQuality?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ContactKind>;
  languagesSpoken?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  noPhysicalLocation?: InputMaybe<Scalars['Boolean']['input']>;
  nonAcceptedContactInsurancesAttributes?: InputMaybe<Array<InputMaybe<ContactInsuranceAttributes>>>;
  npi?: InputMaybe<Scalars['String']['input']>;
  officeHours?: InputMaybe<Scalars['String']['input']>;
  otherNotes?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  personality?: InputMaybe<Rating>;
  phoneNumbersAttributes?: InputMaybe<Array<InputMaybe<PhoneNumberAttributes>>>;
  specialtyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  status?: InputMaybe<ContactStatus>;
  /** @deprecated use `systemStatus` enum instead */
  statusId?: InputMaybe<Scalars['ID']['input']>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Rating>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateContact. */
export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateDefaultCopayStripeId */
export type UpdateDefaultCopayStripeIdInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateDefaultCopayStripeId. */
export type UpdateDefaultCopayStripeIdPayload = {
  __typename?: 'UpdateDefaultCopayStripeIdPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateEnrollmentStatus */
export type UpdateEnrollmentStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  valueIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UpdateEnrollmentStatus. */
export type UpdateEnrollmentStatusPayload = {
  __typename?: 'UpdateEnrollmentStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patientData?: Maybe<SeniorHealthPatientData>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateHieOptIn */
export type UpdateHieOptInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The version of the Health Information Exchange Legal Text that was displayed to the user. */
  healthInformationExchangeLegalTextVersion: Scalars['Int']['input'];
  healthInformationExchangeOptIn: Scalars['Boolean']['input'];
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateHieOptIn. */
export type UpdateHieOptInPayload = {
  __typename?: 'UpdateHieOptInPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateImproperlyClosedServiceTicketOrder */
export type UpdateImproperlyClosedServiceTicketOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  state: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateImproperlyClosedServiceTicketOrder. */
export type UpdateImproperlyClosedServiceTicketOrderPayload = {
  __typename?: 'UpdateImproperlyClosedServiceTicketOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicketImproperlyClosedOrder: ServiceTicketImproperlyClosedOrder;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInsurance */
export type UpdateInsuranceInput = {
  cardImageS3KeyBack?: InputMaybe<Scalars['String']['input']>;
  cardImageS3KeyFront?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  coinsurance?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  copay?: InputMaybe<Scalars['Int']['input']>;
  effectiveAt?: InputMaybe<Scalars['String']['input']>;
  groupno?: InputMaybe<Scalars['String']['input']>;
  holderAddress1?: InputMaybe<Scalars['String']['input']>;
  holderAddress2?: InputMaybe<Scalars['String']['input']>;
  holderCity?: InputMaybe<Scalars['String']['input']>;
  holderDob?: InputMaybe<Scalars['String']['input']>;
  holderFirstName?: InputMaybe<Scalars['String']['input']>;
  holderLastName?: InputMaybe<Scalars['String']['input']>;
  holderPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  holderState?: InputMaybe<Scalars['String']['input']>;
  holderZipCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  insuranceCarrierId?: InputMaybe<Scalars['ID']['input']>;
  insuranceImage?: InputMaybe<S3Attributes>;
  insuranceNetworkId?: InputMaybe<Scalars['ID']['input']>;
  insurancePlanId?: InputMaybe<Scalars['ID']['input']>;
  insuranceRelationshipTypeId?: InputMaybe<Scalars['ID']['input']>;
  insuranceTypeId?: InputMaybe<Scalars['ID']['input']>;
  mlOcrNeedsHumanReview?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  subscriberNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateInsurance. */
export type UpdateInsurancePayload = {
  __typename?: 'UpdateInsurancePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  insurance?: Maybe<Insurance>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInsurancePlan */
export type UpdateInsurancePlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  insuranceTypeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateInsurancePlan. */
export type UpdateInsurancePlanPayload = {
  __typename?: 'UpdateInsurancePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  insurancePlan?: Maybe<InsurancePlan>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateInternalUserPreferences */
export type UpdateInternalUserPreferencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  licensingBodies?: InputMaybe<Array<Scalars['String']['input']>>;
  videoNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateInternalUserPreferences. */
export type UpdateInternalUserPreferencesPayload = {
  __typename?: 'UpdateInternalUserPreferencesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  internalUser?: Maybe<InternalUser>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateIsInsuranceActive */
export type UpdateIsInsuranceActiveInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isInsuranceActive: Scalars['Boolean']['input'];
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateIsInsuranceActive. */
export type UpdateIsInsuranceActivePayload = {
  __typename?: 'UpdateIsInsuranceActivePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patientData?: Maybe<SeniorHealthPatientData>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateLabOrder */
export type UpdateLabOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  measurementOrderTypeSourceId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateLabOrder. */
export type UpdateLabOrderPayload = {
  __typename?: 'UpdateLabOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  labOrder?: Maybe<LabOrder>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateMroWorkflow */
export type UpdateMroWorkflowInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  state: Scalars['String']['input'];
  text: Scalars['String']['input'];
  workflowType: MroWorkflowTypeEnum;
};

/** Autogenerated return type of UpdateMroWorkflow. */
export type UpdateMroWorkflowPayload = {
  __typename?: 'UpdateMroWorkflowPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mroWorkflow?: Maybe<MroWorkflow>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type UpdatePatientAddressAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  kind: AddressKind;
  state: Scalars['String']['input'];
  status: AddressStatus;
  zipCode: Scalars['String']['input'];
};

/** Autogenerated input type of UpdatePatientAddress */
export type UpdatePatientAddressInput = {
  address: UpdatePatientAddressAddressInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdatePatientAddress. */
export type UpdatePatientAddressPayload = {
  __typename?: 'UpdatePatientAddressPayload';
  address?: Maybe<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientAdminComments */
export type UpdatePatientAdminCommentsInput = {
  adminComments: Scalars['Markdown']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePatientAdminComments. */
export type UpdatePatientAdminCommentsPayload = {
  __typename?: 'UpdatePatientAdminCommentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientDemographics */
export type UpdatePatientDemographicsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  demographics: PatientDemographics;
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePatientDemographics. */
export type UpdatePatientDemographicsPayload = {
  __typename?: 'UpdatePatientDemographicsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient: Patient;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientEmail */
export type UpdatePatientEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: PatientEmailInput;
};

/** Autogenerated return type of UpdatePatientEmail. */
export type UpdatePatientEmailPayload = {
  __typename?: 'UpdatePatientEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Email>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientEmergencyContact */
export type UpdatePatientEmergencyContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emergencyContact: EmergencyContactInputObject;
};

/** Autogenerated return type of UpdatePatientEmergencyContact. */
export type UpdatePatientEmergencyContactPayload = {
  __typename?: 'UpdatePatientEmergencyContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  emergencyContact?: Maybe<EmergencyContact>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatient */
export type UpdatePatientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  officeId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated input type of UpdatePatientPassword */
export type UpdatePatientPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePatientPassword. */
export type UpdatePatientPasswordPayload = {
  __typename?: 'UpdatePatientPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of UpdatePatient. */
export type UpdatePatientPayload = {
  __typename?: 'UpdatePatientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientPcp */
export type UpdatePatientPcpInput = {
  allowClosedPanels?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['ID']['input'];
  patientId?: InputMaybe<Scalars['ID']['input']>;
  providerId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdatePatientPcp. */
export type UpdatePatientPcpPayload = {
  __typename?: 'UpdatePatientPcpPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `mutationErrors` instead */
  errors?: Maybe<Scalars['JSON']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  patient?: Maybe<Patient>;
  /** @deprecated Do not use this for getting the patient's PCP as it circumvents Apollo client caching. Use `patient` instead. */
  provider?: Maybe<Provider>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientPhoneNumber */
export type UpdatePatientPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: PatientPhoneNumberInput;
};

/** Autogenerated return type of UpdatePatientPhoneNumber. */
export type UpdatePatientPhoneNumberPayload = {
  __typename?: 'UpdatePatientPhoneNumberPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  phoneNumber?: Maybe<PhoneNumber>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePatientPreferences */
export type UpdatePatientPreferencesInput = {
  acceptsClinicalEmails?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPatientTaskEmails?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPatientTaskPush?: InputMaybe<Scalars['Boolean']['input']>;
  aiAssistantEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  avsHideWeight?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  connectedDevicesOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  fullTextNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  needsLanguageInterpreter?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdatePatientPreferences. */
export type UpdatePatientPreferencesPayload = {
  __typename?: 'UpdatePatientPreferencesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated This field is not yet implemented. It is included for forward-compatibility reasons */
  mutationErrors?: Maybe<Array<MutationError>>;
  preferences: PatientPreferences;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdatePendingDirectSignupAccount */
export type UpdatePendingDirectSignupAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of UpdatePendingDirectSignupAccount. */
export type UpdatePendingDirectSignupAccountPayload = {
  __typename?: 'UpdatePendingDirectSignupAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<DirectSignupError>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateProcedureInteraction */
export type UpdateProcedureInteractionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formData?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  procedureSummary?: InputMaybe<Scalars['String']['input']>;
  procedureTypeId?: InputMaybe<Scalars['ID']['input']>;
  resultsInterpretation?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateProcedureInteraction. */
export type UpdateProcedureInteractionPayload = {
  __typename?: 'UpdateProcedureInteractionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  procedureInteraction?: Maybe<ProcedureInteraction>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProfile */
export type UpdateProfileInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated return type of UpdateProfile. */
export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  profile: InternalUser;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProgramCarePlan */
export type UpdateProgramCarePlanInput = {
  carePlan: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateProgramCarePlan. */
export type UpdateProgramCarePlanPayload = {
  __typename?: 'UpdateProgramCarePlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  programCarePlan?: Maybe<ProgramCarePlan>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProgramEnrollmentCareTeam */
export type UpdateProgramEnrollmentCareTeamInput = {
  careGiverIds: Array<Scalars['ID']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateProgramEnrollmentCareTeam. */
export type UpdateProgramEnrollmentCareTeamPayload = {
  __typename?: 'UpdateProgramEnrollmentCareTeamPayload';
  careTeam?: Maybe<ProgramEnrollmentCareTeam>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProgramEnrollment */
export type UpdateProgramEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  flag?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  outreachComment?: InputMaybe<Scalars['String']['input']>;
  preferredTime?: InputMaybe<Scalars['String']['input']>;
  programPhaseId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateProgramEnrollment. */
export type UpdateProgramEnrollmentPayload = {
  __typename?: 'UpdateProgramEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  programEnrollment?: Maybe<ProgramEnrollment>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProgramForAppointment */
export type UpdateProgramForAppointmentInput = {
  appointmentId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  programId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateProgramForAppointment. */
export type UpdateProgramForAppointmentPayload = {
  __typename?: 'UpdateProgramForAppointmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  programEnrollment?: Maybe<ProgramEnrollment>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateProgramVisit */
export type UpdateProgramVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  formData?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  summaryOfVisit?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateProgramVisit. */
export type UpdateProgramVisitPayload = {
  __typename?: 'UpdateProgramVisitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  programVisit?: Maybe<ProgramVisit>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateRegistrationWaitlistEnrollment */
export type UpdateRegistrationWaitlistEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  patientId: Scalars['ID']['input'];
  serviceAreaCode: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateRegistrationWaitlistEnrollment. */
export type UpdateRegistrationWaitlistEnrollmentPayload = {
  __typename?: 'UpdateRegistrationWaitlistEnrollmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  registrationWaitlistEnrollment?: Maybe<RegistrationWaitlistEnrollment>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateScheduleTemplate */
export type UpdateScheduleTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateScheduleTemplate. */
export type UpdateScheduleTemplatePayload = {
  __typename?: 'UpdateScheduleTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  scheduleTemplate?: Maybe<ScheduleTemplate>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateScheduleTemplateVariant */
export type UpdateScheduleTemplateVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scheduleTemplateId?: InputMaybe<Scalars['ID']['input']>;
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateScheduleTemplateVariant. */
export type UpdateScheduleTemplateVariantPayload = {
  __typename?: 'UpdateScheduleTemplateVariantPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
  variant?: Maybe<ScheduleTemplateVariant>;
};

/** Autogenerated input type of UpdateScheduleTemplateVariantSlots */
export type UpdateScheduleTemplateVariantSlotsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slots: Array<SlotInput>;
  variantId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateScheduleTemplateVariantSlots. */
export type UpdateScheduleTemplateVariantSlotsPayload = {
  __typename?: 'UpdateScheduleTemplateVariantSlotsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  slots?: Maybe<Array<ScheduleTemplateVariantSlot>>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateServiceTicket */
export type UpdateServiceTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  processingInstructions?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateServiceTicketItem */
export type UpdateServiceTicketItemInput = {
  billingMethod?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  selfPayReason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateServiceTicketItem. */
export type UpdateServiceTicketItemPayload = {
  __typename?: 'UpdateServiceTicketItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicketItem?: Maybe<ServiceTicketItem>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of UpdateServiceTicket. */
export type UpdateServiceTicketPayload = {
  __typename?: 'UpdateServiceTicketPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  serviceTicket?: Maybe<ServiceTicket>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateStripePaymentMethodNickname */
export type UpdateStripePaymentMethodNicknameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  stripePaymentMethodId: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateStripePaymentMethodNickname. */
export type UpdateStripePaymentMethodNicknamePayload = {
  __typename?: 'UpdateStripePaymentMethodNicknamePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  assigneeIdent?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  priority?: InputMaybe<Priority>;
  roamMessageLabelPredictionsAttributes?: InputMaybe<Array<RoamMessageLabelPredictionAttributes>>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  taskTemplateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Autogenerated return type of UpdateTask. */
export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of UpdateVoluntaryAlignmentOffer */
export type UpdateVoluntaryAlignmentOfferInput = {
  alignmentDocument?: InputMaybe<S3Attributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  followUpAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  offerMethod?: InputMaybe<Scalars['String']['input']>;
  offeredAt?: InputMaybe<Scalars['DateTime']['input']>;
  offeredById?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  signedAt?: InputMaybe<Scalars['DateTime']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateVoluntaryAlignmentOffer. */
export type UpdateVoluntaryAlignmentOfferPayload = {
  __typename?: 'UpdateVoluntaryAlignmentOfferPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  voluntaryAlignmentOffer?: Maybe<VoluntaryAlignmentOffer>;
};

/** Autogenerated input type of UpsertMlFeedbackTodoMessageReassignment */
export type UpsertMlFeedbackTodoMessageReassignmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackCategory?: InputMaybe<Scalars['String']['input']>;
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  newAssigneeId?: InputMaybe<Scalars['ID']['input']>;
  newAssigneeType?: InputMaybe<Scalars['String']['input']>;
  todoMessageReassignmentId: Scalars['ID']['input'];
};

/** Autogenerated return type of UpsertMlFeedbackTodoMessageReassignment. */
export type UpsertMlFeedbackTodoMessageReassignmentPayload = {
  __typename?: 'UpsertMlFeedbackTodoMessageReassignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  todoMessageReassignmentFeedback?: Maybe<TodoMessageReassignmentFeedback>;
};

export type UserEngagementMessage = {
  __typename?: 'UserEngagementMessage';
  id: Scalars['ID']['output'];
  imageS3Pointer?: Maybe<Scalars['String']['output']>;
  notificationSubtitle?: Maybe<Scalars['String']['output']>;
  notificationTitle: Scalars['String']['output'];
  /** The Call to Action Text for the User Engagement Message */
  primaryCtaText?: Maybe<Scalars['String']['output']>;
  /** The Call to Action URL for the User Engagement Message */
  primaryCtaUrl?: Maybe<Scalars['String']['output']>;
  /** The template text for the current_patient */
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** The connection type for UserEngagementMessage. */
export type UserEngagementMessageConnection = {
  __typename?: 'UserEngagementMessageConnection';
  /** A list of edges. */
  edges: Array<UserEngagementMessageEdge>;
  /** A list of nodes. */
  nodes: Array<UserEngagementMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEngagementMessageEdge = {
  __typename?: 'UserEngagementMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserEngagementMessage>;
};

/** Creates User Engagement Message outreach batches */
export type UserEngagementMessageOutreach = OutreachManagerBatch & {
  __typename?: 'UserEngagementMessageOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for this record. */
  id: Scalars['ID']['output'];
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime']['output'];
  /** The User Engagement Message that this outreach batch will be sent with. */
  userEngagementMessage: UserEngagementMessage;
};


/** Creates User Engagement Message outreach batches */
export type UserEngagementMessageOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

export type UserFreeTextPayload = {
  __typename?: 'UserFreeTextPayload';
  /** Text prompt from the user. Will be null if the user input has not yet been collected. */
  content?: Maybe<Scalars['String']['output']>;
  /** If true, the client must collect input from the user in the form of text */
  requiresUserFreeTextInput: Scalars['Boolean']['output'];
  /** must be "userFreeText" */
  type: Scalars['String']['output'];
};

export type UserFreeTextPayloadInput = {
  /** Prompt text from the user to be sent to IHS-Service */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Set to true as returned by toucanNewConversation or toucanContinueConversation */
  requiresUserFreeTextInput: Scalars['Boolean']['input'];
  /** must be "userFreeText" */
  type: Scalars['String']['input'];
};

/**
 * Languages spoken by internal users, such as providers.
 * In their onboarding survey, providers share what languages they feel comfortable using during appointments.
 * Note that a patient's preferred language is represented by Types::LanguageType.
 */
export type UserLanguage = {
  __typename?: 'UserLanguage';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  preferred: Scalars['Boolean']['output'];
  proficiency: UserLanguageProficiencyEnum;
};

export enum UserLanguageProficiencyEnum {
  Elementary = 'elementary',
  LimitedWorking = 'limited_working',
  Native = 'native',
  ProfessionalFull = 'professional_full',
  ProfessionalWorking = 'professional_working',
  Unspecified = 'unspecified'
}

export type Vaccine = {
  __typename?: 'Vaccine';
  id: Scalars['ID']['output'];
  isFluVaccine: Scalars['Boolean']['output'];
  patientEduText: Scalars['String']['output'];
  prefName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Please use the camelCase version. */
  pref_name?: Maybe<Scalars['String']['output']>;
  procedureType?: Maybe<ProcedureType>;
};

export type VaccineOrder = Order & {
  __typename?: 'VaccineOrder';
  createdAt: Scalars['DateTime']['output'];
  dateDue?: Maybe<Scalars['DateTime']['output']>;
  historyVaccine?: Maybe<HistoryVaccine>;
  id: Scalars['ID']['output'];
  isAutomated?: Maybe<Scalars['Boolean']['output']>;
  isSeries: Scalars['Boolean']['output'];
  isWalkin: Scalars['Boolean']['output'];
  orderedBy?: Maybe<Provider>;
  patient: Patient;
  step?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vaccine: Vaccine;
  /** Name of the vaccine */
  vaccineName?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ValidateClaimCode */
export type ValidateClaimCodeInput = {
  claimCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  purchaseRef?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ValidateClaimCode. */
export type ValidateClaimCodePayload = {
  __typename?: 'ValidateClaimCodePayload';
  claimCodeValidation?: Maybe<ClaimCodeValidation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  mutationErrors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean']['output'];
};

export type VerifiedEligibleEmployee = {
  __typename?: 'VerifiedEligibleEmployee';
  b2bCompany: B2bCompany;
  dependents: Array<ScrubbedWhitelistedEmployee>;
  dob?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  homeCity?: Maybe<Scalars['String']['output']>;
  homeState?: Maybe<Scalars['String']['output']>;
  homeStreetAddress?: Maybe<Scalars['String']['output']>;
  homeStreetAddress2?: Maybe<Scalars['String']['output']>;
  homeZip?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDependent: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registered: Scalars['Boolean']['output'];
  workEmail?: Maybe<Scalars['String']['output']>;
};

/** Attributes that will be used to process outreach for Visit Follow-Up Orders. */
export type VisitFollowUpOrderOutreach = OutreachManagerBatch & {
  __typename?: 'VisitFollowUpOrderOutreach';
  /** The type of order that this batch represents. */
  batchOrderType: OutreachManagerBatchOrderType;
  /** A timestamp noting when the record was created. */
  createdAt: Scalars['DateTime']['output'];
  /** The internal user that created this batch. */
  createdBy: InternalUser;
  /** optional information about this batch of outreach  */
  description?: Maybe<Scalars['String']['output']>;
  /** When will the appointment be due? */
  dueDate: Scalars['DateTime']['output'];
  /** A unique identifier for this record. */
  id: Scalars['ID']['output'];
  /** A note for the Visit Follow-Up Order. */
  note: Scalars['String']['output'];
  /** The Patients related to this batch. */
  outreachBatchPatients: OutreachBatchPatientConnection;
  /** The appointment type group for the outreach. */
  outreachManagerAppointmentTypeGroup: OutreachManagerAppointmentTypeGroup;
  /** The problem type that will be attached to the outreach. */
  problemType?: Maybe<ProblemType>;
  s3Pointers: Array<S3Pointers>;
  /** Current batch status. */
  status: OutreachManagerStatus;
  /** A title for the Visit Follow-Up Order. */
  title?: Maybe<Scalars['String']['output']>;
  /** A timestamp noting when the record was last modified. */
  updatedAt: Scalars['DateTime']['output'];
};


/** Attributes that will be used to process outreach for Visit Follow-Up Orders. */
export type VisitFollowUpOrderOutreachOutreachBatchPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<OutreachBatchPatientStatus>;
};

export type VisitMetadata = {
  __typename?: 'VisitMetadata';
  code?: Maybe<Scalars['Int']['output']>;
  hostId?: Maybe<Scalars['ID']['output']>;
  meetingUuid?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type VisitReasonAttributes = {
  categorizableId?: InputMaybe<Scalars['ID']['input']>;
  categorizableType?: InputMaybe<Scalars['String']['input']>;
  textToMatch?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['String']['input']>;
};

export type VisitReasonMap = {
  __typename?: 'VisitReasonMap';
  mostBooked?: Maybe<Array<AppointmentType>>;
  remainingAvailable?: Maybe<Array<AppointmentType>>;
};

export type VitalSet = {
  __typename?: 'VitalSet';
  collectedAt?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalUser: InternalUser;
  measurements: Array<Measurement>;
  patientAgeAtCollectionInMonths: Scalars['Float']['output'];
  readonly: Scalars['Boolean']['output'];
  unsigned: Scalars['Boolean']['output'];
  withings: Scalars['Boolean']['output'];
};

/** The connection type for VitalSet. */
export type VitalSetConnection = {
  __typename?: 'VitalSetConnection';
  /** A list of edges. */
  edges: Array<VitalSetEdge>;
  /** A list of nodes. */
  nodes: Array<VitalSet>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VitalSetEdge = {
  __typename?: 'VitalSetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<VitalSet>;
};

export type Vitals = {
  __typename?: 'Vitals';
  heartRate: HeartRateMeasurementConnection;
  height: HeightMeasurementConnection;
  respiratoryRate: RespiratoryRateMeasurementConnection;
  sets: VitalSetConnection;
  temperature: TemperatureMeasurementConnection;
  weight: WeightMeasurementConnection;
};


export type VitalsHeartRateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VitalsHeightArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VitalsRespiratoryRateArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VitalsSetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hidePatientCollected?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VitalsTemperatureArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VitalsWeightArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VoluntaryAlignmentOffer = {
  __typename?: 'VoluntaryAlignmentOffer';
  alignmentDocumentS3Link?: Maybe<Scalars['String']['output']>;
  documentUrl?: Maybe<Scalars['String']['output']>;
  documents: Array<Document>;
  docusignEnvelope?: Maybe<DocusignEnvelope>;
  followUpAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  offerMethod: Scalars['String']['output'];
  offeredAt?: Maybe<Scalars['DateTime']['output']>;
  offeredBy?: Maybe<InternalUser>;
  patient: Patient;
  reason?: Maybe<Scalars['String']['output']>;
  resentAt?: Maybe<Scalars['DateTime']['output']>;
  resentBy?: Maybe<InternalUser>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  state?: Maybe<VoluntaryAlignmentOfferState>;
};

export enum VoluntaryAlignmentOfferState {
  Declined = 'declined',
  Deferred = 'deferred',
  Invalid = 'invalid',
  Needed = 'needed',
  NoResponse = 'no_response',
  Offered = 'offered',
  Signed = 'signed'
}

export type VoluntaryAlignmentOffersSearch = {
  __typename?: 'VoluntaryAlignmentOffersSearch';
  results: Array<VoluntaryAlignmentOffer>;
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type WaystarEligibilityStatus = {
  __typename?: 'WaystarEligibilityStatus';
  createdAt: Scalars['DateTime']['output'];
  /** effective date */
  effectiveDate?: Maybe<Scalars['Date']['output']>;
  /** eligibility status */
  eligibilityStatus?: Maybe<Scalars['String']['output']>;
  /** expiration date */
  expirationDate?: Maybe<Scalars['Date']['output']>;
  htmlResponse?: Maybe<Scalars['HTML']['output']>;
  id: Scalars['ID']['output'];
  individualRemainingDeductible?: Maybe<Scalars['Float']['output']>;
  insurance?: Maybe<Insurance>;
  insuranceInformationBenefitCode?: Maybe<Scalars['String']['output']>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  mentalCoinsurance?: Maybe<Scalars['Float']['output']>;
  mentalCopayment?: Maybe<Scalars['Float']['output']>;
  officeCoinsurance?: Maybe<Scalars['Float']['output']>;
  officeCopayment?: Maybe<Scalars['Float']['output']>;
  parsedData?: Maybe<WaystarParsedData>;
  patient?: Maybe<Patient>;
  policyHolder?: Maybe<RelatedPerson>;
  request?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  textResponse?: Maybe<Scalars['String']['output']>;
  totalIndividualDeductible?: Maybe<Scalars['Float']['output']>;
  transactionTrace?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  virtualCoinsurance?: Maybe<Scalars['Float']['output']>;
  virtualCopayment?: Maybe<Scalars['Float']['output']>;
  waystarGroupNumber?: Maybe<Scalars['String']['output']>;
};

export type WaystarParsedData = {
  __typename?: 'WaystarParsedData';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  insuranceRelationshipType?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  messageInfo?: Maybe<Scalars['JSON']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Container for the Min/Max Screen response a.k.a. WBD */
export type WeightBasedDosingResponse = {
  __typename?: 'WeightBasedDosingResponse';
  errors?: Maybe<Array<Error>>;
  id: Scalars['ID']['output'];
  result?: Maybe<Result>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The connection type for Measurement. */
export type WeightMeasurementConnection = {
  __typename?: 'WeightMeasurementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MeasurementEdge>>>;
  education: Education;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Measurement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type WhitelistedEmployee = {
  __typename?: 'WhitelistedEmployee';
  b2bCompany: B2bCompany;
  dependents: Array<ScrubbedWhitelistedEmployee>;
  employeeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isDependent: Scalars['Boolean']['output'];
  registered: Scalars['Boolean']['output'];
  workEmail?: Maybe<Scalars['String']['output']>;
};

export type WorkSchedule = {
  __typename?: 'WorkSchedule';
  comment?: Maybe<Scalars['String']['output']>;
  departureDate?: Maybe<Scalars['Date']['output']>;
  fridaysOff: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mondaysOff: Scalars['Boolean']['output'];
  nextDayIn?: Maybe<Scalars['Date']['output']>;
  onVacation: Scalars['Boolean']['output'];
  out?: Maybe<Scalars['Boolean']['output']>;
  returningOn?: Maybe<Scalars['Date']['output']>;
  returningOnDate?: Maybe<Scalars['Date']['output']>;
  saturdaysOff: Scalars['Boolean']['output'];
  sundaysOff: Scalars['Boolean']['output'];
  thursdaysOff: Scalars['Boolean']['output'];
  tuesdaysOff: Scalars['Boolean']['output'];
  wednesdaysOff: Scalars['Boolean']['output'];
};

export enum WorryScore {
  Emergent = 'EMERGENT',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Unassigned = 'UNASSIGNED'
}

export type Zoom = {
  __typename?: 'Zoom';
  visitMetadata: VisitMetadata;
};


export type ZoomVisitMetadataArgs = {
  meetingId: Scalars['String']['input'];
};
